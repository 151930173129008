import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  topTab: {
    maxHeight: '64px',
    height: '64px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F8F9FC',
    height: '100%',
  },
  newPassContainer: {
    marginTop: '10%',
    borderRadius: '12px',
    padding: '1rem',
    height: '34%',
    width: '31%',
    backgroundColor: 'white',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#5A5F61',
  },
  button: {
    textTransform: 'none',
    marginTop: '3%',
  },
}));

export default useStyles;
