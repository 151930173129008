import React from 'react';
import PendingActionsIcon from '@mui/icons-material/PendingActionsOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';

import Dashboard from '../Dashboard/View/Dashboard';
import Profile from '../Profile/Profile';
import Setting from '../Setting/Views/Setting';
import RequestsHistory from '../RequestsHistory/Views/RequestsHistory';
import { ROUTES, PATHS } from '../../api/apiConstants';
import Calendar from '../Calendar/Calendar';
import UserRole from './UserRole.enum';

export const HrRoutes = [
  {
    name: ROUTES.DASHBOARD,
    path: PATHS.DASHBOARD,
    element: <Dashboard />,
    icon: <DashboardIcon />,
    requiredRoles: [UserRole.ADMIN, UserRole.NORMAL],
    sideBar: true,
  },
  {
    name: ROUTES.REQUESTS,
    path: PATHS.REQUESTS,
    element: <RequestsHistory />,
    icon: <PendingActionsIcon />,
    requiredRoles: [UserRole.ADMIN],
    sideBar: true,
  },
  {
    name: ROUTES.CALENDAR,
    path: PATHS.CALENDAR,
    element: <Calendar />,
    icon: <EventOutlinedIcon />,
    requiredRoles: [UserRole.ADMIN, UserRole.NORMAL],
    sideBar: true,
  },
  {
    name: ROUTES.SETTING,
    path: PATHS.SETTING,
    element: <Setting />,
    icon: <SettingsIcon />,
    requiredRoles: [UserRole.ADMIN],
    sideBar: true,
  },
  {
    name: ROUTES.PROFILE,
    path: PATHS.PROFILE,
    element: <Profile />,
    requiredRoles: [UserRole.ADMIN, UserRole.NORMAL],
  },
];
