import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Box, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { STATUS } from '../../../../api/apiConstants';
import useStyles from './style';

const OverlappingRequestsUserModal = ({
  handleClose,
  updateRequestStatus,
  requestInfo,
}) => {
  const classes = useStyles();

  return (
    <Grid className="container">
      <Formik
        initialValues={{ comment: '' }}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateRequestStatus(
            requestInfo,
            values.comment,
            STATUS.REJECTED,
            true,
          );
          handleClose();
        }}>
        {() => {
          return (
            <Form>
              <Box className={classes.container}>
                <IconButton
                  aria-label="close modal"
                  className={classes.closeButtonStyle}
                  onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
                <Grid className={classes.titleContainer}>
                  <Grid className={classes.title}>¡Atención!</Grid>
                  <Grid className={classes.subtitle}>
                    El usuario ya cuenta con una licencia aprobada en ese rango
                    de fechas, y no es posible aceptar otra solicitud
                  </Grid>
                </Grid>

                <Grid className={classes.containerButtons}>
                  <Button
                    style={{
                      textTransform: 'none',
                      margin: 'auto',
                      display: 'grid',
                    }}
                    variant="outlined"
                    type="submit"
                    color="error">
                    Rechazar
                  </Button>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};
OverlappingRequestsUserModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  updateRequestStatus: PropTypes.func.isRequired,
  requestInfo: PropTypes.object.isRequired,
};

export default OverlappingRequestsUserModal;
