import * as Yup from 'yup';

const validationSchemaLogin = Yup.object().shape({
  email: Yup.string()
    .email('Formato de email inválido')
    .required('El email es requerido'),
  password: Yup.string()
    .required('Ingrese una contraseña')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/,
      'Debe contener al menos 8 caracteres con una mayúscula, una minúscula y un número',
    ),
});

export default validationSchemaLogin;
