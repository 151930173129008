import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: 'auto',
    maxWidth: '1500px',
    borderRadius: '8px',
  },
  tableHead: {
    backgroundColor: 'rgba(8, 47, 63, 0.05)',
    height: '24px',
  },
  headRow: {
    height: '24px',
  },
  topTab: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    paddingLeft: '3%',
    paddingRight: '5%',
    borderRadius: '50px 50px 0px 0px',
  },
  actionCell: {
    flexDirection: 'row',
    display: 'flex',
  },
  headCellsMid: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'center',
  },
  headCellsLeft: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
  },
  headCellsRight: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'right',
    paddingRight: '4%',
  },
  employeesText: {
    paddingLeft: '5%',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
    marginLeft: '.5rem',
  },
  addEmployeesButton: {
    borderRadius: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  employeesTextContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    width: '100%',
  },
  textRowLeft: {
    display: 'flex',
    fontFamily: 'Montserrat',
    flexDirection: 'row',
    fontSize: '14px',
    fontWeight: '400',
  },
  textRowMid: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
  },
  textRowRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  logo: {
    width: '36px',
    height: '36px',
  },
  buttonContainer: {
    borderRadius: '8',
    color: theme.palette.primary.main,
  },
  editButton: {
    color: '#7E62CC',
    cursor: 'pointer',
  },
  input: {
    paddingLeft: '1%',
    width: '35rem',
    borderRadius: '12px',
    borderColor: '#ede9f7',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  boxContainer: {
    borderRadius: '18px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    paddingBottom: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    padding: '1rem',
  },
  subContainer: {
    borderRadius: '18px',
    backgroundColor: 'rgba(90, 95, 97, 0.08);',
    width: '100%',
    padding: '2%',
  },
  contact: {
    borderRadius: '8px',
    border: '1px solid #B5BBBD',
    backgroundColor: '#E7EDEF',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '2px',
    alignItems: 'center',
    marginRight: '1rem',
    color: theme.palette.iconColor.main,
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputItem: {
    paddingBottom: '3%',
  },
  subttile: {
    fontSize: '14px',
    fontWeight: '700',
    marginRight: '4px',
    marginBottom: '4px',
  },
  dateItem: {
    paddingBottom: '4%',
  },
  logoTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '2px',
    alignItems: 'center',
    marginRight: '1rem',
  },
  rowInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
    paddingTop: '2%',
    marginLeft: '1rem',
  },
  name: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
  },
  viewButton: {
    cursor: 'pointer',
  },
  inputStyle: {
    borderRadius: '12px',
    borderColor: '#E7EDEF',
    borderStyle: 'solid',
  },
  formTable: {
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    borderRadius: '18px',
    backgroundColor: 'white',
    maxHeight: '90%',
    maxWidth: '900px',
    padding: '1rem 3rem 1rem 3rem',
    marginBottom: '2rem',
  },
  profileContainer: {
    margin: 'auto',
    borderRadius: '8px',
  },
  titleContainer: {
    marginLeft: '1rem',
    marginTop: '1rem',
  },
  buttonKidsStyle: {
    marginTop: '1rem',
    marginBottom: '1rem',
    marginLeft: '1rem',
  },
  kidsContainer: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  kidsNameContainer: {
    marginBottom: '1rem',
  },
  text: {
    fontWeight: '700',
  },
  gridContainerDate: {
    marginTop: '15px',
  },
}));

export default useStyles;
