import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  gridNormalDays: {
    backgroundColor: '#FDF4E3',
    borderRadius: '16px',
    borderWidth: '1px',
    borderColor: theme.palette.border.main,
    borderStyle: 'solid',
  },

  gridSpaceDays: {
    backgroundColor: '#EDE9F7',
    borderRadius: '16px',
    borderWidth: '1px',
    borderColor: theme.palette.border.main,
    borderStyle: 'solid',
  },

  gridStudyDays: {
    backgroundColor: '#DCF1FF',
    borderRadius: '16px',
    borderWidth: '1px',
    borderColor: theme.palette.border.main,
    borderStyle: 'solid',
  },

  gridSickDays: {
    backgroundColor: '#FEEBF0',
    borderRadius: '16px',
    borderWidth: '1px',
    borderColor: theme.palette.border.main,
    borderStyle: 'solid',
  },

  balanceContainer: {
    maxWidth: '100%',
    justifyContent: 'space-between',
  },

  dayType: {
    color: '#5A5F61',
    fontWeight: '400',
    fontSize: '1rem',
    marginTop: '0.5rem',
  },

  daysAmount: {
    fontWeight: '700',
    fontSize: '24px',
  },
}));

export default useStyles;
