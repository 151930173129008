import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: '18px',
    width: '20%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '1rem',
  },
  inputItem: {
    margin: 'auto',
    paddingBottom: '1rem',
    minWidth: '100%',
    minHeight: '100%',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  rejectButton: {
    borderRadius: '8',
    color: theme.palette.error.main,
  },
  rejectBtnApp: {
    borderRadius: 2,
    width: '100%',
    border: '1px solid',
  },
  rejectBtnPend: {
    borderRadius: 2,
    width: '95%',
    border: '1px solid',
  },
  rejectButtonAction: {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

export default useStyles;
