import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { REQUEST_STATUS, REQUEST_TYPE_EN } from '../../../../api/apiConstants';
import { parseDateDdMmYyyy } from '../../../../Utils/parseDate';
import LineSkeleton from '../../../shared/LineSkeleton';
import { ReactComponent as RequestsLogo } from '../../../../assets/requestsLogo.svg';
import {
  TRANSLATED_REQUEST_STATUS,
  TRANSLATED_REQUEST_TYPE,
} from '../../../../constants';
import useStyles from './style';

const propTypes = {
  handleClose: PropTypes.func,
  requestsByIdData: PropTypes.obj,
};

const defaultPropTypes = {
  handleClose: undefined,
  requestsByIdData: undefined,
};

const HistoryRequestsModal = ({ handleClose, requestsByIdData }) => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const classes = useStyles();
  const getStylesByRequestType = (requestType) => {
    switch (requestType) {
      case REQUEST_TYPE_EN.NORMAL:
        return classes.textRowMidNormal;
      case REQUEST_TYPE_EN.SICK:
        return classes.textRowMidSick;
      case REQUEST_TYPE_EN.STUDY:
        return classes.textRowMidStudy;
      case REQUEST_TYPE_EN.SPACE:
        return classes.textRowMidSpace;
      case REQUEST_TYPE_EN.MATERNITY:
        return classes.textRowMidMaternity;
      case REQUEST_TYPE_EN.PATERNITY:
        return classes.textRowMidPaternity;
      case REQUEST_TYPE_EN.MOURNING:
        return classes.textRowMidMourning;
      case REQUEST_TYPE_EN.MARRIAGE:
        return classes.textRowMidMerriage;
      case REQUEST_TYPE_EN.BLOOD_DONATION:
        return classes.textRowMidBlood;
      case REQUEST_TYPE_EN.NOT_PAID:
        return classes.textRowMidNoPaid;
      case REQUEST_TYPE_EN.PAP:
        return classes.textRowMidPap;
      default:
        return '';
    }
  };

  return (
    <div className={classes.modalContainer}>
      <Typography className={classes.userName}>
        {requestsByIdData[0]?.name}
      </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div className={classes.topTab}>
          <Grid container>
            <Grid item xs={8}>
              <div className={classes.requestsTextContainer}>
                <RequestsLogo className={classes.logo} />
                <div className={classes.requestsText}>
                  <Typography className={classes.title}>Balance</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.tableBodyContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.headCells}>
                <TableCell className={classes.headCellsLeft}>
                  <Typography>Fecha</Typography>
                </TableCell>
                <TableCell className={classes.headCellsMid}>
                  <Typography>Tipo</Typography>
                </TableCell>
                <TableCell className={classes.headCellsMid}>
                  <Typography>Estado</Typography>
                </TableCell>
                <TableCell className={classes.headCellsMid}>
                  <Typography>Aprobado por</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoadingRequest ? (
                requestsByIdData[0]?.request.map((row) => (
                  <TableRow hover key={row.id} className={classes.tableRow}>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.textRowLeft}>
                        {`${parseDateDdMmYyyy(
                          row.startDate,
                        )} - ${parseDateDdMmYyyy(row.finishDate)}`}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography className={getStylesByRequestType(row.type)}>
                        {TRANSLATED_REQUEST_TYPE[row.type]}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        className={
                          row.status === REQUEST_STATUS.APPROVED
                            ? classes.textRowMidApprove
                            : classes.textRowMidRejected
                        }>
                        {TRANSLATED_REQUEST_STATUS[row.status]}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.textRowMid}>
                        {row.reviewedBy}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <LineSkeleton lineCont={requestsByIdData?.requests.length} />
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </div>
  );
};
HistoryRequestsModal.propTypes = propTypes;
HistoryRequestsModal.defaultProps = defaultPropTypes;

export default HistoryRequestsModal;
