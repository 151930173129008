import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Box, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import useStyles from './style';

const UserNegativeDaysModal = ({
  handleClose,
  disableUser,
  userDaysBalance,
}) => {
  const classes = useStyles();

  return (
    <Grid className="container">
      <Formik
        initialValues={{ comment: '' }}
        enableReinitialize={true}
        onSubmit={() => {
          disableUser({ id: userDaysBalance.userId }, true);
          handleClose();
        }}>
        {() => {
          return (
            <Form>
              <Box className={classes.container}>
                <IconButton
                  aria-label="close modal"
                  className={classes.closeButtonStyle}
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <Grid className={classes.titleContainer}>
                  <Grid className={classes.title}>¡Atención!</Grid>
                  <Grid className={classes.subtitle}>
                    El usuario tiene un balance negativo de{' '}
                    {userDaysBalance.normalDays} días de licencia:
                  </Grid>
                </Grid>

                <Grid className={classes.containerButtons}>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={handleClose}
                    color="primary">
                    Cancelar
                  </Button>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    type="submit"
                    color="error">
                    Deshabilitar
                  </Button>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

UserNegativeDaysModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  disableUser: PropTypes.func.isRequired,
  userDaysBalance: PropTypes.object.isRequired,
};

export default UserNegativeDaysModal;
