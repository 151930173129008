import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Box, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { STATUS } from '../../../../api/apiConstants';
import useStyles from './style';

const OldRequestModal = ({ handleClose, updateRequestStatus, requestInfo }) => {
  const classes = useStyles();

  return (
    <Grid className="container">
      <Formik initialValues={{ comment: '' }} enableReinitialize={true}>
        {() => {
          return (
            <Form>
              <Box className={classes.container}>
                <IconButton
                  aria-label="close modal"
                  className={classes.closeButtonStyle}
                  onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
                <Grid className={classes.titleContainer}>
                  <Grid className={classes.title}>¡Atención!</Grid>
                  <Grid className={classes.subtitle}>
                    La fecha de inicio de la solicitud ya ha pasado. ¿Quieres
                    aceptar la solicitud de todas maneras?
                  </Grid>
                </Grid>

                <Grid className={classes.containerButtons}>
                  <Button
                    style={{
                      textTransform: 'none',
                      margin: 'auto',
                      display: 'grid',
                    }}
                    variant="outlined"
                    onClick={() => {
                      updateRequestStatus(requestInfo, '', STATUS.REJECTED);
                      handleClose();
                    }}
                    color="error">
                    Rechazar
                  </Button>

                  <Button
                    style={{
                      textTransform: 'none',
                      margin: 'auto',
                      display: 'grid',
                    }}
                    variant="outlined"
                    onClick={() => {
                      updateRequestStatus(
                        requestInfo,
                        '',
                        STATUS.APPROVED,
                        true,
                      );
                      handleClose();
                    }}
                    color="primary">
                    Aceptar
                  </Button>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};
OldRequestModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  updateRequestStatus: PropTypes.func.isRequired,
  requestInfo: PropTypes.object.isRequired,
};

export default OldRequestModal;
