import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hrApi, { setHeader } from './hrApi';
import { errorHandling } from './apiHelpers';
import { HTTP_STATUS } from './apiConstants';

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);

  const checkConectionBackend = async () => {
    try {
      const resp = await hrApi.get();

      return resp.status === HTTP_STATUS.OK;
    } catch (error) {
      errorHandling(HTTP_STATUS.SERVICE_UNAVAILABLE);
    }

    return false;
  };

  useEffect(() => {
    const getToken = async () => {
      const localToken = localStorage.getItem('spaceHrToken');

      if (localToken) {
        // This is only for check if the backend for any reason is off
        const isBackendOn = await checkConectionBackend();

        if (isBackendOn) {
          loginHandler(localToken);
        }
      }
    };
    getToken();
  }, []);

  const userIsLoggedIn = !!token;

  const loginHandler = async (token) => {
    localStorage.setItem('spaceHrToken', token);
    setHeader();
    setToken(token);
  };

  const logoutHandler = async () => {
    localStorage.removeItem('spaceHrToken');
    setToken(null);
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
