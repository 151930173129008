import React from 'react';
import PropTypes from 'prop-types';
import { startOfWeek } from 'date-fns';
import { Button } from '@mui/material';
import { Grid } from '@mui/material';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import useStyles from './calendarStyle';
import WeekPicker from './WeekPicker';

const propTypes = {
  viewingDate: PropTypes.instanceOf(Date),
  setViewingDate: PropTypes.func,
  searchUser: PropTypes.func,
  setIsSearching: PropTypes.bool,
  users: PropTypes.array,
};

const CalendarHeader = ({
  viewingDate,
  setViewingDate,
  users,
  searchUser,
  setIsSearching,
}) => {
  const classes = useStyles();

  const setToday = () => {
    setViewingDate(startOfWeek(new Date()));
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <Button
          variant="outlined"
          text="Hoy"
          size="large"
          className={classes.button}
          sx={{ fontWeight: '900', fontSize: '1rem' }}
          onClick={setToday}>
          Hoy
        </Button>

        <WeekPicker viewingDate={viewingDate} setViewingDate={setViewingDate} />

        <Grid item xs={4} className={classes.inputContainer}>
          <Input
            className={classes.input}
            disableUnderline={true}
            onChange={(event) => {
              searchUser(users, event.target.value);
              if (event.target.value === '') {
                setIsSearching(false);
              }
            }}
            placeholder="Buscar empleado.."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{ color: '7E62CC' }} />
              </InputAdornment>
            }
          />
        </Grid>
      </div>
    </>
  );
};

CalendarHeader.propTypes = propTypes;

export default CalendarHeader;
