import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { inputStyle } from './Styles';

const propTypes = {
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.object,
};

const defaultPropTypes = {
  error: '',
  id: '',
  name: '',
  required: false,
  label: '',
  type: '',
  helperText: '',
  onChange: null,
  color: 'primary',
  variant: '',
  defaultValue: '',
  value: '',
  values: null,
};

const Input = ({
  id,
  name,
  required,
  label,
  type,
  helperText,
  onChange,
  color,
  variant,
  value,
  defaultValue,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <TextField
      id={id}
      name={name}
      required={required}
      label={label}
      type={type === 'password' && showPassword ? 'text' : type}
      helperText={helperText}
      onChange={onChange}
      variant={variant}
      color={color}
      style={inputStyle}
      value={value || defaultValue}
      fullWidth
      margin="normal"
      InputProps={{
        autoComplete: 'email',
        form: {
          autoComplete: 'off',
        },
        endAdornment:
          type === 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
    />
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultPropTypes;

export default Input;
