import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

const LineSkeleton = ({ lineCont }) => {
  return [...Array(lineCont)].map((_, i) => {
    return (
      <TableRow key={i}>
        <TableCell colSpan={4}>
          <Skeleton variant="h3" animation="wave" />
        </TableCell>
      </TableRow>
    );
  });
};
export default LineSkeleton;
