import * as Yup from 'yup';

const REQUIRED = 'Este campo es requerido';

const validationSchemaProfile = Yup.object().shape({
  dateOfBirth: Yup.date('Formato de fecha inválido')
    .max(new Date())
    .required(REQUIRED),
  personalEmail: Yup.string()
    .email('Formato de email inválido')
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  otherGender: Yup.string().max(250, 'Maximo de caracteres pasado (250)'),
  genderPronouns: Yup.string().max(250, 'Maximo de caracteres pasado (250)'),
  document: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  phoneNumber: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  addres: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  emergencyContactName: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  emergencyContactPhone: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  emergencyContactEmail: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .email('Formato de email inválido')
    .required(REQUIRED),
  heathcare: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  civilStatus: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  academicLevel: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  career: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  specialConditions: Yup.string().max(250, 'Maximo de caracteres pasado (250)'),
  bankName: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  bankAccountNumber: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  bankAccountType: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
  shirtSize: Yup.string()
    .max(250, 'Maximo de caracteres pasado (250)')
    .required(REQUIRED),
});

export default validationSchemaProfile;
