import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: '18px',
    width: '25%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
  },
  closeButtonStyle: {
    position: 'relative',
    top: '-3rem',
    right: '-1rem',
  },
  cancelButton: {
    width: '48%',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: '700',
  },
  deleteButton: {
    width: '48%',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: '700',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 2,
  },
  title: {
    fontWeight: '700',
    fontSize: '22px',
    paddingBottom: '2rem',
    paddingLeft: '1rem',
    textAlign: 'center',
  },
}));

export default useStyles;
