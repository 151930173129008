import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import AddEventForm from './AddEventForm';
import addEmployee from './style';

const propTypes = {
  getAllEventsHandler: PropTypes.func,
};

const defaultPropTypes = {
  getAllEventsHandler: null,
};

const AddEventModal = ({ getAllEventsHandler }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        variant="text"
        onClick={handleOpen}
        sx={{
          textTransform: 'none',
          borderRadius: '16px',
          backgroundColor: '#F8F9FC',
          color: '#7e62cc',
        }}>
        <AddIcon
          sx={{
            color: '#7e62cc',
            cursor: 'pointer',
          }}
        />
        <Typography style={addEmployee.addEmployees}>Agregar evento</Typography>
      </Button>
      <Modal open={open} onClose={handleClose}>
        <AddEventForm
          handleClose={handleClose}
          getAllEventsHandler={getAllEventsHandler}
        />
      </Modal>
    </div>
  );
};
AddEventModal.propTypes = propTypes;
AddEventModal.defaultProps = defaultPropTypes;

export default AddEventModal;
