export const homeContainer = {
  display: 'flex',
  height: '100vh',
  overflow: 'auto',
};

export const toolbarHome = {
  background: 'white',
};

export const linkAvatar = {
  right: '0px',
  position: 'absolute',
  marginRight: '10px',
};

export const toolbarSpaceIcon = {
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const navigationContainer = {
  height: '100vh',
  overflow: 'hidden',
};

export const logoutContainer = {
  bottom: 0,
  position: 'absolute',
  width: '100%',
};

export const drawerCustomStyle = {
  overflow: 'auto',
};

export const mainContainer = {
  flexGrow: 1,
  height: '100vh',
  overflow: 'scroll',
  background: '#f6f6f6',
  overflowX: 'hidden',
};
