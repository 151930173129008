import hrApi from './hrApi';
import { errorHandling } from './apiHelpers';

const getFile = async (id) => {
  const response = await hrApi.get(`/storage/${id}`, {});

  if (response?.status !== 200) {
    errorHandling(response.status);
  }

  return response;
};

export default getFile;
