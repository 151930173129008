import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';

import { ReactComponent as CelebrateIcon } from '../../../assets/CelebrateIcon.svg';
import { PATHS } from '../../../api/apiConstants';
import useStyles from './style';

const propTypes = {
  handleClose: PropTypes.func,
};

const defaultPropTypes = {
  handleClose: undefined,
};

const CompleteProfileModal = ({ handleClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const naviagteToProfile = () => {
    navigate(PATHS.PROFILE);
    handleClose();
  };

  return (
    <Box className={classes.container}>
      <CelebrateIcon className={classes.logo} />
      <div className={classes.textContainer}>
        <Typography className={classes.title}>Bienvenido a SpaceHR,</Typography>
        <Typography>Te invitamos a que completes tu perfil</Typography>
      </div>
      <Button onClick={naviagteToProfile} variant="contained">
        Completar perfil
      </Button>
    </Box>
  );
};

CompleteProfileModal.propTypes = propTypes;
CompleteProfileModal.defaultProps = defaultPropTypes;

export default CompleteProfileModal;
