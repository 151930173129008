import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
  },
  tableContainer: {
    margin: 'auto',
    borderRadius: 18,
  },
  tableHead: {
    backgroundColor: 'rgba(8, 47, 63, 0.05)',
    height: '24px',
  },
  rowApproved: {
    backgroundColor: theme.palette.approvedRequest.main,
  },
  rowPending: {
    backgroundColor: theme.palette.pendingRequest.main,
  },
  topTab: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    padding: '0 2%',
    borderRadius: '50px 50px 0px 0px',
  },
  headCells: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
  },
  newRequest: {
    justifyContent: 'space-around',
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '12px',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    cursor: 'pointer',
  },
  newRequestButton: {
    borderRadius: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  textContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
  },
  textRow: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
  },
  button: {
    textTransform: 'none',
    borderRadius: '16px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  addIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  logo: {
    width: '36px',
    height: '36px',
    paddingRight: 10,
  },
  firstTableCell: {
    display: 'flex',
    flexDirection: 'row',
  },
  colorCircle: {
    marginRight: 5,
    position: 'relative',
    top: 6,
  },
  pendingCircle: {
    marginRight: 5,
    position: 'relative',
    top: 6,
  },
}));
export default useStyles;
