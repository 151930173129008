import moment from 'moment';

const temp = {
  name: '',
  startDateAndTime: null,
  endDateAndTime: null,
  date: null,
  place: '',
};

export default temp;
