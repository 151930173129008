import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './app/store';
import i18n from './localization';
import { AuthContextProvider } from './api/auth-contex';
import UserActivityTimer from './features/UserActivityTimer';
import theme from './theme/index';

const toastOptions = {
  position: 'top-center',
  autoClose: 1600,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <AuthContextProvider>
          <BrowserRouter>
            <ToastContainer
              position={toastOptions.position}
              autoClose={toastOptions.autoClose}
              hideProgressBar={toastOptions.hideProgressBar}
              closeOnClick={toastOptions.closeOnClick}
              pauseOnHover={toastOptions.pauseOnHover}
              draggable={toastOptions.draggable}
              progress={toastOptions.progress}
            />
            <UserActivityTimer />
            <App />
          </BrowserRouter>
        </AuthContextProvider>
      </I18nextProvider>
    </ThemeProvider>
  </Provider>,

  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
