import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '18px',
    width: '40%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '1rem',
  },
  closeButtonStyle: {
    left: '90%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '20px',
    paddingBottom: '1rem',
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    lineHeight: '1.4rem',
    fontSize: '16px',
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '1rem',
  },
  tableHead: {
    backgroundColor: 'lightgray',
  },
}));

export default useStyles;
