import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import {
  Button,
  Grid,
  MenuItem,
  Box,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import useFileUpload from '../../../../hooks/useFileUpload';
import Input from '../../../shared/Input';
import {
  acceptFileTypes,
  checkFileType,
  errorHandling,
} from '../../../../../src/api/apiHelpers';
import userApi from '../../../../../src/api/userApi';
import {
  USER_POSITION,
  HTTP_STATUS,
  PRIVILEGE,
  PRIVILEGE_LABEL,
  TABLE_TYPE,
} from '../../../../../src/api/apiConstants';
import useStyles from './style';
import temp from './BaseEmployeeInfo';
import validationSchemaAddEmployee from './validationSchemaAddEmployee';

const propTypes = {
  getAllUsersHandler: PropTypes.func,
  handleClose: PropTypes.func,
};

const defaultPropTypes = {
  getAllUsersHandler: null,
  handleClose: null,
};

const AddEmployeeForm = ({ handleClose, getAllUsersHandler }) => {
  const [fileUrl, setFileUrl] = useState('');
  const [tempFile, setTempFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, uploadFile, error] = useFileUpload(setFileUrl);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (progress === 100 && fileUrl) {
      setIsFileLoaded(true);
    } else if (error) {
      errorHandling(error.response.status);
    }
  }, [error, fileUrl, progress]);

  const saveData = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        name: `${values.userName} ${values.userLastname}`,
        email: values.personalEmail,
        position: values.position,
        privilege: values.admin,
        admissionDate: (values.dateAdmission = moment(
          values.dateAdmission,
        ).toDate()),
        workload: values.weeklyHours,
      };
      const resp = await userApi.create(data);
      if (resp?.status === HTTP_STATUS.CREATED) {
        toast.success('¡Empleado creado!');
        const userId = resp?.data?.data;

        if (tempFile) {
          uploadFile(tempFile, userId, TABLE_TYPE.USER);
        }

        if (getAllUsersHandler && handleClose) {
          getAllUsersHandler();
          handleClose();
        } else {
          errorHandling(error.response.status);
        }
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <Formik
        initialValues={temp}
        validationSchema={validationSchemaAddEmployee}
        enableReinitialize={true}
        onSubmit={(values) => {
          saveData(values);
        }}>
        {({ values, dirty, handleChange, setFieldValue, errors }) => {
          return (
            <Form>
              <Box className={classes.boxContainer}>
                <Typography className={classes.title}>
                  Agregar empleado
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6} className={classes.inputItem}>
                    <Input
                      variant="outlined"
                      value={values.userName}
                      label="Nombre"
                      name="userName"
                      helperText={errors.userName}
                      id="userName"
                      onChange={handleChange}
                      required
                      type={'userName'}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.inputItem}>
                    <Input
                      variant="outlined"
                      value={values.userLastname}
                      label="Apellidos"
                      helperText={errors.userLastname}
                      name="userLastname"
                      id="userLastname"
                      onChange={handleChange}
                      required
                      type={'userLastname'}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.dateItem}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        label="Fecha de admisión"
                        name="dateAdmission"
                        id="dateAdmission"
                        value={values.dateAdmission}
                        helperText={errors.dateAdmission}
                        inputFormat="DD/MM/yyyy"
                        onChange={(newValue) => {
                          setFieldValue('dateAdmission', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} className={classes.dateItem}>
                    <TextField
                      name="position"
                      id="position"
                      helperText={errors.position}
                      select
                      required
                      fullWidth
                      value={values.position}
                      label="Posición"
                      variant="outlined"
                      onChange={handleChange}>
                      {Object.values(USER_POSITION).map((value, index) => {
                        return (
                          <MenuItem value={value} key={index}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>

                  <Grid item xs={6} className={classes.textFiledItem}>
                    <TextField
                      name="admin"
                      id="admin"
                      helperText={errors.admin}
                      select
                      required
                      fullWidth
                      value={values.admin}
                      label="Permisos"
                      variant="outlined"
                      onChange={handleChange}>
                      {Object.values(PRIVILEGE).map((value) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {PRIVILEGE_LABEL[value]}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>

                  <Grid item xs={6} className={classes.textFiledItem}>
                    <TextField
                      name="dependentWorker"
                      id="dependentWorker"
                      helperText={errors.dependentWorker}
                      select
                      required
                      fullWidth
                      value={values.dependentWorker}
                      label="Trabajador dependiente"
                      variant="outlined"
                      onChange={handleChange}>
                      <MenuItem value={false}>No</MenuItem>
                      <MenuItem value={true}>Si</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6} className={classes.inputItem}>
                    <Input
                      variant="outlined"
                      value={values.weeklyHours}
                      label="Horas semanales"
                      name="weeklyHours"
                      helperText={errors.weeklyHours}
                      id="weeklyHours"
                      onChange={handleChange}
                      required
                      type={'number'}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.inputItem}>
                    <Input
                      id="email"
                      value={values.personalEmail}
                      label="Email"
                      variant="outlined"
                      name="personalEmail"
                      helperText={errors.personalEmail}
                      onChange={handleChange}
                      required
                      type={'email'}
                    />
                  </Grid>
                </Grid>
                {tempFile ? (
                  <Grid item xs={12}>
                    <Button
                      component="label"
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.attachmentButton}>
                      {tempFile.target.files[0].name}
                      <input
                        onChange={(e) => setTempFile(checkFileType(e))}
                        type="file"
                        hidden
                        accept={acceptFileTypes()}
                      />
                    </Button>
                    <IconButton
                      aria-label="Eliminar archivo"
                      onClick={() => {
                        setTempFile(null);
                      }}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Button
                      component="label"
                      size="small"
                      variant="outlined"
                      color="primary"
                      className={classes.attachmentButton}
                      startIcon={<AttachmentOutlinedIcon />}>
                      {/* TODO: fix accessibility*/}
                      <input
                        onChange={(e) => setTempFile(checkFileType(e))}
                        type="file"
                        hidden
                        accept={acceptFileTypes()}
                      />
                      Adjuntar
                    </Button>
                  </Grid>
                )}
                <LoadingButton
                  fullWidth
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  sx={{
                    textTransform: 'none',
                  }}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained">
                  Agregar empleado
                </LoadingButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

AddEmployeeForm.propTypes = propTypes;
AddEmployeeForm.defaultProps = defaultPropTypes;

export default AddEmployeeForm;
