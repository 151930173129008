import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: '18px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '40%',
    maxHeight: '90%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    padding: '0 0 1rem 2.5rem',
  },
  container: { width: '100%' },
  subContainer: {
    borderRadius: '18px',
    backgroundColor: 'rgba(90, 95, 97, 0.08);',
    width: '100%',
    padding: '2%',
  },
  contact: {
    borderRadius: '8px',
    border: '1px solid #B5BBBD',
    backgroundColor: '#E7EDEF',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '2px',
    alignItems: 'center',
    marginRight: '1rem',
    color: theme.palette.iconColor.main,
  },
  logo: {
    paddingRight: '4px',
    paddingLeft: '2px',
    color: theme.palette.iconColor.main,
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputItem: {
    paddingBottom: '3%',
  },
  text: {
    fontSize: '16px',
    margin: '0.1rem',
  },
  item: {
    fontSize: '16px',
    fontWeight: '700',
    margin: '0.1rem',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: '700',
    marginRight: '4px',
    marginBottom: '4px',
    color: theme.palette.iconColor.main,
  },

  dateItem: {
    paddingBottom: '4%',
  },
  logoTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '2px',
    alignItems: 'center',
    marginBottom: '0.6rem',
  },
  rowInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
    paddingTop: '2%',
    paddingBottom: '3%',
  },
  name: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
  },
  viewButton: {
    color: '#5A5F61',
    cursor: 'pointer',
  },
  buttonContainer: {
    borderRadius: '8',
    color: '#5A5F61',
  },
}));

export default useStyles;
