import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import userApi from '../../../../api/userApi';
import {
  errorHandling,
  getUserId,
  getUserName,
} from '../../../../api/apiHelpers';
import useStyles from './style';

const WelcomeCard = () => {
  const classes = useStyles();
  const [name, setName] = useState(getUserName());

  const getData = async () => {
    try {
      const {
        data: { data },
      } = await userApi.getUserNameById(getUserId());

      if (!!data) {
        setName(data);
      } else {
        setName(getUserName());
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Box className={classes.welcomeContainer}>
      <Typography variant="h6" className={classes.welcomeText}>
        Welcome back,
      </Typography>
      <Typography variant="h4" className={classes.welcomeText}>
        {name}
      </Typography>
    </Box>
  );
};

export default WelcomeCard;
