import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';

import { Modal, Grid } from '@mui/material';
import CompleteProfileModal from '../../../components/Profile/CompleteProfileModal/CompleteProfileModal';
import { errorHandling, getUserId } from '../../../api/apiHelpers';
import userInfoApi from '../../../api/userInfoApi';
import EventsTable from '../Utils/EventsTable/EventsTable';
import EmployeesTable from '../Utils/EmployeesTable/EmployeesTable';
import { DISABLE_CLOSE_MODAL } from '../../../api/apiConstants';
import BalanceTable from '../Utils/BalanceTable/BalanceTable';
import settingsStyles from './style';

const Setting = () => {
  const [value, setValue] = useState('0');
  const [openModal, setOpenModal] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const isNewUser = async () => {
    try {
      const {
        data: { data },
      } = await userInfoApi.getByUser(getUserId());
      if (!data.bankAccountNumber) {
        setIsNew(true);
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  useEffect(() => {
    isNewUser();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <TabContext value={value}>
          <Grid item xs={12}>
            <div style={settingsStyles.menuNavContainer}>
              <Box style={settingsStyles.menuNav}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  centered
                  textColor="primary"
                  indicatorColor="primary"
                  style={settingsStyles.tabActive}>
                  <Tab
                    style={settingsStyles.tab}
                    sx={{ textTransform: 'none' }}
                    value="0"
                    label="Empleados"
                  />
                  <Tab
                    style={settingsStyles.tab}
                    sx={{ textTransform: 'none' }}
                    value="1"
                    label="Balances"
                  />
                  <Tab
                    style={settingsStyles.tab}
                    sx={{ textTransform: 'none' }}
                    value="2"
                    label="Eventos"
                  />
                </Tabs>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value="0">
              <EmployeesTable />
            </TabPanel>
            <TabPanel value="1">
              <BalanceTable />
            </TabPanel>
            <TabPanel value="2">
              <EventsTable />
            </TabPanel>
          </Grid>
        </TabContext>
      </Grid>
      {isNew ? (
        <Modal
          open={openModal}
          onClose={(_, reason) => {
            if (reason !== DISABLE_CLOSE_MODAL.BACKDROPCLICK) {
              handleCloseModal();
            }
          }}>
          <CompleteProfileModal handleClose={handleCloseModal} />
        </Modal>
      ) : null}
    </div>
  );
};
export default Setting;
