import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: 'auto',
    maxWidth: '1500px',
    borderRadius: '8px',
  },
  tableHead: {
    backgroundColor: 'rgba(8, 47, 63, 0.05)',
    height: '24px',
  },
  headRow: {
    height: '24px',
  },
  topTab: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    paddingLeft: '3%',
    paddingRight: '5%',
    borderRadius: '50px 50px 0px 0px',
  },
  actionCell: {
    flexDirection: 'row',
    display: 'flex',
  },
  headCellsMid: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'center',
  },
  headCellsLeft: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
  },
  headCellsRight: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'right',
    paddingRight: '4%',
  },
  employeesText: {
    paddingLeft: '5%',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
  },
  addEmployeesButton: {
    borderRadius: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  employeesTextContainer: {
    justifyContent: 'space-around',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
  },
  textRowLeft: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
  },
  textRowMid: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
  },
  textRowRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  logo: {
    width: '36px',
    height: '36px',
  },
  buttonContainer: {
    borderRadius: '8',
    color: '#5A5F61',
  },
  editButton: {
    color: '#7E62CC',
    cursor: 'pointer',
  },
  deleteButton: {
    cursor: 'pointer',
  },
}));

export default useStyles;
