import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const propTypes = {
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  setFieldValue: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.string,
};

const defaultPropTypes = {
  error: '',
  id: '',
  name: '',
  required: false,
  label: '',
  helperText: '',
  setFieldValue: null,
  color: 'primary',
  variant: '',
  value: '',
};

const NumberControllInput = ({
  id,
  name,
  required,
  label,
  helperText,
  setFieldValue,
  color,
  variant,
  value,
}) => {
  return (
    <TextField
      id={id}
      name={name}
      required={required}
      label={label}
      type="number"
      helperText={helperText}
      variant={variant}
      color={color}
      style={{
        borderRadius: '12px',
        borderColor: '#E7EDEF',
        borderStyle: 'solid',
      }}
      value={value}
      fullWidth
      margin="normal"
      InputProps={{
        readOnly: true,
        form: {
          autoComplete: 'off',
        },
        inputProps: { style: { textAlign: 'center' } },
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="Restar un dia"
              onClick={() => {
                setFieldValue(id, value - 1);
              }}
              edge="start">
              <RemoveIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Agregar un dia"
              onClick={() => {
                setFieldValue(id, value + 1);
              }}
              edge="end">
              <AddIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

NumberControllInput.propTypes = propTypes;
NumberControllInput.defaultProps = defaultPropTypes;

export default NumberControllInput;
