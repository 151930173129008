import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import DoneIcon from '@mui/icons-material/Done';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

import {
  getOverlappingRequests,
  errorHandling,
  getUserName,
  translateType,
} from '../../../../api/apiHelpers';
import { parseDateDdMmYyyy } from '../../../../Utils/parseDate';
import LineSkeleton from '../../../shared/LineSkeleton';
import { ReactComponent as RequestsLogo } from '../../../../assets/requestsLogo.svg';
import logRequestApi from '../../../../api/logRequestApi';
import { STATUS, REQUEST_TYPE_EN } from '../../../../api/apiConstants';
import RejectedRequestButton from '../RejectedRequest/RejectedRequestButton';
import ViewRequestModal from '../ViewRequestModal/ViewRequestModal';
import RejectedRequestForm from '../RejectedRequest/RejectedRequestForm';
import OverlappingRequestsModal from '../OverlappingRequestsModal/OverlappingRequestsModal';
import OverlappingRequestsUserModal from '../OverlappingRequestsUserModal/OverlappingRequestsUserModal';
import OldRequestModal from '../OldRequestModal/OldRequestModal';
import useStyles from './style';

const AwaitingRequestsTable = () => {
  const [pendingRequestsData, setPendingRequestsData] = useState([]);
  const [approvedRequestsData, setApprovedRequestsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [usingPatch, setUsingPatch] = useState(false);

  // Modal state managment
  const [open, setOpen] = useState(false);
  const [openSmallModal, setOpenSmallModal] = useState(false);
  const [openOverlapModal, setOpenOverlapModal] = useState(false);
  const [openOverlapUserModal, setOpenOverlapUserModal] = useState(false);
  const [openOldRequestModal, setOpenOldRequestModal] = useState(false);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRejectPressed = () => {
    setOpen(false);
    setOpenSmallModal(true);
  };

  const handleCloseSmallModal = () => {
    setOpenSmallModal(false);
  };

  const handleCloseOverlapModal = () => {
    setOpenOverlapModal(false);
  };

  const handleCloseOldRequestModal = () => {
    setOpenOldRequestModal(false);
  };

  const handleCloseOverlapUserModal = () => {
    setOpenOverlapUserModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllRequestsHandler = async () => {
    try {
      setIsLoadingRequest(true);

      const {
        data: { data },
      } = await logRequestApi.getAll();

      setPendingRequestsData(
        data.filter(
          (request) => request.status === STATUS.PENDING && request.user.active,
        ),
      );

      setApprovedRequestsData(
        data.filter(
          (request) =>
            request.status === STATUS.APPROVED && request.user.active,
        ),
      );
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setUsingPatch(false);
      setIsLoadingRequest(false);
    }
  };

  const checkIfRequestIsOld = (values) => {
    if (new Date(values.startDate) <= new Date()) {
      return true;
    }
  };

  const updateRequestStatus = async (values, answer, status, isInModal) => {
    try {
      setUsingPatch(true);
      const id = values.id;
      const data = {
        status: status,
        answer,
        reviewedBy: getUserName(),
      };

      if (status === STATUS.REJECTED) {
        const resp = await logRequestApi.patchRequestStatus(id, data);

        if (resp?.status === 200) {
          toast.success('Solicitud actualizada');
        }
      }

      if (status === STATUS.APPROVED) {
        const approvedRequestsDataOfUser = approvedRequestsData.filter(
          (request) => request.userId === values.userId,
        );

        const overlappingEvents = getOverlappingRequests(
          approvedRequestsData,
          values,
        );

        const overlappingEventsOfSameUser = getOverlappingRequests(
          approvedRequestsDataOfUser,
          values,
        );

        const isOld = checkIfRequestIsOld(values);

        if (isInModal) {
          const resp = await logRequestApi.patchRequestStatus(id, data);
          if (resp?.status === 200) {
            toast.success('Solicitud actualizada');
          }

          if (resp?.status === 400) {
            toast.success(
              'Este usuario ya posee una licencia aprobada en este rango de fechas',
            );
          }
        } else if (overlappingEvents?.length > 0 && !isInModal) {
          // There are requests of THE SAME USER overlapping
          if (overlappingEventsOfSameUser.length > 0) {
            setOpenOverlapUserModal(true);
          } else {
            // There are requests overlapping
            setOpenOverlapModal(true);
          }
        } else if (isOld && !isInModal) {
          setOpenOldRequestModal(true);
        } else {
          const resp = await logRequestApi.patchRequestStatus(id, data);
          if (resp?.status === 200) {
            toast.success('Solicitud actualizada');
          }
        }
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      getAllRequestsHandler();
    }
  };

  useEffect(() => {
    getAllRequestsHandler();
  }, []);

  const getStylesByRequestType = (requestType) => {
    switch (requestType) {
      case REQUEST_TYPE_EN.NORMAL:
        return classes.textRowMidNormal;
      case REQUEST_TYPE_EN.SICK:
        return classes.textRowMidSick;
      case REQUEST_TYPE_EN.STUDY:
        return classes.textRowMidStudy;
      case REQUEST_TYPE_EN.SPACE:
        return classes.textRowMidSpace;
      case REQUEST_TYPE_EN.MATERNITY:
        return classes.textRowMidMaternity;
      case REQUEST_TYPE_EN.PATERNITY:
        return classes.textRowMidPaternity;
      case REQUEST_TYPE_EN.MOURNING:
        return classes.textRowMidMourning;
      case REQUEST_TYPE_EN.MARRIAGE:
        return classes.textRowMidMerriage;
      case REQUEST_TYPE_EN.BLOOD_DONATION:
        return classes.textRowMidBlood;
      case REQUEST_TYPE_EN.NOT_PAID:
        return classes.textRowMidNoPaid;
      case REQUEST_TYPE_EN.PAP:
        return classes.textRowMidPap;
      default:
        return '';
    }
  };

  return (
    <div>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div className={classes.topTab}>
          <div className={classes.requestsTextContainer}>
            <RequestsLogo className={classes.logo} />
            <div className={classes.requestsText}>
              <p>Solicitudes pendientes</p>
            </div>
          </div>
        </div>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.headCells}>
              <TableCell className={classes.headCellsLeft}>
                <span>Nombre</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Fecha de inicio</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Fecha de finalización</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Tipo</span>
              </TableCell>
              <TableCell className={classes.headCellsRight}>
                <span>Acción</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingRequest ? (
              pendingRequestsData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover key={row.id}>
                    <TableCell onClick={() => handleOpen(row)}>
                      <div className={classes.textRowLeft}>{row.user.name}</div>
                    </TableCell>
                    <TableCell onClick={() => handleOpen(row)}>
                      <div className={classes.textRowMid}>
                        {parseDateDdMmYyyy(row.startDate)}
                      </div>
                    </TableCell>
                    <TableCell onClick={() => handleOpen(row)}>
                      <div className={classes.textRowMid}>
                        {parseDateDdMmYyyy(row.finishDate)}
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.requestCell}
                      onClick={() => handleOpen(row)}>
                      <div className={getStylesByRequestType(row.type)}>
                        {translateType(row.type)}
                      </div>
                    </TableCell>
                    <TableCell className={classes.textRowRight}>
                      <RejectedRequestButton
                        updateRequestStatus={updateRequestStatus}
                        requestInfo={row}
                        type="cross"
                      />
                      <Button
                        variant="text"
                        disabled={usingPatch}
                        onClick={() => {
                          setSelectedItem(row);
                          updateRequestStatus(row, '', STATUS.APPROVED, false);
                        }}
                        className={classes.aproveButton}>
                        <DoneIcon className={classes.aproveButtonAction} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <LineSkeleton lineCont={rowsPerPage} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        labelRowsPerPage=""
        component="div"
        count={pendingRequestsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal open={open} onClose={handleClose}>
        <ViewRequestModal
          requestInfo={selectedItem}
          handleClose={handleClose}
          handleRejectPressed={handleRejectPressed}
          updateRequestStatus={updateRequestStatus}
        />
      </Modal>

      <Modal open={openSmallModal} onClose={handleCloseSmallModal}>
        <RejectedRequestForm
          requestInfo={selectedItem}
          handleClose={handleCloseSmallModal}
          updateRequestStatus={updateRequestStatus}
        />
      </Modal>

      <Modal open={openOverlapModal} onClose={handleCloseOverlapModal}>
        <OverlappingRequestsModal
          requestInfo={selectedItem}
          handleClose={handleCloseOverlapModal}
          approvedRequestsData={approvedRequestsData}
          updateRequestStatus={updateRequestStatus}
        />
      </Modal>

      <Modal open={openOverlapUserModal} onClose={handleCloseOverlapUserModal}>
        <OverlappingRequestsUserModal
          requestInfo={selectedItem}
          handleClose={handleCloseOverlapUserModal}
          updateRequestStatus={updateRequestStatus}
        />
      </Modal>

      <Modal open={openOldRequestModal} onClose={handleCloseOldRequestModal}>
        <OldRequestModal
          requestInfo={selectedItem}
          handleClose={handleCloseOldRequestModal}
          updateRequestStatus={updateRequestStatus}
        />
      </Modal>
    </div>
  );
};
export default AwaitingRequestsTable;
