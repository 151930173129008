import React, { useState } from 'react';
import { Routes, Route, NavLink, Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import userInfoApi from '../../api/userInfoApi';
import { PublicError } from '../../classes/PublicError';
import Dashboard from '../Dashboard/View/Dashboard';
import {
  errorHandling,
  getUserId,
  getUserRole,
  pathToRoute,
} from '../../api/apiHelpers';
import SpaceLogoGroup from '../../assets/spaceLogoGroup.svg';
import SpaceMenuIcon from '../../assets/spaceMenuIcon.svg';
import { logoutHelper } from '../../api/sessionHelpers';
import CustomAppBar from '../Dashboard/Utils/CustomAppBar';
import CustomDrawer from '../Dashboard/Utils/CustomDrawer';
import { PATHS, ROUTES } from '../../api/apiConstants';
import getFile from '../../api/getFile';
import UserRole from './UserRole.enum';
import { HrRoutes } from './HrRoutes';
import { homeContainer, mainContainer } from './homeStyle';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const isAdmin = getUserRole() === UserRole.ADMIN;
  const [profilePicture, setProfilePicture] = useState(null);
  const [open, setOpen] = useState(true);
  const [activePage, setActivePage] = useState(ROUTES.DASHBOARD);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const sideRoutes = React.useMemo(
    () =>
      HrRoutes.filter((route) => {
        if (route.sideBar) {
          if (isAdmin) {
            return route.requiredRoles.includes(UserRole.ADMIN);
          } else {
            return route.requiredRoles.includes(UserRole.NORMAL);
          }
        }
      }),
    [isAdmin],
  );

  const mainRoutes = React.useMemo(
    () =>
      HrRoutes.filter((route) => {
        if (isAdmin) {
          return route.requiredRoles.includes(UserRole.ADMIN);
        } else {
          return route.requiredRoles.includes(UserRole.NORMAL);
        }
      }),
    [isAdmin],
  );

  const getImg = async () => {
    try {
      const {
        data: { data },
      } = await userInfoApi.getByUser(getUserId());

      if (!data) {
        throw new PublicError('No se pueden obtener los datos de usuario');
      }
      if (data.photo !== null && data.photo !== '') {
        const response = await getFile(data.photo);

        const file = response.data.data;

        const link = file.link;

        setProfilePicture(link);
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  React.useEffect(() => {
    setActivePage(pathToRoute(window.location.pathname));
    getImg();
  }, [activePage]);

  return (
    <div style={homeContainer}>
      <CustomAppBar position="absolute" open={open} elevation={0}>
        <Toolbar className={classes.toolbarHome} elevation={0}>
          <IconButton onClick={toggleDrawer}>
            <img src={SpaceMenuIcon} alt="menuIcon" />
          </IconButton>
          <Link to={PATHS.PROFILE} className={classes.linkAvatar}>
            {profilePicture ? (
              <Avatar src={profilePicture} onClick={() => getImg()} />
            ) : (
              <Avatar src="/broken-image.jpg" onClick={() => getImg()} />
            )}
          </Link>
        </Toolbar>
      </CustomAppBar>
      <CustomDrawer
        variant="permanent"
        open={open}
        className={classes.drawerCustomStyle}>
        <Toolbar className={classes.toolbarSpaceIcon}>
          <img src={SpaceLogoGroup} alt="logo" />
        </Toolbar>
        <Divider />
        <Box className={classes.navigationContainer}>
          <List component="nav">
            {sideRoutes.map((route) => (
              <NavLink
                to={route.path}
                key={route.path}
                style={{ textDecoration: 'none' }}>
                <ListItemButton
                  onClick={() => setActivePage(route.name)}
                  className={
                    open
                      ? route.name === activePage
                        ? classes.activeTypo
                        : classes.inactiveTypo
                      : route.name === activePage
                      ? classes.activeTypoClose
                      : classes.inactiveTypoClose
                  }>
                  <ListItemIcon
                    className={
                      open
                        ? route.name === activePage
                          ? classes.activeLogo
                          : classes.inactiveLogo
                        : route.name === activePage
                        ? classes.activeLogoClose
                        : classes.inactiveLogoClose
                    }>
                    {route.icon}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText selected>
                      <Typography variant={'root'}>{route.name}</Typography>
                    </ListItemText>
                  ) : null}
                </ListItemButton>
              </NavLink>
            ))}
          </List>
          <List
            component="div"
            className={
              open ? classes.logoutContainer : classes.logoutContainerClose
            }
            onClick={() => {
              navigate('');
              logoutHelper();
            }}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" color="secondary" />
            </ListItemButton>
          </List>
        </Box>
      </CustomDrawer>
      <Box component="main" style={mainContainer}>
        <Toolbar />
        <Routes>
          {mainRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="*" element={Dashboard} />
        </Routes>
      </Box>
    </div>
  );
};

export default Home;
