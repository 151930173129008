import hrApi from './hrApi';

// User info data example
// {
//   "dateOfBirth": "2022-03-23T19:41:45.216Z",
//   "personalEmail": "string",
//   "gender": "string",
//   "genderPronouns": "string",
//   "nationality": "string",
//   "countryOfResidence": "string",
//   "document": "string",
//   "phoneNumber": "string",
//   "addres": "string",
//   "emergencyContactName": "string",
//   "emergencyContactPhone": "string",
//   "emergencyContactEmail": "string",
//   "heathcare": "string",
//   "civilStatus": "string",
//   "kids": true,
//   "kidsNames": [
//   "string"
//   ],
//   "academicLevel": "string",
//   "career": "string",
//   "languages": "string",
//   "specialConditions": "string",
//   "bankName": "string",
//   "bankAccountNumber": "string",
//   "bankAccountType": "string",
//   "shirtSize": "string",
//   "photo": "string",
//   "userId": "string"
// }

const userInfoApi = {
  getAll: () => hrApi.get('user-info', {}),

  create: (data) => hrApi.post('user-info', data),

  edit: (id, data) => hrApi.patch(`user-info/${id}`, data),

  updateInfoWithName: (id, data) =>
    hrApi.patch(`user-info/update-info-with-name/${id}`, data),

  delete: (id) => hrApi.delete(`user-info/${id}`, {}),

  get: (id) => hrApi.get(`user-info/${id}`, {}),

  getByUser: (userId) => hrApi.get(`user-info/user/${userId}`, {}),

  firstLogin: (id) => hrApi.get(`user/first-login/${id}`),
};

export default userInfoApi;
