import React, { useEffect, useState } from 'react';
import {
  Modal,
  Typography,
  Backdrop,
  Fade,
  Box,
  TableContainer,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

import logRequestApi from '../../../../api/logRequestApi';
import { ReactComponent as ApprovedCircle } from '../../../../assets/ApprovedCircle.svg';
import { ReactComponent as PendingCircle } from '../../../../assets/PendingCircle.svg';
import { ReactComponent as UpcomingTimeOffLogo } from '../../../../assets/UpcomingTimeOffLogo.svg';
import UserTimeOff from '../../../TimeOff/UserTimeOff';
import {
  getUserId,
  errorHandling,
  formatDate,
  getBusinessDatesCount,
} from '../../../../api/apiHelpers';
import { HTTP_STATUS, REQUEST_STATUS } from '../../../../api/apiConstants';
import {
  TRANSLATED_REQUEST_STATUS,
  TRANSLATED_REQUEST_TYPE,
} from '../../../../constants';
import EditRequestModal from '../../../RequestsHistory/Utils/EditRequestModal/EditRequestModal';
import useStyles from './style';

const UpcomingTimeOff = () => {
  const classes = useStyles();
  const [userRequests, setUserRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const handleClose = () => {
    setOpenRequestModal(false);
    setSelectedItem(null);
    getAllRequestsHandler();
  };
  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpenRequestModal(true);
  };

  const getAllRequestsHandler = async () => {
    try {
      const {
        data: { data },
      } = await logRequestApi.getByUser(getUserId());

      setUserRequests(
        data
          .filter(
            (request) =>
              request.status === REQUEST_STATUS.PENDING ||
              request.status === REQUEST_STATUS.APPROVED,
          )
          .filter((request) => new Date(request.finishDate) > new Date()),
      );
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  const updateRequest = async (values) => {
    try {
      const id = values.id;
      const data = {
        comment: values.comment,
        fileId: values.fileId,
        startDate: values.startDate,
        finishDate: values.finishDate,
        type: values.type,
        answer: values.answer,
      };

      const response = await logRequestApi.edit(id, data);

      if (
        response?.status === HTTP_STATUS.OK ||
        response?.status === HTTP_STATUS.CREATED
      ) {
        toast.success('Solicitud actualizada');
        closeModal();
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      getAllRequestsHandler();
    }
  };

  useEffect(() => {
    getAllRequestsHandler();
  }, []);

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <div className={classes.topTab}>
          <div className={classes.textContainer}>
            <UpcomingTimeOffLogo className={classes.logo} />
            <Typography variant="h6" className={classes.textContainer}>
              Próximas licencias
            </Typography>
          </div>
          <div className={classes.newRequestButton}>
            <Button
              variant="text"
              onClick={openModal}
              className={classes.button}>
              <AddIcon className={classes.addIcon} aria-hidden />
              <div className={classes.newRequest}>Nueva licencia</div>
            </Button>
          </div>
        </div>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>
                <span className={classes.headCells}>Tipo</span>
              </TableCell>
              <TableCell>
                <span className={classes.headCells}>Fecha</span>
              </TableCell>
              <TableCell>
                <span className={classes.headCells}>Días</span>
              </TableCell>
              <TableCell>
                <span className={classes.headCells}>Status</span>
              </TableCell>
              <TableCell>
                <span className={classes.headCells}>Acción</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!userRequests ? (
              userRequests.length > 0 ? (
                userRequests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>
                      <div className={classes.firstTableCell}>
                        {request.status === REQUEST_STATUS.PENDING ? (
                          <PendingCircle className={classes.colorCircle} />
                        ) : (
                          <ApprovedCircle className={classes.colorCircle} />
                        )}

                        <div>{TRANSLATED_REQUEST_TYPE[request.type]}</div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{formatDate(request.startDate)}</div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {getBusinessDatesCount(
                          request.startDate,
                          request.finishDate,
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{TRANSLATED_REQUEST_STATUS[request.status]}</div>
                    </TableCell>
                    {request.status === REQUEST_STATUS.PENDING ? (
                      <TableCell>
                        <Button
                          variant="text"
                          onClick={() => handleOpen(request)}>
                          <EditIcon className={classes.viewButton} />
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Button variant="text" disabled>
                          <EditIcon className={classes.viewButton} />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No has solicitado próximas licencias</TableCell>
                </TableRow>
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={openRequestModal} onClose={handleClose}>
        <Box className={classes.modalStyle}>
          <EditRequestModal
            requestInfo={selectedItem}
            handleClose={handleClose}
            updateRequest={updateRequest}
            getAllRequestsHandler={getAllRequestsHandler}
          />
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Box className={classes.modalStyle}>
            <UserTimeOff
              closeModal={closeModal}
              getAllRequestsHandler={getAllRequestsHandler}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UpcomingTimeOff;
