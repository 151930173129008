import React, { useState, useEffect } from 'react';
import 'react-day-picker/lib/style.css';
import './Styles.css';
import DayPicker, { DateUtils } from 'react-day-picker';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import { Form, Formik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { REQUEST_STATUS, TABLE_TYPE } from '../../api/apiConstants';
import useFileUpload from '../../hooks/useFileUpload';
import {
  acceptFileTypes,
  checkFileType,
  errorHandling,
  getBusinessDatesCount,
  getUserId,
  maxDaysCalculation,
} from '../../api/apiHelpers';
import logRequestApi from '../../api/logRequestApi';
import userDayOffApi from '../../api/userDayOffApi';
import REQUEST_TYPE from './TimeOffTypes.enum';
import useStyles from './timeOffStyle';

const propTypes = {
  closeModal: PropTypes.func,
  getAllRequestsHandler: PropTypes.func,
};

const defaultPropTypes = {
  closeModal: undefined,
  getAllRequestsHandler: undefined,
};

const UserTimeOff = ({ closeModal, getAllRequestsHandler }) => {
  const classes = useStyles();
  const [fileUrl, setFileUrl] = useState('');
  const [tempFile, setTempFile] = useState(null);
  const [progress, uploadFile, error] = useFileUpload(setFileUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [range, setRange] = React.useState({ from: null, to: null });

  useEffect(() => {
    if (progress === 100 && fileUrl) {
      setIsFileLoaded(true);
    } else if (error) {
      errorHandling(error.response.status);
    }
  }, [error, fileUrl, progress]);

  const handleDayClick = (day) => {
    if (
      day > moment(new Date()).subtract(7, 'days').toDate() &&
      day.getDay() !== 0
    ) {
      setRange(DateUtils.addDayToRange(day, range));
    }
  };

  const modifiers = { start: range.from, end: range.to };

  const uploadLogRequest = async (values) => {
    try {
      const data = {
        registrationDate: new Date(),
        startDate: range.from,
        finishDate: range.to,
        comment: values.description,
        type: values.type,
        reviewedBy: '',
        fileId: '',
        status: REQUEST_STATUS.PENDING,
        answer: '',
        userId: getUserId(),
      };
      const resp = await logRequestApi.post(data);
      toast.success('Pedido de solicitud realizado');

      const requestId = resp?.data?.data?.id;

      if (tempFile) {
        await uploadFile(tempFile, requestId, TABLE_TYPE.LOG_REQUEST);
      }

      await getAllRequestsHandler();
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  const userData = async () => {
    try {
      const {
        data: { data },
      } = await userDayOffApi.getByUser(getUserId());
      return data;
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const _values = {
        ...values,
        type: values.type === '' ? REQUEST_TYPE.NORMAL : values.type,
      };
      const data = await userData();
      const result = await maxDaysCalculation(_values, data, range);
      if (result.error) {
        toast.error(result.error);
        setFinalDay(result.maxDays);
      } else {
        await uploadLogRequest(_values);
        closeModal();
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  const setFinalDay = (days) => {
    let final = moment(new Date(range.from)).add(days, 'days');

    while (getBusinessDatesCount(range.from, final.toDate()) < days) {
      final = final.add(1, 'days');
    }

    handleDayClick(final.toDate());
  };

  return (
    <Paper className={classes.containerDayOff}>
      <DialogTitle id="id">
        <Box className={classes.titleContainer}>
          <Typography variant="h5" className={classes.text}>
            Nueva licencia
          </Typography>
          <Box>
            <IconButton
              aria-label="close modal"
              className={classes.closeButtonStyle}
              onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider variant="middle" />
      <DayPicker
        className="Selectable"
        disabledDays={[
          { before: moment(new Date()).subtract(15, 'days').toDate() },
          { daysOfWeek: [0] },
        ]}
        numberOfMonths={2}
        selectedDays={[range.from, range]}
        modifiers={modifiers}
        onDayClick={handleDayClick}
      />
      <Formik
        initialValues={{
          type: REQUEST_TYPE.NORMAL,
          description: '',
        }}
        onSubmit={onSubmit}>
        {({ values, handleChange, dirty, handleSubmit }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="type"
                  select
                  fullWidth
                  value={values.type}
                  label="Tipo de licencia"
                  margin="normal"
                  variant="outlined"
                  required
                  className={classes.inputStyle}
                  onChange={handleChange}>
                  <MenuItem value={REQUEST_TYPE.NORMAL}>Normal</MenuItem>
                  <MenuItem value={REQUEST_TYPE.SICK}>Enfermedad</MenuItem>
                  <MenuItem value={REQUEST_TYPE.STUDY}>Estudio</MenuItem>
                  <MenuItem value={REQUEST_TYPE.SPACE}>Space</MenuItem>
                  <MenuItem value={REQUEST_TYPE.MATERNITY}>Maternal</MenuItem>
                  <MenuItem value={REQUEST_TYPE.PATERNITY}>Paternal</MenuItem>
                  <MenuItem value={REQUEST_TYPE.MOURNING}>Duelo</MenuItem>
                  <MenuItem value={REQUEST_TYPE.MARRIAGE}>Matrimonial</MenuItem>
                  <MenuItem value={REQUEST_TYPE.PAP}>PAP</MenuItem>
                  <MenuItem value={REQUEST_TYPE.BLOOD_DONATION}>
                    Donación de sangre
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE.NOT_PAID}>No paga</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  className={classes.inputStyle}
                  multiline
                  autoFocus
                  maxRows={2}
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  inputProps={{ maxLength: 250 }}
                  placeholder="Descripción (max 250)"
                  required
                />
              </Grid>
              {tempFile ? (
                <Grid item xs={12}>
                  <Button
                    component="label"
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.attachmentButton}>
                    {tempFile.target.files[0].name}
                    <input
                      onChange={(e) => setTempFile(checkFileType(e))}
                      type="file"
                      hidden
                      accept={acceptFileTypes()}
                    />
                  </Button>
                  <IconButton
                    aria-label="Eliminar archivo"
                    onClick={() => {
                      setTempFile(null);
                    }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Button
                    component="label"
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={<AttachmentOutlinedIcon />}>
                    {/* TODO: fix accessibility*/}
                    <input
                      onChange={(e) => setTempFile(checkFileType(e))}
                      type="file"
                      hidden
                      accept={acceptFileTypes()}
                    />
                    Adjuntar
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  color="primary"
                  onClick={handleSubmit}
                  type="submit"
                  loading={isLoading}
                  sx={{ borderRadius: 2 }}
                  disabled={!dirty}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained">
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

UserTimeOff.propTypes = propTypes;
UserTimeOff.defaultProps = defaultPropTypes;

export default UserTimeOff;
