import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: '18px',
    position: 'absolute',
    height: '85%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    paddingBottom: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    padding: '1rem',
  },
  subContainer: {
    borderRadius: '18px',
    backgroundColor: 'rgba(90, 95, 97, 0.08);',
    width: '100%',
    padding: '2%',
  },
  contact: {
    borderRadius: '8px',
    border: '1px solid #B5BBBD',
    backgroundColor: '#E7EDEF',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '2px',
    alignItems: 'center',
    marginRight: '1rem',
    color: theme.palette.iconColor.main,
  },
  logo: {
    paddingRight: '4px',
    paddingLeft: '3px',
    color: theme.palette.iconColor.main,
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputItem: {
    paddingBottom: '3%',
  },
  subttile: {
    fontSize: '14px',
    fontWeight: '700',
    marginRight: '4px',
    marginBottom: '4px',
  },
  dateItem: {
    paddingBottom: '4%',
  },
  logoTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '2px',
    alignItems: 'center',
    marginRight: '1rem',
  },
  rowInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
    paddingTop: '2%',
    marginLeft: '1rem',
  },
  name: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
  },
  viewButton: {
    cursor: 'pointer',
  },
  buttonContainer: {
    borderRadius: '8',
    color: theme.palette.primary.main,
  },
  tableContainer: {
    margin: 'auto',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'scroll',
  },
  formContainer: {
    borderRadius: '18px',
    backgroundColor: 'white',
    maxHeight: '90%',
    maxWidth: '900px',
    padding: '1rem 3rem 1rem 3rem',
    marginBottom: '2rem',
  },
  profileContainer: {
    margin: 'auto',
    borderRadius: '8px',
  },
  titleContainer: {
    marginLeft: '1rem',
    marginTop: '1rem',
  },
  buttonKidsStyle: {
    marginTop: '1rem',
    marginBottom: '1rem',
    marginLeft: '1rem',
  },
  kidsContainer: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  kidsNameContainer: {
    marginBottom: '1rem',
  },
  text: {
    fontWeight: '700',
  },
  gridContainerDate: {
    marginTop: '15px',
  },
}));

export default useStyles;
