import moment from 'moment';

const temp = {
  personalEmail: '',
  userName: '',
  userLastname: '',
  weeklyHours: '',
  admin: '',
  dependentWorker: '',
  dateAdmission: null,
  position: '',
};

export default temp;
