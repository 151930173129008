import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import SpaceLogoGroup from '../../../../assets/spaceLogoGroup.svg';
import Input from '../../../shared/Input';
import { errorHandling } from '../../../../api/apiHelpers';
import {
  HTTP_STATUS,
  PASSWORD_TYPE,
  PATHS,
} from '../../../../api/apiConstants';
import { PublicError } from '../../../../classes/PublicError';
import userApi from '../../../../api/userApi';
import validationSchemaRecoverPassword from '../Utils/validationSchemaRecoverPassword';
import useStyles from './style';

const RecoverPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const initialValues = {
    email: '',
  };

  const sendEmailRecoverPassword = async (values) => {
    try {
      const { data } = await userApi.postSendMailToRecoverPassword(
        values.email,
        PASSWORD_TYPE.RECOVER,
      );
      if (!data) {
        throw new PublicError('Error al enviar mail para recuperar contraseña');
      }
      navigate(PATHS.DASHBOARD);
      toast.success('Mail enviado');
    } catch (error) {
      if (error.response.status === HTTP_STATUS.NOT_FOUND) {
        toast.error('El email ingresado no está registrado');
      } else {
        errorHandling(error.response.status);
      }
    }
  };

  return (
    <>
      <div className={classes.topTab}>
        <img src={SpaceLogoGroup} alt="SpaceDev logo" />
      </div>
      <div className={classes.container}>
        <div className={classes.newPassContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              Recuperar contraseña
            </Typography>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaRecoverPassword}
            onSubmit={sendEmailRecoverPassword}
            validateOnBlur>
            {(formik) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <Input
                    id="email"
                    autoComplete="off"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    value={formik.values.email}
                    helperText={formik.errors.email}
                    onChange={formik.handleChange}
                    label="Ingrese su mail"
                    type="text"
                    variant="outlined"
                  />

                  <Button
                    className={classes.button}
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth>
                    Enviar mail
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default RecoverPassword;
