import * as React from 'react';
import { Form, Formik, FieldArray } from 'formik';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import DatePicker from '@mui/lab/DatePicker';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Box,
  Typography,
  Autocomplete,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
} from '@mui/material';
import { toast } from 'react-toastify';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import useFileUpload from '../../hooks/useFileUpload';
import { PublicError } from '../../classes/PublicError';
import userApi from '../../api/userApi';
import Input from '../shared/Input';
import {
  acceptFileTypes,
  checkFileType,
  errorHandling,
  getUserId,
  getUserName,
  translateValueType,
} from '../../../src/api/apiHelpers';
import userInfoApi from '../../../src/api/userInfoApi';
import {
  SHIRT_SIZE,
  CIVIL_STATUS,
  HTTP_STATUS,
  GENDER_EN,
  GENDER_ES,
  PATHS,
  countries,
  languages,
  TABLE_TYPE,
} from '../../../src/api/apiConstants';
import useStyles from './style';
import temp from './BaseUserInfo';
import validationSchemaProfile from './validationSchemaProfile';

const Profile = () => {
  const [otherGender, setOtherGender] = React.useState('');
  const [userInfo, setUserInfo] = React.useState(temp);
  const [fileUrl, setFileUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [progress, uploadFile, error] = useFileUpload(setFileUrl);
  const [tempFile, setTempFile] = React.useState(null);
  const [isNew, setIsNew] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const setUserData = async () => {
    try {
      const {
        data: { data },
      } = await userInfoApi.getByUser(getUserId());
      if (!data) {
        throw new PublicError('No se pueden obtener los datos de usuario');
      }
      const response = await userApi.get(data.userId);

      const baseInfo = response?.data?.data;

      if (!baseInfo) {
        throw new PublicError(
          'No se puede obtener la información base del usuario',
        );
      }

      if (Object.keys(data).length !== 0) {
        data.dateOfBirth = moment(data.dateOfBirth).format('YYYY-MM-DD');
        if (
          data.gender !== GENDER_EN.MALE &&
          data.gender !== GENDER_EN.FEMALE
        ) {
          data.otherGender = data.gender;
          data.gender = GENDER_EN.OTHER;
          setOtherGender(data.gender);
        }

        if (data.genderPronouns) {
          data.genderPronounsOption = true;
        } else {
          data.genderPronounsOption = false;
          data.genderPronouns = '';
        }

        data.languages = data.languages.split(',');

        data.kids = data.kidsNames.length > 0;
        data.personalEmail = baseInfo.email;

        const nameResponse = await userApi.getUserNameById(getUserId());

        data.fullName = nameResponse.data.data;

        setUserInfo(data);
      } else {
        setIsNew(true);
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  const formatData = (values) => {
    const finalData = { ...values };
    finalData.dateOfBirth = moment(finalData.dateOfBirth).toDate();

    if (finalData.genderPronounsOption === false) {
      finalData.genderPronouns = null;
    }

    if (finalData.gender === GENDER_EN.OTHER) {
      finalData.gender = finalData.otherGender;
    }

    if (finalData.kids === false) {
      finalData.kidsNames = [];
    }

    if (finalData.countryOfResidence.label) {
      finalData.countryOfResidence = finalData.countryOfResidence.label;
    }

    if (Array.isArray(finalData.languages)) {
      const index = finalData.languages.indexOf('');

      if (index > -1) {
        finalData.languages.splice(index, 1);
      }

      finalData.languages = finalData.languages.toString();
    }

    return finalData;
  };

  const saveData = async (values) => {
    setIsLoading(true);
    if (isNew) {
      try {
        const resp = await userInfoApi.create(values);
        if (resp?.status === HTTP_STATUS.OK) {
          toast.success('Datos guardados correctamente');
          navigate(PATHS.DASHBOARD);
        }
      } catch (error) {
        errorHandling(error.response.status);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const name = values.fullName;
        delete values.fullName;
        const userInfo = { ...values };

        const resp = await userInfoApi.updateInfoWithName(getUserId(), {
          name,
          userInfo,
        });

        if (resp?.status === HTTP_STATUS.OK) {
          toast.success('Datos guardados correctamente');
          navigate(PATHS.DASHBOARD);
        }
      } catch (error) {
        if (
          error.response.data.message &&
          error.response.status === HTTP_STATUS.BAD_REQUEST
        ) {
          let response = 'Datos no validos: ';
          error.response.data.message.foreach((error) => {
            response += `${translateValueType(error)}. `;
          });
          toast.error(response);
        } else {
          errorHandling(error.response.status);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const uploadImg = async (tempFile) => {
    setIsLoading(true);
    try {
      if (tempFile && userInfo.id) {
        await uploadFile(tempFile, userInfo.id, TABLE_TYPE.USER_INFO);
      }
      toast.success('Se guardo la imagen');
    } catch (e) {
      toast.error('No se pudo cargar la imagen');
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setUserData();
  }, []);

  return (
    <div className={classes.profileContainer}>
      <div className={classes.titleContainer}>
        <Typography variant={'h5'}>Mi perfil</Typography>
        <LoadingButton
          component="label"
          size="small"
          sx={{ marginTop: '1rem' }}
          loading={isLoading}
          variant="outlined"
          color="primary"
          className={classes.button}
          startIcon={<UploadIcon />}>
          {/* TODO: fix accessibility*/}
          <input
            onChange={(e) => uploadImg(checkFileType(e))}
            type="file"
            hidden
            accept={acceptFileTypes()}
          />
          Cambiar foto
        </LoadingButton>
      </div>
      <Formik
        initialValues={userInfo}
        validationSchema={validationSchemaProfile}
        enableReinitialize={true}
        onSubmit={(values) => {
          saveData(formatData(values));
        }}>
        {({ values, dirty, handleChange, setFieldValue, errors }) => {
          return (
            <Form className={classes.tableContainer}>
              <Box className={classes.boxContainer}>
                <Typography className={classes.header}>
                  Información básica
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Input
                      id="fullName"
                      value={values.fullName}
                      label="Nombre"
                      variant="outlined"
                      name="fullName"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      value={values.nationality}
                      label="Nacionalidad"
                      variant="outlined"
                      helperText={errors.nationality}
                      id="nationality"
                      name="nationality"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      value={values.document}
                      label="Documento"
                      id="document"
                      variant="outlined"
                      name="document"
                      helperText={errors.document}
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      id="phoneNumber"
                      value={values.phoneNumber}
                      label="Número de teléfono"
                      name="phoneNumber"
                      helperText={errors.phoneNumber}
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Input
                      id="email"
                      value={values.personalEmail}
                      label="Email"
                      variant="outlined"
                      name="personalEmail"
                      helperText={errors.personalEmail}
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        label="Fecha de nacimiento"
                        value={values.dateOfBirth}
                        helperText={errors.dateOfBirth}
                        inputFormat="DD/MM/yyyy"
                        onChange={(newValue) => {
                          setFieldValue('dateOfBirth', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Prefieres un pronombre en especial"
                        labelPlacement="end"
                        margin="normal"
                        name="genderPronounsOption"
                        value={values.genderPronounsOption}
                        checked={values.genderPronounsOption}
                        onClick={(event) => {
                          setFieldValue(
                            'genderPronounsOption',
                            event.target.checked,
                          );
                        }}
                      />
                      {values.genderPronounsOption && (
                        <Input
                          variant="outlined"
                          id="genderPronouns"
                          helperText={errors.genderPronouns}
                          value={values.genderPronouns}
                          label="Pronombre"
                          name="genderPronouns"
                          onChange={handleChange}
                          required
                          type={'text'}
                        />
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">Género</FormLabel>
                      <RadioGroup
                        name="gender"
                        value={values.gender.toString()}
                        onChange={(event) => {
                          setFieldValue('gender', event.currentTarget.value);
                        }}>
                        <FormControlLabel
                          value={GENDER_EN.MALE}
                          control={<Radio color="primary" />}
                          label={GENDER_ES.MALE}
                        />
                        <FormControlLabel
                          value={GENDER_EN.FEMALE}
                          control={<Radio color="primary" />}
                          label={GENDER_ES.FEMALE}
                        />
                        <FormControlLabel
                          value={GENDER_EN.OTHER}
                          control={<Radio color="primary" />}
                          label={GENDER_ES.OTHER}
                          onClick={() => setOtherGender('')}
                        />
                        {values.gender === GENDER_EN.OTHER && (
                          <Input
                            variant="outlined"
                            id="otherGender"
                            value={values.otherGender}
                            name="otherGender"
                            label="Especifique"
                            onChange={handleChange}
                            required
                            type={'text'}
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.boxContainer}>
                <Typography className={classes.header}>Direcciones</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="country-select-demo"
                      fullWidth
                      variant="outlined"
                      options={countries}
                      onChange={(event, value) => {
                        setFieldValue('countryOfResidence', value);
                      }}
                      helperText={errors.countryOfResidence}
                      name="countryOfResidence"
                      value={values.countryOfResidence}
                      isOptionEqualToValue={(option, value) => {
                        if (value === '') {
                          value = 'Uruguay';
                        }
                        if (typeof value !== 'string') {
                          value = value.label;
                        }
                        return option.label === value;
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.label;
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          helperText={errors.countryOfResidence}
                          required
                          margin="normal"
                          label="País de residencia"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      helperText={errors.city}
                      id="city"
                      value={values.city}
                      name="city"
                      onChange={handleChange}
                      required
                      type={'text'}
                      label="Ciudad de residencia"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      id="addres"
                      helperText={errors.addres}
                      value={values.addres}
                      name="addres"
                      onChange={handleChange}
                      required
                      type={'text'}
                      label="Dirección"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.boxContainer}>
                <Typography className={classes.header}>
                  Datos bancarios
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Input
                      value={values.bankName}
                      label="Nombre del banco"
                      name="bankName"
                      helperText={errors.bankName}
                      onChange={handleChange}
                      required
                      type={'text'}
                      variant="outlined"
                      id="bankName"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      id="bankAccountNumber"
                      helperText={errors.bankAccountNumber}
                      variant="outlined"
                      value={values.bankAccountNumber}
                      label="Número de cuenta"
                      name="bankAccountNumber"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      value={values.bankAccountType}
                      label="Tipo de cuenta bancaria"
                      id="bankAccountType"
                      helperText={errors.bankAccountType}
                      name="bankAccountType"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.boxContainer}>
                <Typography className={classes.header}>Otros datos</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      value={values.heathcare}
                      label="Sociedad medica"
                      name="heathcare"
                      id="heathcare"
                      helperText={errors.heathcare}
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      value={values.emergencyContactName}
                      label="Nombre del contacto de emergencia"
                      name="emergencyContactName"
                      helperText={errors.emergencyContactName}
                      id="emergencyContactName"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      value={values.emergencyContactPhone}
                      label="Número del contacto de emergencia"
                      name="emergencyContactPhone"
                      id="emergencyContactPhone"
                      helperText={errors.emergencyContactPhone}
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      value={values.emergencyContactEmail}
                      label="Email del contacto de emergencia"
                      name="emergencyContactEmail"
                      helperText={errors.emergencyContactEmail}
                      id="emergencyContactEmail"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="civilStatus"
                      select
                      fullWidth
                      value={values.civilStatus}
                      label="Estado civil"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}>
                      <MenuItem value={CIVIL_STATUS.SINGLE}>Soltero</MenuItem>
                      <MenuItem value={CIVIL_STATUS.MARRIED}>Casado</MenuItem>
                      <MenuItem value={CIVIL_STATUS.DIVORCED}>
                        Divorciado
                      </MenuItem>
                      <MenuItem value={CIVIL_STATUS.WIDOWED}>Viudo</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      value={values.academicLevel}
                      label="Nivel académico"
                      name="academicLevel"
                      helperText={errors.academicLevel}
                      id="academicLevel"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      value={values.career}
                      label="Carrera"
                      name="career"
                      helperText={errors.career}
                      id="career"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="demo-multiple-checkbox-label">
                        Idiomas
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        name="languages"
                        id="languages"
                        multiple
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={values.languages}
                        onChange={handleChange}
                        input={<OutlinedInput label="Lenguajes" />}
                        renderValue={(selected) => selected.join(', ')}>
                        {languages.map((language) => (
                          <MenuItem key={language} value={language}>
                            <Checkbox
                              checked={values.languages.indexOf(language) > -1}
                            />
                            <ListItemText primary={language} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      variant="outlined"
                      value={values.specialConditions}
                      label="Condiciones especiales"
                      name="specialConditions"
                      helperText={errors.specialConditions}
                      id="specialConditions"
                      onChange={handleChange}
                      required
                      type={'text'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="shirtSize"
                      id="shirtSize"
                      select
                      helperText={errors.shirtSize}
                      fullWidth
                      value={values.shirtSize}
                      label="Talla de remera"
                      variant="outlined"
                      margin="normal"
                      onChange={handleChange}>
                      <MenuItem value={SHIRT_SIZE.SMALL}>
                        {SHIRT_SIZE.SMALL}
                      </MenuItem>
                      <MenuItem value={SHIRT_SIZE.MEDIUM}>
                        {SHIRT_SIZE.MEDIUM}
                      </MenuItem>
                      <MenuItem value={SHIRT_SIZE.LARGE}>
                        {SHIRT_SIZE.LARGE}
                      </MenuItem>
                      <MenuItem value={SHIRT_SIZE.EXTRA_LARGE}>
                        {SHIRT_SIZE.EXTRA_LARGE}
                      </MenuItem>
                      <MenuItem value={SHIRT_SIZE.XX_LARGE}>
                        {SHIRT_SIZE.XX_LARGE}
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Hijos?"
                        labelPlacement="end"
                        name="kids"
                        value={values.kids}
                        checked={!!values.kids}
                        onClick={(event) => {
                          setFieldValue('kids', event.target.checked);
                        }}
                      />
                      {values.kids && (
                        <FieldArray
                          name="kidsNames"
                          render={(arrayHelpers) => (
                            <div>
                              {values.kidsNames.map((kidName, index) => (
                                <div
                                  key={index}
                                  className={classes.kidsContainer}>
                                  <TextField
                                    variant="outlined"
                                    value={kidName}
                                    label="Nombre"
                                    name={`kidsNames[${index}]`}
                                    id={`kidsNames[${index}]`}
                                    onChange={handleChange}
                                    required
                                    className={classes.kidsNameContainer}
                                  />
                                  <Button
                                    variant="text"
                                    type="button"
                                    className={classes.buttonStyle}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    X
                                  </Button>
                                </div>
                              ))}
                              <Button
                                className={classes.buttonKidsStyle}
                                variant="contained"
                                type="button"
                                onClick={() => arrayHelpers.push('')}>
                                Agregar nombre
                              </Button>
                            </div>
                          )}
                        />
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
              <br />
              <Box className={classes.buttonContainer}>
                <LoadingButton
                  className={classes.buttonSaveStyle}
                  disabled={!dirty}
                  type="submit"
                  color="primary"
                  loading={isLoading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained">
                  Guardar
                </LoadingButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Profile;
