import hrApi from './hrApi';

//User day off data example
// {
//   "spaceDays": 0,
//   "studyDays": 0,
//   "normalDays": 0,
//   "userId": id,
// }

const userDayOffApi = {
  editBalance: (id, normalDays, studyDays, spaceDays) =>
    hrApi.patch('user-day-off/editBalance', {
      id: id,
      normalDays: normalDays,
      studyDays: studyDays,
      spaceDays: spaceDays,
    }),

  getAll: () => hrApi.get('user-day-off', {}),

  create: (data) => hrApi.post('user-day-off', data),

  edit: (id, data) => hrApi.patch(`user-day-off/${id}`, data),

  delete: (id) => hrApi.delete(`user-day-off/${id}`, {}),

  get: (id) => hrApi.get(`user-day-off/${id}`, {}),

  getByUser: (userId) => hrApi.get(`user-day-off/user/${userId}`, {}),
};

export default userDayOffApi;
