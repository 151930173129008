import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import EventIcon from '@mui/icons-material/Event';
import {
  Button,
  Grid,
  Box,
  TextField,
  Paper,
  DialogTitle,
  Typography,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { STATUS } from '../../../../api/apiConstants';
import { TRANSLATED_REQUEST_TYPE } from '../../../../constants';
import {
  errorHandling,
  formatDate,
  getFileName,
} from '../../../../api/apiHelpers';
import RejectedRequestButton from '../RejectedRequest/RejectedRequestButton';
import getFile from '../../../../api/getFile';
import useStyles from './style';

const propTypes = {
  handleClose: PropTypes.func,
  handleRejectPressed: PropTypes.func,
  updateRequestStatus: PropTypes.func,
  requestInfo: PropTypes.obj,
};

const defaultPropTypes = {
  handleClose: null,
  handleRejectPressed: null,
  updateRequestStatus: null,
  requestInfo: '',
};

const ViewRequestModal = ({
  handleClose,
  handleRejectPressed,
  updateRequestStatus,
  requestInfo,
}) => {
  const classes = useStyles();
  const [fileName, setFileName] = useState('');
  const [url, setUrl] = useState('');

  const initialValues = {
    name: requestInfo.user.name,
    startDate: formatDate(requestInfo.startDate),
    finishDate: formatDate(requestInfo.finishDate),
    type: TRANSLATED_REQUEST_TYPE[requestInfo.type],
    description: requestInfo.comment,
  };

  const getAttachedFile = async (id) => {
    try {
      const {
        data: { data },
      } = await getFile(id);
      const link = data.link;
      const fileName = data.fileName;
      setFileName(getFileName(fileName));
      setUrl(link);
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  useEffect(() => {
    if (requestInfo.fileId !== '') {
      getAttachedFile(requestInfo?.fileId);
    }
  }, []);

  return (
    <Paper className={classes.boxContainer}>
      <DialogTitle id="id">
        <Box className={classes.titleContainer}>
          <Typography variant="h5" className={classes.text}>
            Licencia
          </Typography>
          <Box>
            <IconButton
              className={classes.closeButtonStyle}
              onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <Divider variant="middle" className={classes.divider} />

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          updateRequestStatus(requestInfo, values.comment, STATUS.REJECTED);
          handleClose();
        }}>
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  aria-readonly
                  name="name"
                  value={values.name}
                  label="Nombre de Empleado/a"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  className={classes.input}>
                  Donación de sangre
                </TextField>
              </Grid>

              <Grid item xs={12} className={classes.containerDates}>
                <Grid item xs={12}>
                  <TextField
                    aria-readonly
                    name="startDate"
                    value={values.startDate}
                    label="Fecha de Inicio"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputDate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="calendar-icon" edge="end">
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    aria-readonly
                    name="finishDate"
                    value={values.finishDate}
                    label="Fecha de Finalización"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputDate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="calendar-icon" edge="end">
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  aria-readonly
                  name="type"
                  value={values.type}
                  label="Tipo de licencia"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  className={classes.input}>
                  Donación de sangre
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  aria-readonly
                  name="description"
                  variant="outlined"
                  fullWidth
                  label="Descripción"
                  className={classes.input}
                  multiline
                  maxRows={2}
                  value={values.description}
                  inputProps={{ maxLength: 250 }}
                  required
                />
              </Grid>
              {requestInfo.fileId ? (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.attachmentButton}
                    onClick={() =>
                      window.open(url, '_blank', 'noopener, noreferrer')
                    }>
                    {fileName}
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={12} className={classes.containerButtons}>
                <Grid item xs={12}>
                  <RejectedRequestButton
                    updateRequestStatus={updateRequestStatus}
                    requestInfo={requestInfo}
                    type="square"
                    onClick={handleRejectPressed}
                  />
                </Grid>

                {requestInfo.status === STATUS.APPROVED ? null : (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.approveButton}
                      onClick={() => {
                        updateRequestStatus(requestInfo, '', STATUS.APPROVED);
                        handleClose();
                      }}>
                      Aprobar
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
ViewRequestModal.propTypes = propTypes;
ViewRequestModal.defaultProps = defaultPropTypes;

export default ViewRequestModal;
