import * as Yup from 'yup';

const REQUIRED = 'Este campo es requerido';

const validationSchemaAddEvent = Yup.object().shape({
  eventName: Yup.string().required(REQUIRED),
  date: Yup.date().required(REQUIRED),
  hourFrom: Yup.date().required(REQUIRED),
  hourTo: Yup.date().required(REQUIRED),
  place: Yup.string().required(REQUIRED),
});

export default validationSchemaAddEvent;
