import * as Yup from 'yup';

const REQUIRED = 'Este campo es requerido';

const validationSchemaEditEvent = Yup.object().shape({
  name: Yup.string().required(REQUIRED),
  startDateAndTime: Yup.date().required(REQUIRED),
  endDateAndTime: Yup.date().required(REQUIRED),
  place: Yup.string().required(REQUIRED),
});

export default validationSchemaEditEvent;
