import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Popover from '@mui/material/Popover';
import DayPicker, { DateUtils } from 'react-day-picker';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';

import moment from 'moment';
import { errorHandling } from '../../../../api/apiHelpers';
import LineSkeleton from '../../../shared/LineSkeleton';
import { ReactComponent as RequestsLogo } from '../../../../assets/requestsLogo.svg';
import logRequestApi from '../../../../api/logRequestApi';
import { parseDateDdMmYyyy } from '../../../../Utils/parseDate';
import HistoryRequestsModal from '../HistoryRequestsModal/HistoryRequestsModal';
import useStyles from './style';

const HistoryRequestsTable = () => {
  const [allRequestsData, setAllRequestsData] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [requestsByIdData, setRequestsByIdData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const classes = useStyles();

  const [range, setRange] = React.useState({
    from: new Date(new Date().getFullYear(), 0, 1),
    to: new Date(new Date().getFullYear(), 11, 31),
  });

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenDatePicker = (event) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseDatePicker = () => {
    setAnchorEl(null);
  };

  const openDatePicker = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOpen = (userId) => {
    getRequestsById(userId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllRequestsHandler = async () => {
    try {
      const dates = {
        startDate: range.from,
        finishDate: range.to,
      };
      setIsLoadingRequest(true);
      const {
        data: { data },
      } = await logRequestApi.getRequestHistory(dates);
      if (data) {
        const activeUsers = data.filter((user) => user.isActive);
        setAllRequestsData(activeUsers);
        setSearchedUsers(activeUsers);
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoadingRequest(false);
    }
  };

  useEffect(() => {
    getAllRequestsHandler();
  }, [range]);

  const getRequestsById = async (userId) => {
    setRequestsByIdData(
      allRequestsData?.filter((request) => request.userId === userId),
    );
  };

  const handleDayClick = (day) => {
    if (DateUtils.addDayToRange(day, range).from !== null) {
      setRange(DateUtils.addDayToRange(day, range));
    }
  };

  const searchUser = (users, userToSearch) => {
    if (userToSearch === '') {
      return setSearchedUsers(allRequestsData);
    }

    const filteredUsers = users.filter((user) => {
      if (user.name.toLowerCase().includes(userToSearch.toLowerCase())) {
        return true;
      }
      return false;
    });

    setSearchedUsers(filteredUsers);
  };

  const modifiers = { start: range.from, end: range.to };

  const requestsCouter = (user) => {
    let cont = 0;
    allRequestsData.forEach((req) => (req.user.id === user ? cont++ : cont));
    return cont;
  };

  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div className={classes.topTab}>
          <Grid container>
            <Grid item xs={6}>
              <div className={classes.requestsTextContainer}>
                <RequestsLogo className={classes.logo} />
                <div className={classes.requestsText}>
                  <Typography className={classes.title}>
                    Balance de solicitudes
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.inputContainer}>
              <div className={classes.tableHeadContainer}>
                <div className={classes.inputContainer}>
                  <Input
                    className={classes.input}
                    disableUnderline={true}
                    placeholder="Buscar empleado"
                    onChange={(event) => {
                      searchUser(allRequestsData, event.target.value);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '7E62CC' }} />
                      </InputAdornment>
                    }
                  />
                </div>
                <Button
                  aria-describedby={id}
                  onClick={handleOpenDatePicker}
                  variant="text"
                  className={classes.dateButtonContainer}>
                  {`${parseDateDdMmYyyy(range.from)} - ${parseDateDdMmYyyy(
                    range.to,
                  )}`}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.headCells}>
              <TableCell className={classes.headCellsLeft}>
                <Typography>Nombre</Typography>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <Typography>Dias de licencia</Typography>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <Typography>Dias de enfermedad</Typography>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <Typography>Otros</Typography>
              </TableCell>
              <TableCell className={classes.headCellsRight}>
                <Typography>Acción</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingRequest ? (
              searchedUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover key={row.userId} className={classes.tableRow}>
                    <TableCell component="th" scope="row">
                      <div className={classes.textRowLeft}>{row.name}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className={classes.textRowMid}>{row.normal}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className={classes.textRowMid}>{row.sick}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className={classes.textRowMid}>{row.other}</div>
                    </TableCell>
                    <TableCell className={classes.textRowRight}>
                      <Button
                        onClick={() => handleOpen(row.userId)}
                        variant="text"
                        className={classes.buttonContainer}>
                        <VisibilityIcon className={classes.viewButton} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <LineSkeleton lineCont={rowsPerPage} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        labelRowsPerPage=""
        component="div"
        count={allRequestsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal open={open} onClose={handleClose}>
        <HistoryRequestsModal
          handleClose={handleClose}
          requestsByIdData={requestsByIdData}
        />
      </Modal>
      <Popover
        id={id}
        open={openDatePicker}
        anchorEl={anchorEl}
        onClose={handleCloseDatePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Paper>
          <DayPicker
            className="Selectable"
            numberOfMonths={2}
            selectedDays={[range.from, range]}
            modifiers={modifiers}
            onDayClick={handleDayClick}
          />
        </Paper>
      </Popover>
    </div>
  );
};
export default HistoryRequestsTable;
