import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  areIntervalsOverlapping,
  endOfWeek,
  isAfter,
  isBefore,
  parseISO,
} from 'date-fns';
import { Avatar } from '@mui/material';
import getFile from '../../api/getFile';
import userInfoApi from '../../api/userInfoApi';
import { PublicError } from '../../classes/PublicError';
import { errorHandling, getUserRole } from '../../api/apiHelpers';
import { TRANSLATED_REQUEST_TYPE } from '../../constants';
import { PRIVILEGE, REQUEST_STATUS } from '../../api/apiConstants';
import useStyles from './calendarStyle';

const propTypes = {
  startDateField: PropTypes.string,
  endDateField: PropTypes.string,
  events: PropTypes.array,
  user: PropTypes.object,
  position: PropTypes.number,
  viewingDate: PropTypes.instanceOf(Date),
  birthday: PropTypes.instanceOf(Date),
};

const Row = ({
  events,
  user,
  position,
  viewingDate,
  startDateField,
  endDateField,
  birthday,
}) => {
  const classes = useStyles();
  const [profilePicture, setProfilePicture] = React.useState(null);

  const computedEvents = useMemo(() => {
    return events
      .filter((event) => {
        return event.status === REQUEST_STATUS.APPROVED;
      })
      .filter((event) => {
        return areIntervalsOverlapping(
          { start: viewingDate, end: endOfWeek(viewingDate) },
          {
            start: new Date(parseISO(event[startDateField])),
            end: new Date(parseISO(event[endDateField])),
          },
        );
      })
      .map((e) => {
        let startColumn = new Date(e[startDateField]).getDay() + 2;
        let endColumn = new Date(e[endDateField]).getDay() + 3;
        if (isAfter(viewingDate, new Date(e[startDateField]))) {
          startColumn = 2;
        }
        if (isAfter(new Date(e[endDateField]), endOfWeek(viewingDate))) {
          endColumn = 9;
        }
        return {
          startColumn,
          endColumn,
          ...e,
        };
      });
  }, [endDateField, startDateField, events, viewingDate]);

  const computedBirthday = useMemo(() => {
    if (!birthday) {
      return;
    }

    const birthdayAdjust = new Date(birthday).setFullYear(
      new Date(viewingDate).getFullYear(),
    );

    const startColumn = new Date(birthdayAdjust).getDay() + 2;
    const endColumn = new Date(birthdayAdjust).getDay() + 3;

    if (isAfter(viewingDate, new Date(birthdayAdjust))) {
      return null;
    }

    if (isBefore(endOfWeek(viewingDate), new Date(birthdayAdjust))) {
      return null;
    }

    return {
      startColumn,
      endColumn,
      birthdayAdjust,
    };
  }, [birthday, viewingDate]);

  const isToday = useMemo(() => {
    const todayDate = new Date().setFullYear(
      new Date(viewingDate).getFullYear(),
    );

    const startColumn = new Date(todayDate).getDay() + 2;
    const endColumn = new Date(todayDate).getDay() + 3;

    if (isAfter(viewingDate, new Date(todayDate))) {
      return null;
    }
    if (isBefore(endOfWeek(viewingDate), new Date(todayDate))) {
      return null;
    }

    return {
      startColumn,
      endColumn,
    };
  }, [viewingDate]);

  const getImg = async () => {
    try {
      if (user.userId) {
        const {
          data: { data },
        } = await userInfoApi.getByUser(user.userId);

        if (!data) {
          throw new PublicError('No se pueden obtener los datos de usuario');
        }
        if (data.photo !== null && data.photo !== '') {
          const response = await getFile(data.photo);

          const file = response.data.data;

          const link = file.link;

          setProfilePicture(link);
        }
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  React.useEffect(() => {
    getImg();
  }, []);

  return (
    <>
      {/* User´s name */}

      <div
        className={classes.gridCell}
        style={{ gridColumn: 1, gridRow: position }}>
        <Avatar
          src={profilePicture ?? '/broken-image.jpg'}
          sx={{ width: 24, height: 24 }}
        />
        <p className={classes.nameText}>{user.name}</p>
      </div>

      {/* User´s licenses */}

      {isToday ? (
        <div
          key={isToday}
          className={`${classes.gridToday}`}
          style={{
            gridColumn: `${isToday.startColumn} / span 1`,
            gridRow: position,
          }}></div>
      ) : null}

      {computedEvents?.map((event) => {
        console.log(event);
        return (
          <div
            key={event.id}
            className={`${classes.gridEvent} ${classes.eventText} ${classes.license}`}
            onClick={() => console.log(event)}
            style={{
              gridColumn: `${event.startColumn} / ${event.endColumn}`,
              gridRow: position,
            }}>
            {event.name
              ? event.name
              : getUserRole() === PRIVILEGE.USER
              ? 'Fuera de la oficina'
              : TRANSLATED_REQUEST_TYPE[event.type]}
          </div>
        );
      })}

      {/* User´s birthday */}

      {computedBirthday ? (
        <div
          key={computedBirthday.birthday}
          className={`${classes.gridEvent} ${classes.birthday}`}
          style={{
            gridColumn: `${computedBirthday.startColumn} / span 1`,
            gridRow: position,
          }}>
          <p className={classes.birthdayText}>Cumpleaños</p>
        </div>
      ) : null}
    </>
  );
};

Row.propTypes = propTypes;

export default Row;
