import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ReactComponent as OutOfficeLogo } from '../../../../assets/OutOfficeLogo.svg';
import { errorHandling, translateType } from '../../../../api/apiHelpers';
import LineSkeleton from '../../../shared/LineSkeleton';
import logRequestApi from '../../../../api/logRequestApi';
import useStyles from './style';

const OutOfOffice = () => {
  const classes = useStyles();
  const [allRequestsData, setAllRequestsData] = useState([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Se cambiara una vez que se cambie la logica de backend
  const getAllRequestsHandler = async () => {
    try {
      setIsLoadingRequest(true);
      const {
        data: { data },
      } = await logRequestApi.getAll();
      setAllRequestsData(data);
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoadingRequest(false);
    }
  };

  useEffect(() => {
    getAllRequestsHandler();
  }, []);

  const userFilter = useMemo(
    () =>
      allRequestsData.filter(
        (row) =>
          new Date(row.startDate).setHours(0, 0, 0, 0) <=
            new Date().setHours(0, 0, 0, 0) &&
          new Date().setHours(0, 0, 0, 0) <=
            new Date(row.finishDate).setHours(0, 0, 0, 0),
      ),

    [allRequestsData],
  );

  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <span className={classes.headTextContainer}>
                  <OutOfficeLogo className={classes.logo} />
                  <div className={classes.commonText}>Fuera de oficina hoy</div>
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingRequest ? (
              userFilter.map((row) =>
                row.user.active ? (
                  <TableRow key={row.id}>
                    <TableCell>{row.user.name}</TableCell>
                  </TableRow>
                ) : null,
              )
            ) : (
              <LineSkeleton lineCont={allRequestsData.length} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OutOfOffice;
