const REQUEST_TYPE = {
  NORMAL: 'normal',
  SICK: 'sick',
  STUDY: 'study',
  SPACE: 'space',
  MATERNITY: 'maternity',
  PATERNITY: 'paternity',
  MOURNING: 'mourning',
  MARRIAGE: 'marriage',
  BLOOD_DONATION: 'blood donation',
  NOT_PAID: 'not paid',
  PAP: 'PAP',
};

export default REQUEST_TYPE;
