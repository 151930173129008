import makeApiCall from '../app/makeApiCall';
import hrApi from './hrApi';
import { AUTH_TYPE } from './apiConstants';

//User data example
// {
//   "name": "string",
//   "email": "string",
//   "password": "string",
//   "verified": true,
//   "active": true,
//   "dependent": true,
//   "privilege": "user",
//   "authType": "email"
// }

export const getUser = makeApiCall((data) =>
  hrApi.get(`user/${data.id}`, data),
);

const userApi = {
  login: (data) =>
    hrApi.post('user/signin', {
      email: data.email,
      password: data.password,
      authType: AUTH_TYPE.EMAIL,
    }),

  create: async (data) => {
    const { name, email, position, admissionDate, workload, privilege } = data;
    return await hrApi.post('user', {
      name,
      email,
      position,
      admissionDate,
      workload,
      privilege,
      authType: AUTH_TYPE.EMAIL,
    });
  },

  get: (id) => hrApi.get(`user/${id}`, {}),

  getUserNameById: (id) => hrApi.get(`user/user-name/${id}`, {}),

  editUserNameById: (id, newName) =>
    hrApi.patch(`user/user-name/${id}`, newName),

  getAll: () => hrApi.get(`user/`, {}),

  getBalances: () => hrApi.get(`user/getBalances`, {}),

  getAllUsersInfo: () => hrApi.get(`user/all-users-info'`, {}),

  postNewPassword: (password, recoveryToken) =>
    hrApi.post(`user/set-new-password/${recoveryToken}`, { password }),

  postRecoverPassword: (password, recoveryToken) =>
    hrApi.post(`user/recover-password/${recoveryToken}`, { password }),

  postSendMailToRecoverPassword: (email, passwordType) =>
    hrApi.post(`user/send-recover-mail`, { email, newPassword: passwordType }),

  postPasswordReset: () => hrApi.post('user/send-password-reset', {}),

  getBirthday: (id) => hrApi.get(`user/birthday`, {}),

  isActive: (data) =>
    hrApi.patch(`user/change-user-status`, {
      active: data.active,
      email: data.email,
      password: data.password,
    }),

  edit: (data, id) => hrApi.patch(`user/${id}`, data),

  disableUser: (data) => hrApi.patch(`user/change-user-status`, data),
};

export default userApi;
