import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tabActive: {
    color: '#7e62cc',
  },
  tab: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '14px',
    padding: '0 50px',
  },
  menuNav: {
    width: 'fit-content',
    backgroundColor: 'white',
    alignItems: 'flex-start',
    borderRadius: '8px  8px 0 0',
  },
  menuNavContainer: {
    padding: '1rem',
  },

  bottomContainer: {
    padding: '1rem',
  },
}));

export default useStyles;
