import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    tableContainer: {
      margin: 'auto ',
      padding: '0',
      maxWidth: '1500px',
      borderRadius: '8px',
    },
    tableHead: {
      backgroundColor: 'rgba(8, 47, 63, 0.05)',
    },
    topTab: {
      flexDirection: 'row',
      display: 'flex',
      paddingLeft: '2%',
      paddingRight: '5%',
      borderRadius: '50px 50px 0px 0px',
    },
    actionCell: {
      flexDirection: 'row',
    },
    headCellsMid: {
      fontFamily: 'Montserrat',
      fontWeight: '400',
      fontSize: '12px',
      textAlign: 'center',
    },
    headCellsLeft: {
      fontFamily: 'Montserrat',
      fontWeight: '400',
      fontSize: '12px',
      textAlign: 'left',
    },
    headCellsRight: {
      fontFamily: 'Montserrat',
      fontWeight: '400',
      fontSize: '12px',
      textAlign: 'right',
      paddingRight: '2%',
    },
    requestsText: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      paddingLeft: '1rem',
      fontFamily: 'Montserrat',
      fontWeight: '700',
      fontSize: '18px',
    },
    requestsTextContainer: {
      justifyContent: 'space-around',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      height: '64px',
    },
    textRowLeft: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '400',
    },
    textRowMid: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
    },
    textRowRight: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '400',
      display: 'flex',
      justifyContent: 'right',
    },
    requestCell: {
      textAlign: 'center',
    },
    textRowMidNormal: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      alignContent: 'center',
      margin: 'auto',
      color: '#4AB533',
      backgroundColor: '#E9F6E7',
      borderRadius: '8px',
    },
    textRowMidSick: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#CC1F3B',
      backgroundColor: '#FEEBF0',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidStudy: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#4A77E0',
      backgroundColor: '#E5F3FF',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidSpace: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#7E62CC',
      backgroundColor: '#EDE9F7',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidMaternity: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#de5fa5',
      backgroundColor: '#e3d3dc',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidPaternity: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#3a74ba',
      backgroundColor: '#bbd3f0',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidMourning: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#633b23',
      backgroundColor: '#e8d8cf',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidMerriage: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#756a05',
      backgroundColor: '#edeacc',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidBlood: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#a61f26',
      backgroundColor: '#e8bec0',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidNoPaid: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#E57825',
      backgroundColor: '#FDF4E3',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    textRowMidPap: {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      color: '#04825a',
      backgroundColor: '#c7ebdf',
      borderRadius: '8px',
      alignContent: 'center',
      margin: 'auto',
    },
    logo: {
      width: '36px',
      height: '36px',
    },
    aproveButton: {
      borderRadius: '8',
      color: theme.palette.success.main,
    },
    aproveButtonAction: {
      color: theme.palette.success.main,
      cursor: 'pointer',
    },
  };
});

export default useStyles;
