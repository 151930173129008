import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';

import { errorHandling } from '../../../../api/apiHelpers';
import LineSkeleton from '../../../shared/LineSkeleton';
import { ReactComponent as UsersLogo } from '../../../../assets/usersLogo.svg';
import { HTTP_STATUS } from '../../../../api/apiConstants';
import userApi from '../../../../api/userApi';
import { parseDateDdMmYyyy } from '../../../../Utils/parseDate';
import EmployeeDetailsModal from '../EmployeeDetails/EmployeeDetailsModal';
import EmployeeDetailsEditModal from '../EmployeeDetailsEdit/EmployeeDetailsEditModal';
import AddEmployeeModal from '../AddEmployee/AddEmployeeModal';
import userDayOffApi from '../../../../api/userDayOffApi';
import UserNegativeDaysModal from '../../../Setting/Utils/UserNegativeDaysModal/UserNegativeDaysModal';
import { PublicError } from '../../../../classes/PublicError';
import useStyles from './style';

const EmployeesTable = () => {
  const classes = useStyles();
  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [userDaysBalance, setUserDaysBalance] = useState(null);
  const [openNegativeDaysModal, setOpenNegativeDaysModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isActiveFirst = (a, b) => {
    return b.active - a.active;
  };

  const handleCloseNegativeDaysModal = () => {
    setOpenNegativeDaysModal(false);
  };

  const getAllUsersHandler = async () => {
    try {
      setIsLoadingUsers(true);
      const {
        data: { data },
      } = await userApi.getAll();

      setUsersData(data.sort(isActiveFirst));
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const disableUser = async (user, isInModal) => {
    try {
      const date = {
        id: user.id,
        active: false,
      };

      const {
        data: { data },
      } = await userDayOffApi.getByUser(user.id);

      if (!data) {
        throw new PublicError(
          'No se puede obtener el balance de días del usuario',
        );
      }

      if (data.normalDays < 0) {
        if (isInModal) {
          const resp = await userApi.disableUser(date);

          if (resp?.status === HTTP_STATUS.OK) {
            toast.success('Usuario desactivado correctamente');

            setOpenNegativeDaysModal(false);

            await getAllUsersHandler();
          }

          return resp;
        }

        setUserDaysBalance(data);
        setOpenNegativeDaysModal(true);
      } else {
        const resp = await userApi.disableUser(date);

        if (resp?.status === HTTP_STATUS.OK) {
          toast.success('Usuario desactivado correctamente');

          setOpenNegativeDaysModal(false);

          await getAllUsersHandler();
        }

        return resp;
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  useEffect(() => {
    getAllUsersHandler();
  }, []);

  const slideUsers = useMemo(
    () => usersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, usersData],
  );

  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div className={classes.topTab}>
          <div className={classes.employeesTextContainer}>
            <UsersLogo className={classes.logo} />
            <div className={classes.employeesText}>Empleados</div>
          </div>
          <div className={classes.addEmployeesButton}>
            <AddEmployeeModal getAllUsersHandler={getAllUsersHandler} />
          </div>
        </div>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.headCellsLeft}>
                <span>Nombre</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Fecha de admisión</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Posición</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Status</span>
              </TableCell>
              <TableCell className={classes.headCellsRight}>
                <span>Acción</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingUsers ? (
              slideUsers.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}>
                  <TableCell component="th" scope="row">
                    <div className={classes.textRowLeft}>{row.name}</div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.textRowMid}>
                      {parseDateDdMmYyyy(row.admissionDate)}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.textRowMid}>{row.position}</div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.textRowMid}>
                      {row.active ? (
                        <Typography>Activo</Typography>
                      ) : (
                        <Typography>Inactivo</Typography>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.textRowRight}>
                    <EmployeeDetailsModal userActive={row} />
                    <EmployeeDetailsEditModal
                      userActive={row}
                      disableUser={disableUser}
                      getAllUsersHandler={getAllUsersHandler}
                      isActive={row.active}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <LineSkeleton lineCont={rowsPerPage} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        labelRowsPerPage=""
        component="div"
        count={usersData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={openNegativeDaysModal}
        onClose={handleCloseNegativeDaysModal}>
        <UserNegativeDaysModal
          userDaysBalance={userDaysBalance}
          disableUser={disableUser}
          handleClose={handleCloseNegativeDaysModal}
        />
      </Modal>
    </div>
  );
};
export default EmployeesTable;
