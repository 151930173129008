import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { STATUS } from '../../../../api/apiConstants';
import RejectedRequestForm from './RejectedRequestForm';
import useStyles from './style';

const RejectedRequestButton = ({
  updateRequestStatus,
  requestInfo,
  type,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    onClick();
  };
  const handleClose = () => setOpen(false);
  const crossButton = 'cross';
  const classes = useStyles();

  return (
    <>
      {type === crossButton ? (
        <>
          <Button
            variant="text"
            onClick={handleOpen}
            aria-label="close-modal"
            className={classes.rejectButton}>
            <CloseIcon className={classes.rejectButtonAction} />
          </Button>
          <Modal open={open} onClose={handleClose}>
            <RejectedRequestForm
              requestInfo={requestInfo}
              handleClose={handleClose}
              updateRequestStatus={updateRequestStatus}
            />
          </Modal>
        </>
      ) : (
        <>
          {requestInfo.status === STATUS.APPROVED ? (
            <Button
              variant="outlined"
              aria-label="reject-request"
              onClick={handleOpen}
              color="error"
              className={classes.rejectBtnApp}>
              Rechazar
            </Button>
          ) : (
            <Button
              variant="outlined"
              aria-label="reject-request"
              onClick={handleOpen}
              color="error"
              className={classes.rejectBtnPend}>
              Rechazar
            </Button>
          )}
        </>
      )}
    </>
  );
};

RejectedRequestButton.propTypes = {
  updateRequestStatus: PropTypes.func.isRequired,
  requestInfo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RejectedRequestButton;
