import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import SpaceLogoGroup from '../../../../assets/spaceLogoGroup.svg';
import Input from '../../../shared/Input';
import { errorHandling } from '../../../../api/apiHelpers';
import { HTTP_STATUS, PATHS } from '../../../../api/apiConstants';
import userApi from '../../../../api/userApi';
import validationSchemaPassword from '../Utils/validationSchemaPassword';
import useStyles from './style';

const NewPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const params = useParams();
  const { token } = params;

  const setNewPassword = async (values) => {
    try {
      const resp = await userApi.postRecoverPassword(values.password, token);

      if (resp?.status === HTTP_STATUS.OK) {
        navigate(PATHS.DASHBOARD);
        toast.success('Contraseña actualizada');
      }
    } catch (error) {
      if (error.response.status === HTTP_STATUS.CONFLICT) {
        toast.error('La contraseña no puede ser la misma que la actual');
      } else {
        errorHandling(error.response.status);
      }
    }
  };

  return (
    <>
      <div className={classes.topTab}>
        <img src={SpaceLogoGroup} alt="SpaceDev logo" />
      </div>
      <div className={classes.container}>
        <div className={classes.newPassContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Nueva contraseña</Typography>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaPassword}
            onSubmit={setNewPassword}
            validateOnBlur>
            {(formik) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <Input
                    id="password"
                    autoComplete="off"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    value={formik.values.password}
                    helperText={formik.errors.password}
                    onChange={formik.handleChange}
                    label="Nueva contraseña"
                    type="password"
                    variant="outlined"
                  />
                  <Input
                    id="passwordConfirm"
                    autoComplete="off"
                    margin="normal"
                    required
                    fullWidth
                    name="passwordConfirm"
                    value={formik.values.passwordConfirm}
                    helperText={formik.errors.passwordConfirm}
                    onChange={formik.handleChange}
                    label="Confirmar contraseña"
                    type="password"
                    variant="outlined"
                  />
                  <Button
                    className={classes.button}
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth>
                    Guardar y entrar
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default NewPassword;
