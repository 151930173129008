import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  modalContainer: {
    borderRadius: '18px',
    width: '30%',
    position: 'absolute',
    top: '50%',
    maxHeight: '90%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '2rem',
  },
  title: {
    fontWeight: '700',
    fontSize: '24px',
    marginLeft: '1rem',
  },
  inputItem: {
    paddingBottom: '0.8rem',
  },
  hourItem: {
    paddingBottom: '0.8rem',
  },
  hourContainer: {
    paddingBottom: '0.8rem',
  },
  dateItem: {
    paddingBottom: '1.3rem',
  },
}));

export default useStyles;
