import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#7E62CC' },
    border: { main: '#B5BBBD' },
    secondary: { main: '#ede9f7' },
    home: { main: '#FFFFFF' },
    iconColor: { main: '#5A5F61' },
    approvedRequest: { main: '#E9F6E7' },
    pendingRequest: { main: '#FDF4E3' },
    inactive: { main: '#B5BBBD' },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});

export default theme;
