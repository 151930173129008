import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Typography, Box, Grid, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { parseDateDdMmYyyy } from '../../../../Utils/parseDate';
import { getOverlappingRequests } from '../../../../api/apiHelpers';
import { STATUS } from '../../../../api/apiConstants';
import useStyles from './style';

const OverlappingRequestsModal = ({
  handleClose,
  updateRequestStatus,
  requestInfo,
  approvedRequestsData,
}) => {
  const classes = useStyles();

  const overlappingEvents = getOverlappingRequests(
    approvedRequestsData,
    requestInfo,
  );

  return (
    <Grid className="container">
      <Formik
        initialValues={{ comment: '' }}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateRequestStatus(
            requestInfo,
            values.comment,
            STATUS.APPROVED,
            true,
          );
          handleClose();
        }}>
        {() => {
          return (
            <Form>
              <Box className={classes.container}>
                <Grid className={classes.titleContainer}>
                  <Grid className={classes.title}>
                    La licencia seleccionada se superpone con las siguientes ya
                    aprobadas:
                  </Grid>
                  <IconButton
                    aria-label="close modal"
                    className={classes.closeButtonStyle}
                    onClick={() => handleClose()}>
                    <CloseIcon />
                  </IconButton>
                </Grid>

                <Grid className={classes.tableContainer}>
                  <TableContainer
                    className={classes.tableContainer}
                    component={Paper}>
                    <Table>
                      <TableHead className={classes.tableHead}>
                        <TableRow className={classes.headCells}>
                          <TableCell className={classes.headCellsLeft}>
                            <span>Nombre</span>
                          </TableCell>
                          <TableCell className={classes.headCellsMid}>
                            <span>Fecha de inicio</span>
                          </TableCell>
                          <TableCell className={classes.headCellsMid}>
                            <span>Fecha de finalización</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {overlappingEvents?.map((row) => (
                          <TableRow hover key={row.id}>
                            <TableCell>
                              <Grid className={classes.textRowLeft}>
                                {row.user.name}
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid className={classes.textRowMid}>
                                {parseDateDdMmYyyy(row.startDate)}
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid className={classes.textRowMid}>
                                {parseDateDdMmYyyy(row.finishDate)}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid className={classes.containerButtons}>
                  <Button
                    style={{
                      textTransform: 'none',
                      margin: 'auto',
                      display: 'grid',
                    }}
                    variant="outlined"
                    onClick={() => handleClose()}
                    color="error">
                    Cerrar
                  </Button>

                  <Button
                    style={{
                      textTransform: 'none',
                      margin: 'auto',
                      display: 'grid',
                    }}
                    variant="contained"
                    type="submit"
                    color="primary">
                    Aprobar
                  </Button>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};
OverlappingRequestsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  updateRequestStatus: PropTypes.func.isRequired,
  requestInfo: PropTypes.object.isRequired,
  approvedRequestsData: PropTypes.array.isRequired,
};

export default OverlappingRequestsModal;
