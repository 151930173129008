import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    borderRadius: '18px',
    width: '40%',
    position: 'absolute',
    top: '50%',
    maxHeight: '90%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '2rem',
  },
  title: { fontWeight: '700', fontSize: '18px' },
  userName: { fontWeight: '700', fontSize: '24px', margin: '0 0 1rem 0.5rem' },
  tableContainer: {
    margin: '0 auto',
    maxWidth: '1500px',
    maxHeight: '60%',
    padding: '0',
    borderRadius: '8px',
  },
  tableBodyContainer: {
    overflow: 'auto',
    maxHeight: '30rem',
  },
  requestsTextContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
  },
  topTab: {
    flexDirection: 'row',
    display: 'flex',
    paddingLeft: '2%',
    paddingRight: '5%',
    borderRadius: '50px 50px 0px 0px',
  },
  logo: {
    width: '36px',
    height: '36px',
  },
  requestsText: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: '1%',
    fontWeight: '700',
    fontSize: '18px',
  },
  tableHead: {
    backgroundColor: 'rgba(8, 47, 63, 0.05)',
  },
  headCellsMid: {
    color: '#082F3F',
    opacity: '0.5',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'center',
  },
  headCellsLeft: {
    color: '#082F3F',
    opacity: '0.5',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
  },
  headCellsRight: {
    color: '#082F3F',
    opacity: '0.5',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'right',
    paddingRight: '4%',
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 },
  },
  textRowLeft: {
    fontSize: '14px',
    fontWeight: '400',
  },
  textRowMidNormal: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#4AB533',
    backgroundColor: '#E9F6E7',
    borderRadius: '8px',
  },
  textRowMidSick: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#CC1F3B',
    backgroundColor: '#FEEBF0',
    borderRadius: '8px',
  },
  textRowMidStudy: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#4A77E0',
    backgroundColor: '#E5F3FF',
    borderRadius: '8px',
  },
  textRowMidSpace: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#7E62CC',
    backgroundColor: '#EDE9F7',
    borderRadius: '8px',
  },
  textRowMidMaternity: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#de5fa5',
    backgroundColor: '#e3d3dc',
    borderRadius: '8px',
  },
  textRowMidPaternity: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#3a74ba',
    backgroundColor: '#bbd3f0',
    borderRadius: '8px',
  },
  textRowMidMourning: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#633b23',
    backgroundColor: '#e8d8cf',
    borderRadius: '8px',
  },
  textRowMidMerriage: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#756a05',
    backgroundColor: '#edeacc',
    borderRadius: '8px',
  },
  textRowMidBlood: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#a61f26',
    backgroundColor: '#e8bec0',
    borderRadius: '8px',
  },
  textRowMidNoPaid: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#E57825',
    backgroundColor: '#FDF4E3',
    borderRadius: '8px',
  },
  textRowMidPap: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#04825a',
    backgroundColor: '#c7ebdf',
    borderRadius: '8px',
  },
  textRowMidApprove: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: 'green',
    backgroundColor: '#E9F6E7',
    borderRadius: '8px',
  },
  textRowMidRejected: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: 'red',
    backgroundColor: '#faebeb',
    borderRadius: '8px',
  },
  textRowRight: {
    textAlign: 'right',
    fontSize: '14px',
    fontWeight: '400',
    paddingRight: '3%',
  },
}));

export default useStyles;
