import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import { Typography, Box } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';

import Input from '@mui/material/Input';
import { Avatar } from '@mui/material';
import { errorHandling } from '../../../../api/apiHelpers';
import LineSkeleton from '../../../shared/LineSkeleton';
import { ReactComponent as UsersLogo } from '../../../../assets/usersLogo.svg';
import userApi from '../../../../api/userApi';
import EmployeeDetailsEditModal from '../EmployeeDetailsEdit/EmployeeDetailsEditModal';
import AddEmployeeModal from '../AddEmployee/AddEmployeeModal';
import getFile from '../../../../api/getFile';
import useStyles from './styles';
import BalanceModal from './BalanceModal';

const BalanceTable = () => {
  const classes = useStyles();
  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllUsersHandler = async () => {
    try {
      setIsLoadingUsers(true);
      const {
        data: { data },
      } = await userApi.getBalances();

      data.forEach(async (user) => (user.photo = await getImg(user)));

      setUsersData(data);
      setSearchedUsers(data);
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const getImg = async (user) => {
    try {
      if (user.photo !== null && user.photo !== '') {
        const response = await getFile(user.photo);

        const file = response.data.data;

        const link = file.link;

        return link;
      }
    } catch (error) {
      errorHandling(error.response.status);
    }

    return null;
  };

  const searchUser = (users, userToSearch) => {
    if (userToSearch === '') {
      return setSearchedUsers(usersData);
    }

    const filteredUsers = users.filter((user) => {
      if (user.name.toLowerCase().includes(userToSearch.toLowerCase())) {
        return true;
      }
      return false;
    });

    setSearchedUsers(filteredUsers);
  };

  useEffect(() => {
    getAllUsersHandler();
  }, []);

  const slideUsers = useMemo(
    () =>
      searchedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, searchedUsers],
  );

  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div className={classes.topTab}>
          <div className={classes.employeesTextContainer}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UsersLogo className={classes.logo} />
              <div className={classes.employeesText}>Balance</div>
            </Box>
            <Input
              className={classes.input}
              disableUnderline={true}
              onChange={(event) => {
                searchUser(usersData, event.target.value);
              }}
              placeholder="Buscar empleado.."
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '7E62CC' }} />
                </InputAdornment>
              }
            />
          </div>
        </div>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.headCellsLeft}>
                <span>Nombre</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Dias vacacionales</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>SpaceDays</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Dias de estudio</span>
              </TableCell>
              <TableCell className={classes.headCellsRight}>
                <span>Acción</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingUsers ? (
              slideUsers.map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell component="th" scope="row">
                      <div className={classes.textRowLeft}>
                        <Avatar
                          src={row.photo ?? '/broken-image.jpg'}
                          sx={{ width: 24, height: 24, marginRight: 2 }}
                        />
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.textRowMid}>{row.normalDays}</div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.textRowMid}>{row.spaceDays}</div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.textRowMid}>{row.studyDays}</div>
                    </TableCell>
                    <TableCell className={classes.textRowRight}>
                      <BalanceModal
                        userActive={row}
                        disableUser={false}
                        getAllUsersHandler={getAllUsersHandler}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <LineSkeleton lineCont={rowsPerPage} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        labelRowsPerPage=""
        component="div"
        count={usersData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default BalanceTable;
