import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';

import EmployeeDetails from './EmployeeDetails';
import useStyles from './style';

const propTypes = {
  userActive: PropTypes.array,
};

const defaultPropTypes = {
  userActive: [],
};
const EmployeeDetailsModal = ({ userActive }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        aria-label="Abrir modal de detalles"
        variant="text"
        className={classes.buttonContainer}
        onClick={handleOpen}>
        <VisibilityIcon className={classes.viewButton} />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <EmployeeDetails userActive={userActive} />
      </Modal>
    </div>
  );
};
EmployeeDetailsModal.propTypes = propTypes;
EmployeeDetailsModal.defaultProps = defaultPropTypes;
export default EmployeeDetailsModal;
