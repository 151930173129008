import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Link,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HTTP_STATUS, PATHS } from '../../api/apiConstants';
import userApi from '../../api/userApi';
import { errorHandling } from '../../api/apiHelpers';
import AuthContext from '../../api/auth-contex';
import Input from '../shared/Input';
import loginImg from '../../assets/background.png';
import { ConteinerStyle } from './muiStyle';

import validationSchemaLogin from './validationSchemaLogin';
import './style.css';

const Login = () => {
  const [shouldRememberLogin, setShouldRememberLogin] = useState(false);
  const authCtx = React.useContext(AuthContext);
  const navigate = useNavigate();

  const initialValues = {
    email: localStorage.getItem('rememberEmail') || '',
    password: '',
  };

  const handleSubmit = async (values) => {
    try {
      const { data } = await userApi.login(values);
      const hasAccessToken = !!get(data, 'data.access_token', false);
      if (hasAccessToken) {
        if (shouldRememberLogin) {
          localStorage.setItem('rememberEmail', values.email);
        } else {
          localStorage.removeItem('rememberEmail');
        }
        await authCtx.login(data.data.access_token);
        toast.success('¡Bienvenido de vuelta!');
      }
    } catch (error) {
      if (error.response?.status === HTTP_STATUS.CONFLICT) {
        toast.error('El usuario está desactivado');
      } else if (error.response?.status === HTTP_STATUS.NOT_FOUND) {
        toast.error('Credenciales equivocadas');
      } else {
        errorHandling(error.response.status);
      }
    }
  };

  return (
    <div className="containerLogin">
      <Container component="main" maxWidth="md" fixed={true}>
        <CssBaseline />
        <Box sx={ConteinerStyle}>
          <Typography component="h1" variant="h3" noWrap={true}>
            ¡Bienvenidos a SpaceHR!
          </Typography>
          <Typography component="h5" variant="h5" noWrap={true}>
            Ingresá a tu Cuenta
          </Typography>
          <br />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaLogin}
            onSubmit={handleSubmit}
            validateOnBlur>
            {(formik) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <Input
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    helperText={formik.errors.email}
                    onChange={formik.handleChange}
                    variant="outlined"
                  />
                  <Input
                    id="password"
                    autoComplete="off"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    value={formik.values.password}
                    helperText={formik.errors.password}
                    onChange={formik.handleChange}
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        color="primary"
                        onChange={(e) =>
                          setShouldRememberLogin(e.target.checked)
                        }
                        checked={shouldRememberLogin}
                      />
                    }
                    label="Recuérdame"
                  />
                  <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}>
                    Conectarse
                  </Button>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end">
                    <Grid item>
                      <Button
                        aria-label="Navegar a la pantalla de recuperar contraseña"
                        variant="text"
                        onClick={() => navigate(PATHS.RECOVERPASSWORD)}>
                        ¿Olvidaste tu contraseña?
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Container>
      <div className="imgContainer">
        <img src={loginImg} alt="Login" className="loginImage" />
      </div>
    </div>
  );
};

export default Login;
