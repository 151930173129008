import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  addEmployees: {
    justifyContent: 'space-around',
    color: ' #7e62cc',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '12px',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    cursor: 'pointer',
  },
  boxContainer: {
    borderRadius: '18px',
    width: '30%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '1%',
  },
  attachButton: {
    border: 'none',
    color: 'gray',
    marginBottom: 2,
  },
  inputItem: {
    paddingBottom: '0.5rem',
  },
  hourItem: {
    paddingBottom: '0.5rem',
  },
  dateItem: {
    paddingBottom: '1rem',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
    paddingTop: '2%',
    paddingBottom: '3%',
  },
}));

export default useStyles;
