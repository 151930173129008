import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '18px',
    width: '40%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '1.5rem',
  },
  closeButtonStyle: {
    top: -40,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    lineHeight: '1.8rem',
    fontSize: '20px',
    paddingTop: '1rem',
    paddingBottom: '2rem',
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '1rem',
  },
  tableHead: {
    backgroundColor: 'lightgray',
  },
}));

export default useStyles;
