import React, { useState, useEffect } from 'react';
import { Avatar, Grid, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import getFile from '../../../../api/getFile';
import {
  errorHandling,
  translateCivilStatus,
} from '../../../../api/apiHelpers';
import userInfoApi from '../../../../api/userInfoApi';
import { parseDateDdMmYyyy } from '../../../../Utils/parseDate';
import { ReactComponent as MailIcon } from '../../../../assets/MailIcon.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/PhoneIcon.svg';
import { ReactComponent as AdressIcon } from '../../../../assets/AdressIcon.svg';
import { ReactComponent as BankIcon } from '../../../../assets/BankIcon.svg';
import { ReactComponent as OtherInfoIcon } from '../../../../assets/OtherInfoIcon.svg';
import useStyles from './style';

const propTypes = {
  userActive: PropTypes.array,
};

const defaultPropTypes = {
  userActive: [],
};

const EmployeeDetails = ({ userActive }) => {
  const classes = useStyles();
  const [profilePicture, setProfilePicture] = React.useState(null);
  const [userInfo, setUserInfo] = useState([]);

  const getUserData = async () => {
    try {
      const {
        data: { data },
      } = await userInfoApi.getByUser(userActive.id);

      setUserInfo(data);

      if (data.photo) {
        if (data.photo !== null && data.photo !== '') {
          const response = await getFile(data.photo);

          const file = response.data.data;

          const link = file.link;

          setProfilePicture(link);
        }
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Box className={classes.boxContainer}>
      <Typography className={classes.title}>Detalles del empleado</Typography>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} md={1}>
          {/* TODO cambiar a foto de perfil del usuar */}
          <Avatar src={profilePicture ?? '/broken-image.jpg'} />
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={11}>
              <Typography className={classes.name}>
                {userActive.name}
              </Typography>
              <Typography className={classes.text}>
                {userInfo.dateOfBirth
                  ? `Fecha de nacimiento: ${parseDateDdMmYyyy(
                      userInfo.dateOfBirth,
                    )} |`
                  : 'Fecha de nacimiento: Sin datos |'}
                {userInfo.nationality
                  ? ` Nacionalidad: ${userInfo.nationality} |`
                  : ' Nacionalidad: Sin datos |'}
                {userInfo.document
                  ? ` CI/DNI: ${userInfo.document}`
                  : ' CI/DNI: Sin datos'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className={classes.contactContainer}>
                <Typography className={classes.text}>
                  <div className={classes.contact}>
                    <MailIcon className={classes.logo} />
                    {userInfo.personalEmail
                      ? `${userInfo.personalEmail}`
                      : 'Sin datos'}
                  </div>
                </Typography>
                <Typography className={classes.text}>
                  <div className={classes.contact}>
                    <PhoneIcon className={classes.logo} />
                    {userInfo.phoneNumber
                      ? `${userInfo.phoneNumber}`
                      : 'Sin datos'}
                  </div>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} md={11}>
          <div className={classes.subContainer}>
            <Typography className={classes.subtitle}>
              <div className={classes.logoTextContainer}>
                <AdressIcon className={classes.logo} />
                Dirección
              </div>
            </Typography>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>
                Pais de residencia:
              </Typography>
              <Typography className={classes.text}>
                {userInfo.countryOfResidence}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>
                Ciudad de residencia:
              </Typography>
              <Typography className={classes.text}>{userInfo.city}</Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Dirección:</Typography>
              <Typography className={classes.text}>
                {userInfo.addres}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={11} md={11}>
          <div className={classes.subContainer}>
            <Typography className={classes.subtitle}>
              <div className={classes.logoTextContainer}>
                <BankIcon className={classes.logo} />
                Informacion bancaria
              </div>
            </Typography>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Banco:</Typography>
              <Typography className={classes.text}>
                {userInfo.bankName}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>
                Numero de cuenta:
              </Typography>
              <Typography className={classes.text}>
                {userInfo.bankAccountNumber}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Tipo de cuenta:</Typography>
              <Typography className={classes.text}>
                {userInfo.bankAccountType}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={11} md={11}>
          <div className={classes.subContainer}>
            <Typography className={classes.subtitle}>
              <div className={classes.logoTextContainer}>
                <OtherInfoIcon className={classes.logo} />
                Otra información
              </div>
            </Typography>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>
                Numero de emergencia:
              </Typography>
              <Typography className={classes.text}>
                {userInfo.emergencyContactPhone}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Sociedad medica:</Typography>
              <Typography className={classes.text}>
                {userInfo.heathcare}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Estado civil:</Typography>
              <Typography className={classes.text}>
                {translateCivilStatus(userInfo.civilStatus)}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Hijos:</Typography>
              <Typography className={classes.text}>
                {userInfo.kidsNames ? userInfo.kidsNames.length : 'No tiene'}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Nivel academico:</Typography>
              <Typography className={classes.text}>
                {userInfo.academicLevel}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Carrera:</Typography>
              <Typography className={classes.text}>
                {userInfo.career}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Idioma/s:</Typography>
              <Typography className={classes.text}>
                {userInfo.languages}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>
                Consideraciones especiales:
              </Typography>
              <Typography className={classes.text}>
                {userInfo.specialConditions}
              </Typography>
            </div>
            <div className={classes.rowInfo}>
              <Typography className={classes.item}>Talle remera:</Typography>
              <Typography className={classes.text}>
                {userInfo.shirtSize}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
EmployeeDetails.propTypes = propTypes;
EmployeeDetails.defaultProps = defaultPropTypes;
export default EmployeeDetails;
