import makeApiCall from '../app/makeApiCall';
import hrApi from './hrApi';

//User info data example
// {
//   "registrationDate": "2022-03-03T15:12:55.626Z",
//   "startDate": "2022-03-03T15:12:55.626Z",
//   "finishDate": "2022-03-03T15:12:55.626Z",
//   "comment": "string",
//   "type": "sick",
//   "status": "approved",
//   "answer": "string",
//   "userId": id
// }

export const getAll = makeApiCall((data) => hrApi.get('/log-request', data));

export const updateOne = makeApiCall((client, data) => {
  return hrApi.patch(`/log-request/${data.id}/request-status`, {
    status: data.status,
  });
});

const logRequestApi = {
  getAll: () => hrApi.get('log-request', {}),

  post: (data) => hrApi.post('log-request', data),

  edit: (id, data) => hrApi.patch(`log-request/${id}`, data),

  checkOther: (data) => hrApi.post(`log-request/check-others/`, data),

  delete: (id) => hrApi.delete(`log-request/${id}`, {}),

  get: (id) => hrApi.get(`log-request/${id}`, {}),

  patchRequestStatus: (id, data) =>
    hrApi.patch(`log-request/request-status/${id}`, data),

  rejectRequestApproved: (id, data) =>
    hrApi.patch(`log-request/reject-approved/${id}`, data),

  getByUser: (userId) => hrApi.get(`log-request/user/${userId}`, {}),

  getRequestHistory: (data) => hrApi.post(`log-request/request-history`, data),
};

export default logRequestApi;
