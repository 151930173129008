import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Typography } from '@mui/material';
import { errorHandling } from '../../../../api/apiHelpers';
import userApi from '../../../../api/userApi';
import LineSkeleton from '../../../shared/LineSkeleton';
import { parseDateDdMm } from '../../../../Utils/parseDate';
import { ReactComponent as AniversariesLogo } from '../../../../assets/AniversariesLogo.svg';
import useStyles from './style';

const Aniversaries = () => {
  const classes = useStyles();
  const [allBirthday, setAllBirthday] = useState([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const getAllBirthday = async () => {
    try {
      setIsLoadingRequest(true);
      const {
        data: { data },
      } = await userApi.getBirthday();
      setAllBirthday(data);
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoadingRequest(false);
    }
  };

  useEffect(() => {
    getAllBirthday();
  }, []);

  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography className={classes.headTextContainer}>
                  <AniversariesLogo />
                  <Typography className={classes.commonText}>
                    Cumpleaños
                  </Typography>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingRequest ? (
              allBirthday.map((row) =>
                row.active ? (
                  <TableRow key={row.id}>
                    <TableCell className={classes.textRowLeft}>
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.textRowRight}>
                      {parseDateDdMm(row.userInfo.dateOfBirth)}
                    </TableCell>
                  </TableRow>
                ) : null,
              )
            ) : (
              <LineSkeleton lineCont={allBirthday.lenght} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Aniversaries;
