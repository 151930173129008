import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';

import { noop } from 'lodash';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { errorHandling } from '../../../../api/apiHelpers';
import userDayOffApi from '../../../../api/userDayOffApi';
import { HTTP_STATUS, GENDER_EN } from '../../../../api/apiConstants';
import Input from '../../../shared/Input';
import useStyles from './styles';
import NumberControllInput from './NumberControlInput';

const propTypes = {
  userActive: PropTypes.array,
  onDisable: PropTypes.func,
  handleClose: PropTypes.func,
  getAllUsersHandler: PropTypes.func,
};

const defaultPropTypes = {
  userActive: [],
  onDisable: null,
  handleClose: noop,
  getAllUsersHandler: noop,
};

const BalanceForm = ({
  userActive,
  onDisable,
  handleClose,
  getAllUsersHandler,
}) => {
  const [otherGender, setOtherGender] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(userActive);
  const classes = useStyles();

  const formatData = (values) => {
    const finalData = { ...values };
    finalData.dateOfBirth = moment(finalData.dateOfBirth).toDate();

    if (finalData.genderPronounsOption === false) {
      finalData.genderPronouns = null;
    }
    if (finalData.gender === GENDER_EN.OTHER) {
      finalData.gender = otherGender;
    }
    if (finalData.kids === false) {
      finalData.kidsNames = [];
    }

    if (finalData.countryOfResidence !== null) {
      finalData.countryOfResidence = finalData.countryOfResidence.label;
    }

    if (Array.isArray(finalData.languages)) {
      const index = finalData.languages.indexOf('');

      if (index > -1) {
        finalData.languages.splice(index, 1);
      }

      finalData.languages = finalData.languages.toString();
    }

    return finalData;
  };

  const saveData = async (values) => {
    setIsLoading(true);
    try {
      const resp = await userDayOffApi.editBalance(
        values.id,
        values.normalDays,
        values.studyDays,
        values.spaceDays,
      );
      if (resp?.status === HTTP_STATUS.OK) {
        handleClose();
        getAllUsersHandler();
        toast.success('Datos guardados correctamente');
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  const setUserData = async () => {
    try {
      const {
        data: { data },
      } = await userDayOffApi.getByUser(userActive.id);

      setUserInfo(data);
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  useEffect(() => {
    setUserData();
  }, []);

  return (
    <>
      <div className={classes.boxContainer}>
        <Typography className={classes.title}>Edición de balance</Typography>
        <Formik
          initialValues={userActive}
          enableReinitialize={true}
          onSubmit={(values) => {
            saveData(values);
          }}>
          {({ values, handleChange, setFieldValue, errors }) => {
            return (
              <Form className={classes.formTable}>
                <Box className={classes.formContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Input
                        id="fullName"
                        value={values.name}
                        label="Nombre"
                        variant="outlined"
                        name="fullName"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <NumberControllInput
                        value={values.normalDays}
                        label="Dias vacacionales"
                        name="normalDays"
                        onChange={handleChange}
                        required
                        setFieldValue={setFieldValue}
                        variant="outlined"
                        id="normalDays"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <NumberControllInput
                        id="spaceDays"
                        variant="outlined"
                        value={values.spaceDays}
                        label="Spacedays"
                        name="spaceDays"
                        setFieldValue={setFieldValue}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <NumberControllInput
                        variant="outlined"
                        value={values.studyDays}
                        label="Dias de estudio"
                        id="studyDays"
                        name="studyDays"
                        setFieldValue={setFieldValue}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      fullWidth
                      color="primary"
                      type="submit"
                      loading={isLoading}
                      sx={{ width: '100%', marginBottom: '1rem' }}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained">
                      Guardar
                    </LoadingButton>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
BalanceForm.propTypes = propTypes;
BalanceForm.defaultProps = defaultPropTypes;
export default BalanceForm;
