import axios from 'axios';

import hrApi from '../api/hrApi';
import { errorHandling } from './apiHelpers';

const uploadFile = async (file, tableId, tableType, onProgress) => {
  const response = await hrApi.post('/storage', {
    originalName: file.name.toString(),
    mimeType: file.type.toString(),
    tableId,
    tableType,
  });

  if (!response?.data.success) {
    errorHandling(response.status);
  }

  const { link } = response?.data.data;

  const config = {
    onUploadProgress: (ev) =>
      onProgress(Math.round((ev.loaded / ev.total) * 100)),
    headers: {
      'Content-Type': file?.type,
      'x-amz-acl': 'private',
    },
  };

  await axios.put(link, file, config);

  return {
    fileUrl: link.split('?')[0],
  };
};

export default uploadFile;
