import moment from 'moment';
import { getUserId } from './../../../../api/apiHelpers';

const temp = {
  dateOfBirth: moment(new Date()).format('YYYY-MM-DD'),
  fullName: 'Un nombre',
  personalEmail: '',
  gender: 'other',
  otherGender: '',
  genderPronounsOption: false,
  genderPronouns: '',
  nationality: '',
  countryOfResidence: '',
  document: '',
  phoneNumber: '',
  addres: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  emergencyContactEmail: '',
  heathcare: '',
  civilStatus: '',
  kids: false,
  kidsNames: [],
  academicLevel: '',
  career: '',
  languages: [],
  specialConditions: '',
  bankName: '',
  bankAccountNumber: '',
  bankAccountType: '',
  city: '',
  shirtSize: '',
  photo: '',
  userId: getUserId(),
};

export default temp;
