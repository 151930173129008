import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { getUserId, errorHandling } from '../../api/apiHelpers';
import { ReactComponent as SpaceDaysIcon } from '../../assets/SpaceDaysIcon.svg';
import { ReactComponent as SickDaysIcon } from '../../assets/SickDaysIcon.svg';
import { ReactComponent as StudyDaysIcon } from '../../assets/StudyDaysIcon.svg';
import { ReactComponent as NormalDaysIcon } from '../../assets/NormalDaysIcon.svg';
import userDayOffApi from '../../api/userDayOffApi';
import useStyles from './style';

const UserBalance = () => {
  const classes = useStyles();
  const [userData, setUserData] = React.useState({
    spaceDays: 0,
    normalDays: 0,
    studyDays: 0,
    sickDays: 0,
    userId: getUserId(),
  });

  const getUserData = async () => {
    try {
      const {
        data: { data },
      } = await userDayOffApi.getByUser(userData.userId);
      if (Object.keys(data).length === 0) {
        await userDayOffApi.create(userData);
      } else {
        setUserData(data);
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  return (
    <Grid container className={classes.balanceContainer} columns={14}>
      <Grid item xs={11} md={3} p={2} className={classes.gridNormalDays} m={1}>
        <NormalDaysIcon />
        <Typography className={classes.dayType} noWrap>
          Licencia
        </Typography>
        <Typography className={classes.daysAmount} noWrap>
          {userData.normalDays}
        </Typography>
      </Grid>
      <Grid item xs={11} md={3} p={2} className={classes.gridSpaceDays} m={1}>
        <SpaceDaysIcon />
        <Typography className={classes.dayType} noWrap>
          SpaceDays
        </Typography>
        <Typography className={classes.daysAmount} noWrap>
          {userData.spaceDays}
        </Typography>
      </Grid>
      <Grid item xs={11} md={3} p={2} className={classes.gridStudyDays} m={1}>
        <StudyDaysIcon />
        <Typography className={classes.dayType} noWrap>
          Estudio
        </Typography>
        <Typography className={classes.daysAmount} noWrap>
          {userData.studyDays}
        </Typography>
      </Grid>
      <Grid item xs={11} md={3} p={2} className={classes.gridSickDays} m={1}>
        <SickDaysIcon />
        <Typography className={classes.dayType} noWrap>
          Enfermedad
        </Typography>
        <Typography className={classes.daysAmount} noWrap>
          {userData.sickDays}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserBalance;
