import { makeStyles } from '@mui/styles';

import WelcomeBG from '../../../../assets/WelcomeBG.svg';

const useStyles = makeStyles(() => ({
  welcomeContainer: {
    backgroundImage: 'url(' + WelcomeBG + ')',
    backgroundColor: '#7E62CC',
    margin: '1rem',
    padding: ' 2% 0 0 2%',
    height: '128px',
    borderRadius: 18,
  },
  welcomeText: {
    color: 'white',
  },
}));

export default useStyles;
