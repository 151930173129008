import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: '18px',
    width: '25%',
    height: '15%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '1rem',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '18px',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    marginBottom: '1rem',
  },
  buttonContainer: {
    display: 'flex',
  },
  rejectButton: {
    borderRadius: '8',
    color: theme.palette.error.main,
  },
}));

export default useStyles;
