import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: '20px',
    margin: '5px',
    borderRadius: '16px',
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  deleteButton: {
    borderRadius: 2,
    width: '100%',
    color: theme.palette.error.main,
    border: '1px solid',
  },

  text: {
    fontWeight: 600,
  },

  inputStyle: {
    margin: 'auto',
  },

  button: { border: 'none', color: 'gray' },

  closeButtonStyle: {
    position: 'relative',
    left: 10,
    top: -20,
  },

  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
  },
}));
export default useStyles;
