import React from 'react';
import PropTypes from 'prop-types';
import { addDays, format } from 'date-fns';
import es from 'date-fns/locale/es';
import useStyles from './calendarStyle';
const _ = require('lodash');

const propTypes = {
  viewingDate: PropTypes.instanceOf(Date),
};

const daysOfWeek = Array.from(new Array(7), (v, i) => i);

const Week = ({ viewingDate }) => {
  const classes = useStyles();

  return (
    <>
      {daysOfWeek.map((i) => {
        const stringDate = _.capitalize(
          format(addDays(viewingDate, i), 'eee', { locale: es }),
        );
        const numDate = format(addDays(viewingDate, i), 'dd');
        const isToday =
          addDays(viewingDate, i).setHours(0, 0, 0, 0) ===
          new Date().setHours(0, 0, 0, 0);

        let isTodayClass = `${classes.dates}`;

        if (isToday) {
          isTodayClass = `${classes.dates} ${classes.todayDate}`;
        }

        return (
          <div
            className={isTodayClass}
            style={{ gridColumn: `${i + 2} / span 1` }}
            key={i}>
            <p className={classes.stringDate}>{stringDate}</p>
            <p className={classes.numDate}>{numDate}</p>
          </div>
        );
      })}
    </>
  );
};

Week.propTypes = propTypes;

export default Week;
