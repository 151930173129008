import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Typography, Modal } from '@mui/material';

import userInfoApi from '../../../api/userInfoApi';
import {
  SHIRT_SIZE,
  CIVIL_STATUS,
  HTTP_STATUS,
  GENDER_EN,
  GENDER_ES,
  PATHS,
  DISABLE_CLOSE_MODAL,
} from '../../../api/apiConstants';
import userApi from '../../../api/userApi';
import { errorHandling, getUserId } from '../../../api/apiHelpers';
import UserBalance from '../../Balance/UserBalance';
import WelcomeCard from '../Utils/WelcomeCard/WelcomeCard';
import Aniversaries from '../Utils/Aniversaries/Aniversaries';
import OutOfOffice from '../Utils/OutOfOffice/OutOfOffice';
import UpcomingTimeOff from '../Utils/UpcomingTimeOff/UpcomingTimeOff';
import CompleteProfileModal from '../../../components/Profile/CompleteProfileModal/CompleteProfileModal';

const Dashboard = () => {
  const [openModal, setOpenModal] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const isNewUser = async () => {
    try {
      const {
        data: { data },
      } = await userInfoApi.getByUser(getUserId());
      if (!data.bankAccountNumber) {
        setIsNew(true);
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  useEffect(() => {
    isNewUser();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box>
              <WelcomeCard />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                margin: 1,
                borderRadius: 6,
              }}>
              <UserBalance />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper
              sx={{
                margin: 2,
                borderRadius: 6,
              }}>
              <UpcomingTimeOff />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper
              sx={{
                margin: 2,
                borderRadius: 6,
              }}>
              <OutOfOffice />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          sx={{
            margin: 2,
            borderRadius: 6,
          }}>
          <Aniversaries />
        </Paper>
      </Grid>
      {isNew ? (
        <Modal
          open={openModal}
          onClose={(_, reason) => {
            if (reason !== DISABLE_CLOSE_MODAL.BACKDROPCLICK) {
              handleCloseModal();
            }
          }}>
          <CompleteProfileModal handleClose={handleCloseModal} />
        </Modal>
      ) : null}
    </Grid>
  );
};

export default Dashboard;
