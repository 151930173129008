import React, { useContext } from 'react';
import IdleTimer from 'react-idle-timer';
import AuthContext from '../api/auth-contex';

const IDLE_TIMEOUT_INTERVAL_MS = 30 * 60 * 1000;

const UserActivityTimer = () => {
  const authCtx = useContext(AuthContext);

  const handleOnIdle = (event) => {
    authCtx.logout();
  };

  return (
    authCtx.isLoggedIn && (
      <IdleTimer timeout={IDLE_TIMEOUT_INTERVAL_MS} onIdle={handleOnIdle} />
    )
  );
};

export default UserActivityTimer;
