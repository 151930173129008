import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '18px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    padding: '2rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    borderColor: 'black',
  },
  logo: {
    margin: 'auto',
    padding: '1rem',
  },
  textContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    padding: '1rem',
  },
  title: {
    fontWeight: 700,
  },
  text: {
    fontWeight: 600,
  },
  closeButtonStyle: {
    position: 'relative',
    left: 10,
    top: -20,
  },
}));
export default useStyles;
