import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  homeContainer: {
    display: 'flex',
    height: '100vh',
  },
  toolbarHome: {
    background: 'white',
    borderBottom: '1px solid #d5d8db',
  },
  linkAvatar: {
    right: 0,
    position: 'absolute',
    marginRight: '10px',
  },
  toolbarSpaceIcon: {
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0px',
  },
  navigationContainer: {
    height: '100vh',
  },
  logoutContainer: {
    bottom: 0,
    position: 'absolute',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  logoutContainerClose: {
    bottom: 0,
    position: 'absolute',
    width: '100%',
    paddingLeft: '6px',
  },
  listContainer: {
    padding: 0,
  },
  mainContainer: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: '#f6f6f6',
  },
  activeTypo: {
    marginLeft: '1rem',
    marginRight: '1rem',
    color: '#082F3F',
    fontFamily: 'Montserrat',
    fontWeight: '400',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '16px',
  },
  inactiveTypo: {
    marginLeft: '1rem',
    marginRight: '1rem',
    color: theme.palette.inactive.main,
    fontFamily: 'Montserrat',
  },
  activeLogo: {
    color: theme.palette.primary.main,
    minWidth: '30px',
    paddingRight: '2rem',
  },
  inactiveLogo: {
    color: theme.palette.inactive.main,
    minWidth: '30px',
    paddingRight: '2rem',
  },
  activeLogoClose: {
    color: theme.palette.primary.main,
    minWidth: '30px',
    paddingLeft: '6px',
  },
  inactiveLogoClose: {
    color: theme.palette.inactive.main,
    minWidth: '30px',
    paddingLeft: '6px',
  },
  activeItem: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '30px',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '85%',
  },
  inactiveItem: {
    color: theme.palette.inactive.main,
    fontFamily: 'Montserrat',
  },
  activeItemClose: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '85%',
  },
  inactiveItemClose: {
    color: theme.palette.inactive.main,
    fontFamily: 'Montserrat',
  },
  drawerCustomStyle: {
    overflow: 'auto',
  },
}));
export default useStyles;
