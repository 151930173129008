import hrApi from './hrApi';

const eventApi = {
  getAllEvents: () => hrApi.get('event/', {}),

  getAllCalendarEvents: () => hrApi.get('event/calendar', {}),

  createEvent: (data) => hrApi.post('event/', data),

  patchEvent: (id, data) => hrApi.patch(`event/${id}`, data),

  deleteEvent: (id) => hrApi.delete(`event/${id}`, {}),

  getEvent: (id) => hrApi.get(`event/${id}`, {}),

  getEventßByUser: (userId) => hrApi.get(`event/user/${userId}`, {}),
};

export default eventApi;
