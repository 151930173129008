import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from '@mui/material';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';

import { errorHandling } from '../../../../api/apiHelpers';
import LineSkeleton from '../../../shared/LineSkeleton';
import { ReactComponent as UsersLogo } from '../../../../assets/usersLogo.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/DeleteIcon.svg';
import eventApi from '../../../../api/eventApi';
import AddEventModal from '../AddEvent/AddEventModal';
import { parseDateDdMmYyyy, parseDateHhMm } from '../../../../Utils/parseDate';
import EditEventsModal from '../EditEventsModal/EditEventsModal';
import DeleteEventModal from '../DeleteEvent/DeleteEventModal';
import useStyles from './style';

const EventsTable = () => {
  const classes = useStyles();
  const [eventsData, setEventsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [activeEvent, setActiveEvent] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleOpenDeleteModal = (activeEvent) => {
    setActiveEvent(activeEvent);
    setOpenDeleteModal(true);
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (activeEvent) => {
    setActiveEvent(activeEvent);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllEventsHandler = async () => {
    try {
      setIsLoadingEvents(true);
      const {
        data: { data },
      } = await eventApi.getAllEvents();
      setEventsData(data);
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoadingEvents(false);
    }
  };
  useEffect(() => {
    getAllEventsHandler();
  }, []);

  const slideEvents = useMemo(
    () =>
      eventsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, eventsData],
  );

  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div className={classes.topTab}>
          <div className={classes.employeesTextContainer}>
            <UsersLogo className={classes.logo} />
            <div className={classes.employeesText}>Eventos</div>
          </div>
          <div className={classes.addEmployeesButton}>
            <AddEventModal getAllEventsHandler={getAllEventsHandler} />
          </div>
        </div>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.headCellsLeft}>
                <span>Fecha</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Nombre</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Lugar</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Hora</span>
              </TableCell>
              <TableCell className={classes.headCellsRight}>
                <span>Acción</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingEvents ? (
              slideEvents?.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}>
                  <TableCell component="th" scope="row">
                    <div className={classes.textRowLeft}>
                      {parseDateDdMmYyyy(row.startDateAndTime)}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <div className={classes.textRowMid}>{row.name}</div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.textRowMid}>{row.place}</div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.textRowMid}>
                      {`${parseDateHhMm(
                        row.startDateAndTime,
                      )} - ${parseDateHhMm(row.endDateAndTime)}`}
                    </div>
                  </TableCell>
                  <TableCell className={classes.textRowRight}>
                    <Button variant="text" className={classes.buttonContainer}>
                      <EditIcon
                        className={classes.editButton}
                        onClick={() => handleOpen(row)}
                      />
                    </Button>
                    <Button
                      variant="text"
                      className={classes.buttonContainer}
                      onClick={() => handleOpenDeleteModal(row)}>
                      <DeleteIcon className={classes.deleteButton} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <LineSkeleton lineCont={rowsPerPage} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        labelRowsPerPage=""
        component="div"
        count={eventsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal open={open} onClose={handleClose}>
        <EditEventsModal
          handleClose={handleClose}
          eventActive={activeEvent}
          getAllEventsHandler={getAllEventsHandler}
        />
      </Modal>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DeleteEventModal
          handleClose={handleCloseDeleteModal}
          eventActive={activeEvent}
          getAllEventsHandler={getAllEventsHandler}
        />
      </Modal>
    </div>
  );
};
export default EventsTable;
