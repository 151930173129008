import React from 'react';

export const parseDateDdMm = (date) =>
  new Date(date).toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
  });

export const parseDateDdMmYyyy = (date) =>
  new Date(date).toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

export const parseDateMmYyyy = (date) =>
  new Date(date).toLocaleDateString('es-ES', {
    month: 'short',
    year: '2-digit',
  });

export const parseDateHhMm = (date) =>
  new Date(date).toLocaleTimeString('default', {
    hour: '2-digit',
    minute: '2-digit',
  });
