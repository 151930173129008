import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: '0 auto',
    maxWidth: '1500px',
    padding: '0',
    borderRadius: '8px',
  },
  tableHead: {
    backgroundColor: 'rgba(8, 47, 63, 0.05)',
  },
  tableHeadContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  topTab: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '2%',
    paddingRight: '5%',
    borderRadius: '50px 50px 0px 0px',
  },
  actionCell: {
    flexDirection: 'row',
  },
  headCellsMid: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'center',
  },
  headCellsLeft: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
  },
  headCellsRight: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'right',
    paddingRight: '4%',
  },
  requestsText: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: '1%',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
  },
  inputContainer: {
    alignSelf: 'center',
    width: '70%',
    paddingRight: '2rem',
  },
  input: {
    paddingLeft: '1%',
    width: '100%',
    borderRadius: '12px',
    borderColor: '#ede9f7',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  logo: {
    width: '36px',
    height: '36px',
  },
  requestsTextContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
  },
  title: { fontWeight: '700', fontSize: '18px' },

  textRowLeft: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
  },
  textRowMid: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
  },
  textRowRight: {
    textAlign: 'right',
    fontSize: '14px',
    fontWeight: '400',
    paddingRight: '3%',
    color: theme.palette.iconColor.main,
  },
  buttonContainer: {
    borderRadius: '8',
    color: '#5A5F61',
  },
  dateButtonContainer: {
    borderRadius: '8',
    textTransform: 'capitalize',
    color: '#5A5F61',
    whiteSpace: 'nowrap',
  },
  viewButton: {
    color: '#5A5F61',
    cursor: 'pointer',
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 },
  },
}));

export default useStyles;
