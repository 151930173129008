import * as Yup from 'yup';

const REQUIRED = 'Este campo es requerido';

const validationSchemaPassword = Yup.object().shape({
  password: Yup.string()
    .required(REQUIRED)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/,
      'Debe contener al menos 8 caracteres con una mayúscula, una minúscula y un número',
    ),
  passwordConfirm: Yup.string()
    .required(REQUIRED)
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir'),
});

export default validationSchemaPassword;
