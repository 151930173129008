import React from 'react';
import PropTypes from 'prop-types';
import { addDays, format, endOfWeek, subWeeks, addWeeks } from 'date-fns';
import es from 'date-fns/locale/es';
import { IconButton } from '@mui/material';
import LowerThanIcon from '../../assets/LowerThan.svg';
import GreaterThanIcon from '../../assets/GreaterThan.svg';
import useStyles from './calendarStyle';
const _ = require('lodash');

const propTypes = {
  viewingDate: PropTypes.instanceOf(Date),
  setViewingDate: PropTypes.func,
};

const WeekPicker = ({ viewingDate, setViewingDate }) => {
  const classes = useStyles();

  const subWeek = () => {
    setViewingDate(subWeeks(viewingDate, 1));
  };

  const addWeek = () => {
    setViewingDate(addWeeks(viewingDate, 1));
  };

  const firstMonth = _.capitalize(
    format(new Date(viewingDate), 'MMM', { locale: es }),
  );
  const secondMonth = _.capitalize(
    format(addDays(new Date(viewingDate), 7), 'MMM', { locale: es }),
  );

  return (
    <div className={classes.weekPickerContainer}>
      <IconButton onClick={subWeek}>
        <img src={LowerThanIcon} alt="subWeek" />
      </IconButton>
      <div className={classes.weekPickerDates}>
        <p className={classes.textDates}>
          {firstMonth} {format(viewingDate, 'dd')} - {secondMonth}{' '}
          {format(endOfWeek(viewingDate), 'dd')}
        </p>
      </div>
      <IconButton onClick={addWeek}>
        <img src={GreaterThanIcon} alt="addWeek" />
      </IconButton>
    </div>
  );
};

WeekPicker.propTypes = propTypes;

export default WeekPicker;
