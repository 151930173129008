import * as Yup from 'yup';

const REQUIRED = 'Este campo es requerido';

const validationSchemaAddEmployee = Yup.object().shape({
  personalEmail: Yup.string()
    .email('Formato de email inválido')
    .required(REQUIRED),
  userName: Yup.string()
    .required(REQUIRED)
    .matches(/^[a-zA-Z]+$/, 'Debe contener solo letras'),
  userLastname: Yup.string()
    .required(REQUIRED)
    .matches(/^[a-zA-Z]+$/, 'Debe contener solo letras'),
  weeklyHours: Yup.number().min(0, 'No puede ser negativo').required(REQUIRED),
  admin: Yup.string().required(REQUIRED),
  dependentWorker: Yup.string().required(REQUIRED),
  dateAdmission: Yup.date().required(REQUIRED),
  position: Yup.string().required(REQUIRED),
});

export default validationSchemaAddEmployee;
