import React from 'react';
import { Form, Formik } from 'formik';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Grid, Box, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { LoadingButton, TimePicker } from '@mui/lab';

import Input from '../../../shared/Input';
import { errorHandling, getUserId } from '../../../../api/apiHelpers';
import eventApi from '../../../../../src/api/eventApi';
import { HTTP_STATUS } from '../../../../../src/api/apiConstants';
import validationSchemaAddEvent from './validationSchemaAddEvent';
import useStyles from './style';
import temp from './BaseEventInfo';

const propTypes = {
  getAllEventsHandler: PropTypes.func,
  handleClose: PropTypes.func,
};

const defaultPropTypes = {
  getAllEventsHandler: null,
  handleClose: null,
};

const AddEventForm = ({ handleClose, getAllEventsHandler }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  const saveData = async (values) => {
    setIsLoading(true);
    try {
      if (values.hourFrom >= values.hourTo) {
        return toast.error(
          'La hora de comienzo debe ser menor a la de finalización',
        );
      }

      const startDate = new Date(values.date);
      startDate.setHours(values.hourFrom.getHours());
      startDate.setMinutes(values.hourFrom.getMinutes());
      startDate.setSeconds(0);
      const endDate = new Date(values.date);
      endDate.setHours(values.hourTo.getHours());
      endDate.setMinutes(values.hourTo.getMinutes());
      endDate.setSeconds(0);

      const data = {
        name: values.eventName,
        place: values.place,
        startDateAndTime: startDate,
        endDateAndTime: endDate,
        createdBy: getUserId(),
      };

      const resp = await eventApi.createEvent(data);
      if (resp?.status === HTTP_STATUS.CREATED) {
        toast.success('Evento creado');
        if (getAllEventsHandler && handleClose) {
          getAllEventsHandler();
          handleClose();
        } else {
          errorHandling(500);
        }
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <Formik
        initialValues={temp}
        validationSchema={validationSchemaAddEvent}
        enableReinitialize={true}
        onSubmit={(values) => {
          saveData(values);
        }}>
        {({ values, dirty, handleChange, setFieldValue, errors }) => {
          return (
            <Form>
              <Box className={classes.boxContainer}>
                <Typography className={classes.title}>
                  Agregar evento
                </Typography>
                <Grid container>
                  <Grid item xs={12} className={classes.inputItem}>
                    <Input
                      helperText={errors.eventName}
                      variant="outlined"
                      value={values.eventName}
                      label="Nombre"
                      name="eventName"
                      id="eventName"
                      onChange={handleChange}
                      required
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.dateItem}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        label="Fecha"
                        disablePast
                        helperText={errors.date}
                        value={values.date}
                        onChange={(newValue) => {
                          setFieldValue('date', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid container spacing={2} m={0}>
                    <Grid item xs={6} className={classes.hourItem}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Hora de comienzo"
                          value={values.hourFrom}
                          onChange={(newValue) => {
                            setFieldValue('hourFrom', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} className={classes.hourItem}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Hora de finalización"
                          value={values.hourTo}
                          onChange={(newValue) => {
                            setFieldValue('hourTo', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className={classes.inputItem}>
                    <Input
                      variant="outlined"
                      value={values.place}
                      label="Lugar"
                      name="place"
                      id="place"
                      onChange={handleChange}
                      required
                      type="text"
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  fullWidth
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  sx={{ borderRadius: 2, textTransform: 'none' }}
                  disabled={!dirty}
                  loadingPosition="start"
                  variant="contained">
                  Guardar
                </LoadingButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

AddEventForm.propTypes = propTypes;
AddEventForm.defaultProps = defaultPropTypes;

export default AddEventForm;
