import { REQUEST_STATUS } from './api/apiConstants';
import REQUEST_TYPE from './components/TimeOff/TimeOffTypes.enum';

export const TRANSLATED_REQUEST_TYPE = {
  [REQUEST_TYPE.NORMAL]: 'Normal',
  [REQUEST_TYPE.SICK]: 'Enfermedad',
  [REQUEST_TYPE.STUDY]: 'Estudio',
  [REQUEST_TYPE.SPACE]: 'Space Day',
  [REQUEST_TYPE.MATERNITY]: 'Maternidad',
  [REQUEST_TYPE.PATERNITY]: 'Paternidad',
  [REQUEST_TYPE.MOURNING]: 'Luto',
  [REQUEST_TYPE.MARRIAGE]: 'Casamiento',
  [REQUEST_TYPE.BLOOD_DONATION]: 'Donación de sangre',
  [REQUEST_TYPE.NOT_PAID]: 'No paga',
  [REQUEST_TYPE.PAP]: 'PAP',
};

export const TRANSLATED_REQUEST_STATUS = {
  [REQUEST_STATUS.PENDING]: 'Pendiente',
  [REQUEST_STATUS.APPROVED]: 'Aprobada',
  [REQUEST_STATUS.REJECTED]: 'Rechazada',
};
