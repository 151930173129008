import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid, Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { STATUS } from '../../../../api/apiConstants';
import useStyles from './style';

const RejectedRequestForm = ({
  handleClose,
  updateRequestStatus,
  requestInfo,
}) => {
  const classes = useStyles();

  return (
    <div className="container">
      <Formik
        initialValues={{ comment: '' }}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateRequestStatus(requestInfo, values.comment, STATUS.REJECTED);
          handleClose();
        }}>
        {({ values, dirty, handleChange, errors }) => {
          return (
            <Form>
              <Box className={classes.boxContainer}>
                <div className={classes.title}>Agregar comentario</div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} className={classes.inputItem}>
                    <TextField
                      className={classes.inputItem}
                      variant="outlined"
                      fullWidth
                      multiline
                      maxRows={2}
                      id="comment"
                      name="comment"
                      label=""
                      onChange={handleChange}
                      value={values.comment}
                      inputProps={{ maxLength: 250 }}
                      placeholder="Descripción (max 250)"
                      required
                    />
                  </Grid>
                </Grid>
                <Button
                  style={{
                    textTransform: 'none',
                    margin: 'auto',
                    display: 'grid',
                  }}
                  variant="contained"
                  disabled={!dirty}
                  type="submit"
                  color="primary">
                  Enviar
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
RejectedRequestForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  updateRequestStatus: PropTypes.func.isRequired,
  requestInfo: PropTypes.string.isRequired,
};

export default RejectedRequestForm;
