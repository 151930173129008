import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  containerDayOff: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: '20px',
    margin: '5px',
    borderRadius: '16px',
  },
  text: {
    fontWeight: 600,
  },
  button: { border: 'none', color: 'gray' },
  closeButtonStyle: {
    position: 'relative',
    left: 10,
    top: -20,
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
export default useStyles;
