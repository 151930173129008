import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tableContainer: {
    margin: 'auto',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxContainer: {
    borderRadius: '18px',
    backgroundColor: 'white',
    width: '60%',
    maxWidth: '900px',
    padding: '1rem',
    marginTop: '1rem',
  },
  profileContainer: {
    margin: 'auto',
    borderRadius: '8px',
  },
  buttonContainer: {
    borderRadius: '18px',
    backgroundColor: 'white',
    padding: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  titleContainer: {
    marginLeft: '1rem',
    marginTop: '1rem',
  },
  buttonKidsStyle: {
    marginTop: '1rem',
    marginLeft: '1rem',
  },
  buttonSaveStyle: {
    width: '30%',
  },
  kidsContainer: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  kidsNameContainer: {
    marginBottom: '1rem',
  },
  header: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
