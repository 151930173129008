import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import AuthContext from './api/auth-contex';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import NewPassword from './components/Password/NewPassword/View/NewPassword';
import RecoverPassword from './components/Password/NewPassword/View/RecoverPassword';

const App = () => {
  const authCtx = React.useContext(AuthContext);

  return (
    <Routes>
      {!authCtx.isLoggedIn ? (
        <>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Login />} />
          <Route path="/new-password/:token" element={<NewPassword />} />
          <Route
            path="/recover-password/:token"
            element={<RecoverPassword />}
          />
        </>
      ) : (
        <>
          <Route path="*" element={<Home />} />
        </>
      )}
    </Routes>
  );
};

export default App;
