import axios from 'axios';
import { toast } from 'react-toastify';
import { HTTP_STATUS } from './apiConstants';
import { logoutHelper } from './sessionHelpers';

const hrApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 6000,
  headers: {
    'Content-Type': 'application/json',
  },
});

hrApi.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
      toast.error('Problemas con las credenciales');
      logoutHelper();
    }
    return Promise.reject(error);
  },
);

export const setHeader = () => {
  const token = localStorage.getItem('spaceHrToken');

  if (token) {
    hrApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    hrApi.defaults.headers.common['Authorization'] = null;
  }
};

export default hrApi;
