import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { errorHandling } from './apiHelpers';

import * as logRequestApi from './logRequestApi';

const initialState = {
  requests: [],
};

export const getAll = createAsyncThunk('log-request/log-request', async () => {
  try {
    return logRequestApi.getAll();
  } catch (e) {
    errorHandling(e);
  }
});

export const updateOne = createAsyncThunk(
  'log-request/update',
  async (data) => {
    try {
      return logRequestApi.updateOne(data);
    } catch (e) {}
  },
);

export const logRequestSlice = createSlice({
  name: 'requestsData',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.requests = action.payload.data;
      })
      .addCase(updateOne.fulfilled, (state, action) => {
        const updatedRequest = action.payload.data;
        const index = state.requests.findIndex(
          (request) => request.id === updatedRequest.id,
        );
        state.requests[index] = updatedRequest;
      });
  },
});

export const { reset } = logRequestSlice.actions;

export const requestsSelector = (state) => state.requests;

export const selectRequests = createSelector(
  requestsSelector,
  (state) => state.requests,
);

export default logRequestSlice.reducer;
