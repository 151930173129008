import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem',
  },

  gantt: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridGap: '20px 5px',
    width: '100%',
    maxWidth: '1080px',
    margin: '0 auto',
    border: '1px solid lightgray',
    borderRadius: 18,
    backgroundColor: 'white',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  d1: { gridColumn: 2 },
  d2: { gridColumn: 3 },
  d3: { gridColumn: 4 },
  d4: { gridColumn: 5 },
  d5: { gridColumn: 6 },
  d6: { gridColumn: 7 },
  d7: { gridColumn: 8 },

  hSun: { gridColumn: '2 / span 1' },
  hMon: { gridColumn: '3 / span 1' },
  hTue: { gridColumn: '4 / span 1' },
  hWed: { gridColumn: '5 / span 1' },
  hThu: { gridColumn: '6 / span 1' },
  hFri: { gridColumn: '7 / span 1' },
  hSat: { gridColumn: '8 / span 1' },

  /* dividers */

  span: {
    borderLeft: '1px solid lightgrey',
  },

  spanHorizontal: {
    borderBottom: '1px solid lightgray',
  },

  dates: {
    flexDirection: 'column',
    textAlign: 'center',
    gridRow: 1,
    paddingTop: '0.5rem',
    marginRight: '-0.35rem',
  },

  todayDate: {
    backgroundColor: '#e6e6e6',
  },

  stringDate: {
    gridRow: 1,
    margin: 'auto',
  },

  numDate: {
    margin: '0.3rem',
    fontSize: '1.2rem',
    fontWeight: 'bolder',
    gridRow: 1,
  },

  gridCell: {
    position: 'relative',
    top: '-10px',
    height: '50px',
    paddingLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },

  birthday: {
    backgroundColor: '#FDF4E3',
  },

  license: {
    backgroundColor: '#E5F3FF',
  },

  gridName: {
    padding: 2,
  },

  nameText: {
    paddingLeft: '12px',
  },

  gridEvent: {
    borderRadius: 18,
    marginLeft: '5px',
    textAlign: 'center',
    position: 'relative',
    top: '-10px',
    alignSelf: 'center',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  gridToday: {
    position: 'relative',
    top: '-10px',
    height: '75px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#e6e6e6',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginRight: '-0.35rem',
    marginTop: '-0.6rem',
    marginBottom: '-0.6rem',
  },

  birthdayText: {
    color: '#E57825',
  },

  eventText: {
    color: '#4A77E0',
  },

  /* header */

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  weekPickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },

  weekPickerDates: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },

  textDates: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },

  inputContainer: {
    alignSelf: 'center',
  },

  input: {
    padding: '1%',
    width: '100%',
    borderRadius: '12px',
    borderColor: 'lightgray',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
  },

  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  button: {
    textTransform: 'none',
    borderRadius: '12px',
    backgroundColor: '#F8F9FC',
    color: 'gray',
  },
}));

export default useStyles;
