import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { HTTP_STATUS } from '../../../../../src/api/apiConstants';
import { errorHandling } from '../../../../api/apiHelpers';
import eventApi from '../../../../api/eventApi';
import useStyles from './style';

const propTypes = {
  eventActive: PropTypes.array,
  handleClose: PropTypes.func,
  getAllEventsHandler: PropTypes.func,
};

const defaultPropTypes = {
  eventActive: [],
  handleClose: null,
  getAllEventsHandler: null,
};

const DeleteEventModal = ({
  getAllEventsHandler,
  handleClose,
  eventActive,
}) => {
  const classes = useStyles();

  const deleteData = async () => {
    try {
      const resp = await eventApi.deleteEvent(eventActive.id);
      if (resp?.status === HTTP_STATUS.OK) {
        toast.success('Evento eliminado');
        if (getAllEventsHandler && handleClose) {
          getAllEventsHandler();
          handleClose();
        } else {
          errorHandling(500);
        }
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  return (
    <div className={classes.boxContainer}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>
          ¿Está seguro que quiere eliminar el evento?
        </Typography>
        <IconButton
          aria-label="cerrar modal"
          className={classes.closeButtonStyle}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
          className={classes.cancelButton}>
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="error"
          className={classes.deleteButton}
          onClick={deleteData}>
          Eliminar
        </Button>
      </div>
    </div>
  );
};
DeleteEventModal.propTypes = propTypes;
DeleteEventModal.defaultProps = defaultPropTypes;

export default DeleteEventModal;
