import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tableContainer: {
    margin: 'auto',
    borderRadius: 18,
  },
  commonText: {
    paddingLeft: '1rem',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
  },
  headTextContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  textRowRight: {
    textAlign: 'right',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
  },
  textRowLeft: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
  },
}));
export default useStyles;
