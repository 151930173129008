import React from 'react';
import { Button, Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';

import useStyles from './style';

const DeleteRequestModal = ({ handleClose, deleteRequest, requestInfo }) => {
  const classes = useStyles();

  return (
    <div className="container">
      <Box className={classes.boxContainer}>
        <div className={classes.title}>
          ¿Seguro que deseas eliminar esta solicitud?
        </div>
        <Grid className={classes.buttonContainer}>
          <Button
            className={classes.rejectButton}
            style={{
              textTransform: 'none',
              margin: 'auto',
              display: 'grid',
            }}
            variant="outlined"
            onClick={() => handleClose()}
            color="error">
            Cerrar
          </Button>
          <Button
            style={{
              textTransform: 'none',
              margin: 'auto',
              display: 'grid',
            }}
            variant="contained"
            onClick={() => deleteRequest(requestInfo.id)}
            color="primary">
            Eliminar
          </Button>
        </Grid>
      </Box>
    </div>
  );
};
DeleteRequestModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  deleteRequest: PropTypes.func.isRequired,
  requestInfo: PropTypes.string.isRequired,
};

export default DeleteRequestModal;
