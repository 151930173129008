import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import DoneIcon from '@mui/icons-material/Done';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import { isAfter, isBefore } from 'date-fns';
import { toast } from 'react-toastify';

import { errorHandling, getUserName } from '../../../../api/apiHelpers';
import { parseDateDdMmYyyy } from '../../../../Utils/parseDate';
import LineSkeleton from '../../../shared/LineSkeleton';
import { ReactComponent as RequestsLogo } from '../../../../assets/requestsLogo.svg';
import logRequestApi from '../../../../api/logRequestApi';
import {
  STATUS,
  REQUEST_TYPE_SP,
  REQUEST_TYPE_EN,
} from '../../../../api/apiConstants';
import RejectedRequestButton from '../RejectedRequest/RejectedRequestButton';
import ViewRequestModal from '../ViewRequestModal/ViewRequestModal';
import RejectedRequestForm from '../RejectedRequest/RejectedRequestForm';
import useStyles from './style';

const ApprovedRequestTable = () => {
  const [approvedRequestsData, setApprovedRequestsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [usingPatch, setUsingPatch] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSmallModal, setOpenSmallModal] = useState(false);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRejectPressed = () => {
    setOpen(false);
    setOpenSmallModal(true);
  };

  const handleCloseSmallModal = () => {
    setOpenSmallModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllRequestsHandler = async () => {
    try {
      setIsLoadingRequest(true);

      const {
        data: { data },
      } = await logRequestApi.getAll();

      setApprovedRequestsData(
        data.filter(
          (request) =>
            request.status === STATUS.APPROVED && request.user.active,
        ),
      );
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setUsingPatch(false);
      setIsLoadingRequest(false);
    }
  };

  const updateRequestStatus = async (values, answer, status) => {
    try {
      setUsingPatch(true);
      const id = values.id;
      const data = {
        status: status,
        answer,
        reviewedBy: getUserName(),
      };
      const resp = await logRequestApi.rejectRequestApproved(id, data);
      if (resp?.status === 200) {
        toast.success('Solicitud rechazada');
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      getAllRequestsHandler();
    }
  };

  useEffect(() => {
    getAllRequestsHandler();
  }, []);

  const translateType = (type) => {
    switch (type) {
      case REQUEST_TYPE_EN.NORMAL:
        return REQUEST_TYPE_SP.NORMAL;
      case REQUEST_TYPE_EN.SICK:
        return REQUEST_TYPE_SP.SICK;
      case REQUEST_TYPE_EN.STUDY:
        return REQUEST_TYPE_SP.STUDY;
      case REQUEST_TYPE_EN.SPACE:
        return REQUEST_TYPE_SP.SPACE;
      case REQUEST_TYPE_EN.MATERNITY:
        return REQUEST_TYPE_SP.MATERNITY;
      case REQUEST_TYPE_EN.PATERNITY:
        return REQUEST_TYPE_SP.PATERNITY;
      case REQUEST_TYPE_EN.MOURNING:
        return REQUEST_TYPE_SP.MOURNING;
      case REQUEST_TYPE_EN.MARRIAGE:
        return REQUEST_TYPE_SP.MARRIAGE;
      case REQUEST_TYPE_EN.BLOOD_DONATION:
        return REQUEST_TYPE_SP.BLOOD_DONATION;
      case REQUEST_TYPE_EN.NOT_PAID:
        return REQUEST_TYPE_SP.NOT_PAID;
      case REQUEST_TYPE_EN.PAP:
        return REQUEST_TYPE_SP.PAP;
      default:
        return 'Estado invalido';
    }
  };
  const getStylesByRequestType = (requestType) => {
    switch (requestType) {
      case REQUEST_TYPE_EN.NORMAL:
        return classes.textRowMidNormal;
      case REQUEST_TYPE_EN.SICK:
        return classes.textRowMidSick;
      case REQUEST_TYPE_EN.STUDY:
        return classes.textRowMidStudy;
      case REQUEST_TYPE_EN.SPACE:
        return classes.textRowMidSpace;
      case REQUEST_TYPE_EN.MATERNITY:
        return classes.textRowMidMaternity;
      case REQUEST_TYPE_EN.PATERNITY:
        return classes.textRowMidPaternity;
      case REQUEST_TYPE_EN.MOURNING:
        return classes.textRowMidMourning;
      case REQUEST_TYPE_EN.MARRIAGE:
        return classes.textRowMidMerriage;
      case REQUEST_TYPE_EN.BLOOD_DONATION:
        return classes.textRowMidBlood;
      case REQUEST_TYPE_EN.NOT_PAID:
        return classes.textRowMidNoPaid;
      case REQUEST_TYPE_EN.PAP:
        return classes.textRowMidPap;
      default:
        return '';
    }
  };
  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div className={classes.topTab}>
          <div className={classes.requestsTextContainer}>
            <RequestsLogo className={classes.logo} />
            <div className={classes.requestsText}>
              <p>Solicitudes aprobadas</p>
            </div>
          </div>
        </div>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.headCells}>
              <TableCell className={classes.headCellsLeft}>
                <span>Nombre</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Fecha de inicio</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Fecha de finalización</span>
              </TableCell>
              <TableCell className={classes.headCellsMid}>
                <span>Tipo</span>
              </TableCell>
              <TableCell className={classes.headCellsRight}>
                <span>Acción</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingRequest ? (
              approvedRequestsData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell onClick={() => handleOpen(row)}>
                        <div className={classes.textRowLeft}>
                          {row.user.name}
                        </div>
                      </TableCell>
                      <TableCell onClick={() => handleOpen(row)}>
                        <div className={classes.textRowMid}>
                          {parseDateDdMmYyyy(row.startDate)}
                        </div>
                      </TableCell>
                      <TableCell onClick={() => handleOpen(row)}>
                        <div className={classes.textRowMid}>
                          {parseDateDdMmYyyy(row.finishDate)}
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.requestCell}
                        onClick={() => handleOpen(row)}>
                        <div className={getStylesByRequestType(row.type)}>
                          {translateType(row.type)}
                        </div>
                      </TableCell>
                      <TableCell className={classes.textRowRight}>
                        <RejectedRequestButton
                          updateRequestStatus={updateRequestStatus}
                          requestInfo={row}
                          type="cross"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <LineSkeleton lineCont={rowsPerPage} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        labelRowsPerPage=""
        component="div"
        count={approvedRequestsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal open={open} onClose={handleClose}>
        <ViewRequestModal
          requestInfo={selectedItem}
          handleClose={handleClose}
          handleRejectPressed={handleRejectPressed}
          updateRequestStatus={updateRequestStatus}
        />
      </Modal>
      <Modal open={openSmallModal} onClose={handleCloseSmallModal}>
        <RejectedRequestForm
          requestInfo={selectedItem}
          handleClose={handleCloseSmallModal}
          updateRequestStatus={updateRequestStatus}
        />
      </Modal>
    </div>
  );
};
export default ApprovedRequestTable;
