import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';

import { PublicError } from '../../../../classes/PublicError';
import EmployeeDetailsFormEdit from './EmployeeDetailsFormEdit';
import useStyles from './style';

const propTypes = {
  userActive: PropTypes.array,
  disableUser: PropTypes.func,
  getAllUsersHandler: PropTypes.func,
  isActive: PropTypes.bool,
};

const defaultPropTypes = {
  userActive: [],
  disableUser: null,
  isActive: true,
  getAllUsersHandler: null,
};

const EmployeeDetailsEditModal = ({
  userActive,
  disableUser,
  isActive,
  getAllUsersHandler,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        variant="text"
        onClick={handleOpen}
        disabled={!isActive}
        className={classes.buttonContainer}>
        <EditIcon className={classes.viewButton} />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <EmployeeDetailsFormEdit
          userActive={userActive}
          handleClose={handleClose}
          getAllUsersHandler={getAllUsersHandler}
          onDisable={async () => {
            const res = await disableUser(userActive, false);
            if (!res) {
              throw new PublicError(
                'No se puede obtener el balance de días del usuario',
              );
            }
            handleClose();
          }}
        />
      </Modal>
    </div>
  );
};
EmployeeDetailsEditModal.propTypes = propTypes;
EmployeeDetailsEditModal.defaultProps = defaultPropTypes;
export default EmployeeDetailsEditModal;
