import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { areIntervalsOverlapping } from 'date-fns';
import MimeType from '../Utils/MimeType.enum';
import REQUEST_TYPE from '../components/TimeOff/TimeOffTypes.enum';
import { PublicError } from '../classes/PublicError';
import {
  HTTP_STATUS,
  REQUEST_TYPE_SP,
  REQUEST_TYPE_EN,
  PATHS,
  ROUTES,
  CIVIL_STATUS,
  CIVIL_STATUS_ES,
  USER_INFO_VALUES,
  USER_INFO_VALUES_SP,
} from './apiConstants';
import logRequestApi from './logRequestApi';

export const getUserId = () => {
  const token = localStorage.getItem('spaceHrToken');
  if (token) {
    const result = jwtDecode(token);
    return result.userId;
  } else {
    return null;
  }
};
export const getUserName = () => {
  const token = localStorage.getItem('spaceHrToken');
  if (token) {
    const result = jwtDecode(token);
    return result.userName;
  } else {
    return null;
  }
};
export const errorHandling = (error) => {
  console.error(error);
  if (error instanceof PublicError) {
    toast.error(error.message);
  } else {
    switch (error) {
      case HTTP_STATUS.UNHANDLED:
        toast.error('Error inesperado del servidor');
        break;
      case HTTP_STATUS.BAD_REQUEST:
        toast.error('Datos no validos');
        break;
      case HTTP_STATUS.FORBIDDEN:
        toast.error('No cuentas con permisos para hacer eso');
        break;
      case HTTP_STATUS.NOT_FOUND:
        toast.error('Resultado no encontrado');
        break;
      case HTTP_STATUS.SERVICE_UNAVAILABLE:
        toast.error('Servidor fuera de servicio');
        break;
      case HTTP_STATUS.CONFLICT:
        toast.error('El email ya se encuentra en uso');
        break;
      default:
        toast.error('Error inesperado');
        break;
    }
  }
};

export const translateValueType = (value) => {
  if (typeof value === 'string' && value.search('shorter than') >= 0) {
    switch (value.trim().split(' ')[0]) {
      case USER_INFO_VALUES.DATEOFBIRTH:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.DATEOFBIRTH;
      case USER_INFO_VALUES.PERSONAL_EMAIL:
        return (
          'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.PERSONAL_EMAIL
        );
      case USER_INFO_VALUES.GENDER:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.GENDER;
      case USER_INFO_VALUES.GENDER_PRONOUNS:
        return (
          'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.GENDER_PRONOUNS
        );
      case USER_INFO_VALUES.NATIONALITY:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.NATIONALITY;
      case USER_INFO_VALUES.COUNTRYOFRESIDENCE:
        return (
          'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.COUNTRYOFRESIDENCE
        );
      case USER_INFO_VALUES.DOCUMENT:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.DOCUMENT;
      case USER_INFO_VALUES.PHONE_NUMBER:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.PHONE_NUMBER;
      case USER_INFO_VALUES.ADDRES:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.ADDRES;
      case USER_INFO_VALUES.EMERGENCY_CONTACT_NAME:
        return (
          'Se exedio de caracteres en ' +
          USER_INFO_VALUES_SP.EMERGENCY_CONTACT_NAME
        );
      case USER_INFO_VALUES.EMERGENCY_CONTACT_PHONE:
        return (
          'Se exedio de caracteres en ' +
          USER_INFO_VALUES_SP.EMERGENCY_CONTACT_PHONE
        );
      case USER_INFO_VALUES.EMERGENCYCONTACTEMAIL:
        return (
          'Se exedio de caracteres en ' +
          USER_INFO_VALUES_SP.EMERGENCYCONTACTEMAIL
        );
      case USER_INFO_VALUES.HEATHCARE:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.HEATHCARE;
      case USER_INFO_VALUES.CIVILSTATUS:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.CIVILSTATUS;
      case USER_INFO_VALUES.KIDS:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.KIDS;
      case USER_INFO_VALUES.KIDSNAMES:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.KIDSNAMES;
      case USER_INFO_VALUES.ACADEMICLEVEL:
        return (
          'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.ACADEMICLEVEL
        );
      case USER_INFO_VALUES.CAREER:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.CAREER;
      case USER_INFO_VALUES.LANGUAGES:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.LANGUAGES;
      case USER_INFO_VALUES.SPECIAL_CONDITIONS:
        return (
          'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.SPECIAL_CONDITIONS
        );
      case USER_INFO_VALUES.BANK_NAME:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.BANK_NAME;
      case USER_INFO_VALUES.CITY:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.CITY;
      case USER_INFO_VALUES.BANK_ACCOUNT_NUMBER:
        return (
          'Se exedio de caracteres en ' +
          USER_INFO_VALUES_SP.BANK_ACCOUNT_NUMBER
        );
      case USER_INFO_VALUES.BANK_ACCOUNT_TYPE:
        return (
          'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.BANK_ACCOUNT_TYPE
        );
      case USER_INFO_VALUES.SHIRT_SIZE:
        return 'Se exedio de caracteres en ' + USER_INFO_VALUES_SP.SHIRT_SIZE;
      default:
        return 'Se exedio de caracteres en un campo no tomado en cuenta';
    }
  } else {
    return value;
  }
};

export const translateType = (type) => {
  switch (type) {
    case REQUEST_TYPE_EN.NORMAL:
      return REQUEST_TYPE_SP.NORMAL;
    case REQUEST_TYPE_EN.SICK:
      return REQUEST_TYPE_SP.SICK;
    case REQUEST_TYPE_EN.STUDY:
      return REQUEST_TYPE_SP.STUDY;
    case REQUEST_TYPE_EN.SPACE:
      return REQUEST_TYPE_SP.SPACE;
    case REQUEST_TYPE_EN.MATERNITY:
      return REQUEST_TYPE_SP.MATERNITY;
    case REQUEST_TYPE_EN.PATERNITY:
      return REQUEST_TYPE_SP.PATERNITY;
    case REQUEST_TYPE_EN.MOURNING:
      return REQUEST_TYPE_SP.MOURNING;
    case REQUEST_TYPE_EN.MARRIAGE:
      return REQUEST_TYPE_SP.MARRIAGE;
    case REQUEST_TYPE_EN.BLOOD_DONATION:
      return REQUEST_TYPE_SP.BLOOD_DONATION;
    case REQUEST_TYPE_EN.NOT_PAID:
      return REQUEST_TYPE_SP.NOT_PAID;
    case REQUEST_TYPE_EN.PAP:
      return REQUEST_TYPE_SP.PAP;
    default:
      return 'Estado invalido';
  }
};

export const translateCivilStatus = (status) => {
  switch (status) {
    case CIVIL_STATUS.SINGLE:
      return CIVIL_STATUS_ES.SINGLE;
    case CIVIL_STATUS.MARRIED:
      return CIVIL_STATUS_ES.MARRIED;
    case CIVIL_STATUS.DIVORCED:
      return CIVIL_STATUS_ES.DIVORCED;
    case CIVIL_STATUS.WIDOWED:
      return CIVIL_STATUS_ES.WIDOWED;
    default:
      return 'Estado invalido';
  }
};

export const getUserRole = () => {
  const token = localStorage.getItem('spaceHrToken');
  if (token) {
    const result = jwtDecode(token);
    return result.role;
  } else {
    return null;
  }
};

export const getOverlappingRequests = (requests, requestToApprove) => {
  return requests.filter((request) => {
    const endDateApproved = new Date(request.finishDate);
    const startDateApproved = new Date(request.startDate);
    startDateApproved.setDate(startDateApproved.getDate() - 1);
    endDateApproved.setDate(endDateApproved.getDate() + 1);
    return areIntervalsOverlapping(
      {
        start: startDateApproved,
        end: endDateApproved,
      },
      {
        start: new Date(requestToApprove.startDate),
        end: new Date(requestToApprove.finishDate),
      },
    );
  });
};

export const formatDate = (date) => {
  return moment(date).format('DD-MM-YYYY');
};

export const getBusinessDatesCount = (startDate, finishDate) => {
  let count = 0;

  const curDate = new Date(startDate);
  curDate.setHours(0, 0, 0, 0);

  const formatFinishDate = new Date(finishDate);
  formatFinishDate.setHours(23, 59, 59, 0);

  while (curDate <= new Date(formatFinishDate)) {
    if (!isSpecialDay(curDate)) {
      count++;
    }

    curDate.setDate(curDate.getDate() + 1);
  }

  return count;
};

export const isSpecialDay = (dateToCheck) => {
  return isSunday(dateToCheck) || isHolyday(dateToCheck);
};

const isSunday = (dateToCheck) => {
  const dayOfWeek = dateToCheck.getDay();

  return dayOfWeek === 0;
};

const isHolyday = (dateToCheck) => {
  const dayNumber = dateToCheck.getDate();
  const monthNumber = dateToCheck.getMonth();
  const weekdayNumber = dateToCheck.getDay();
  const isWeekend = weekdayNumber === 0 || weekdayNumber === 6;

  return (
    !isWeekend &&
    ((dayNumber === 1 && monthNumber === 0) ||
      (dayNumber === 1 && monthNumber === 4) ||
      (dayNumber === 18 && monthNumber === 6) ||
      (dayNumber === 25 && monthNumber === 7) ||
      (dayNumber === 25 && monthNumber === 11))
  );
};

export const getFileName = (fileName) => {
  return fileName.slice(fileName.indexOf('-') + 1);
};

export const checkFileType = (file) => {
  const regexCheck = new RegExp(MimeType.IMGAGE_CHECK);

  if (!file) {
    return null;
  }

  const fileType = file.target.files[0].type;

  if (regexCheck.test(fileType) || fileType === MimeType.PDF) {
    return file;
  } else {
    return null;
  }
};

export const pathToRoute = (path) => {
  const keyFormPath = Object.keys(PATHS).find((key) => PATHS[key] === path);
  return ROUTES[keyFormPath];
};

export const acceptFileTypes = () => {
  return MimeType.IMAGE + ',' + MimeType.PDF;
};

export const maxDaysCalculation = async (values, data, range) => {
  const initialErrorMessage = 'Error:\n';
  let errorMessage = initialErrorMessage;
  let dateRangeLength = 0;
  let dayNumber = 0;

  if (data && Object.keys(data).length > 0) {
    if (range.from === null || range.to === null) {
      errorMessage += ' - Seleccione inicio y fin';
    } else {
      const rangeCheck = moment(range.from).diff(moment(new Date()), 'days');

      dateRangeLength = getBusinessDatesCount(range.from, range.to);

      const dayBefore = moment(new Date(range.from))
        .subtract(1, 'days')
        .toDate();

      const dayAfter = moment(new Date(range.to)).add(1, 'days').toDate();

      const otherRequestResponse = await logRequestApi.checkOther({
        dayBefore: dayBefore,
        dayAfter: dayAfter,
        userId: getUserId(),
      });

      const otherRequest = otherRequestResponse.data.data;

      const nextToHolyday = isHolyday(dayBefore) || isHolyday(dayAfter);

      switch (true) {
        case values.type === REQUEST_TYPE.STUDY && dateRangeLength > 3:
          errorMessage += ' - Los días de estudio no pueden ser más de 3 \n';
          dayNumber = 3;
          break;

        case values.type === REQUEST_TYPE.SPACE && dateRangeLength > 1:
          errorMessage += ' - Los "Space days" no pueden ser más de 1 \n';
          dayNumber = 0;
          break;

        case values.type === REQUEST_TYPE.NORMAL && dateRangeLength > 20:
          errorMessage += ' - La licencia normal no puede ser mayor a 20 \n';
          dayNumber = 20;
          break;

        case values.type === REQUEST_TYPE.NORMAL &&
          dateRangeLength > data.normalDays + 20:
          errorMessage += ' - No tienes suficiente días libres \n';
          break;

        case values.type === REQUEST_TYPE.SPACE &&
          dateRangeLength > data.spaceDays:
          errorMessage += ' - No tienes suficientes "space days" \n';
          break;

        case values.type === REQUEST_TYPE.STUDY &&
          dateRangeLength > data.studyDays:
          errorMessage += ' - No tienes suficiente días de estudio \n';
          break;

        case values.type === REQUEST_TYPE.MATERNITY && dateRangeLength !== 98:
          errorMessage +=
            ' - Los días de maternidad se ajustaron (los días de paternidad son 98 y deben pedirse de corridos) \n';
          dayNumber = 98;
          break;

        case values.type === REQUEST_TYPE.PATERNITY && dateRangeLength !== 13:
          errorMessage +=
            ' - Los días de paterindad se ajustaron (los días de paternidad son 13 y deben pedirse de corridos) \n';
          dayNumber = 13;
          break;

        case values.type === REQUEST_TYPE.MOURNING && dateRangeLength !== 3:
          errorMessage +=
            ' - Los días de duelo solo pueden ser 3 días de corrido \n';
          dayNumber = 3;
          break;

        case values.type === REQUEST_TYPE.MARRIAGE && dateRangeLength !== 3:
          errorMessage +=
            ' - Los días de casamiento solo pueden ser 3 días de corrido \n';
          dayNumber = 3;
          break;

        case values.type === REQUEST_TYPE.PAP && dateRangeLength > 1:
          errorMessage += ' - Los dias para el PAP no pueden ser más de 1 \n';
          dayNumber = 0;
          break;

        case values.type === REQUEST_TYPE.BLOOD_DONATION && dateRangeLength > 1:
          errorMessage +=
            ' - Los dias para donar sangre no pueden ser más de 1 \n';
          dayNumber = 0;
          break;

        case rangeCheck <= 13 &&
          dateRangeLength <= 3 &&
          values.type === REQUEST_TYPE.NORMAL:
          errorMessage +=
            ' - No se permite solicitar una licencia, de 3 días o menos, con 13 días de anticipación \n';
          break;

        case rangeCheck <= 29 &&
          dateRangeLength > 3 &&
          values.type === REQUEST_TYPE.NORMAL:
          errorMessage +=
            ' - No se permite solicitar una licencia, de más de 3 días, con 29 días de anticipación \n';
          break;

        case otherRequest:
          errorMessage +=
            ' - Se encuentra otra licencia pegada a las fechas selecionadas \n';
          dayNumber = 0;
          break;

        case values.type === REQUEST_TYPE.SPACE && nextToHolyday:
          errorMessage +=
            ' - No se puede pedir una SPACE DAY a continuacion de un feriado \n';
          dayNumber = 0;
          break;

        case dateRangeLength === 0:
          errorMessage += ' - Selecciono un total de 0 dias \n';
          dayNumber = 0;
          break;

        default:
          break;
      }
    }
    if (errorMessage !== initialErrorMessage) {
      return { error: errorMessage, maxDays: dayNumber };
    } else {
      return { maxDays: dayNumber };
    }
  }
};
