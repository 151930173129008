import React, { useState, useEffect } from 'react';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';

import { startOfWeek } from 'date-fns';
import { DISABLE_CLOSE_MODAL } from '../../api/apiConstants';
import userInfoApi from '../../api/userInfoApi';
import { errorHandling, getUserId } from '../../api/apiHelpers';
import eventApi from '../../api/eventApi';
import CompleteProfileModal from '../Profile/CompleteProfileModal/CompleteProfileModal';
import useStyles from './calendarStyle';
import Week from './Week';
import CalendarHeader from './CalendarHeader';
import Row from './Row';

const propTypes = {
  closeModal: PropTypes.func,
  getAllRequestsHandler: PropTypes.func,
};

const defaultPropTypes = {
  closeModal: undefined,
  getAllRequestsHandler: undefined,
};

const Calendar = () => {
  const [calendarData, setCalendarData] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState('');
  const [viewingDate, setViewingDate] = useState(startOfWeek(new Date()));
  const [openModal, setOpenModal] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const isNewUser = async () => {
    try {
      const {
        data: { data },
      } = await userInfoApi.getByUser(getUserId());
      if (!data.bankAccountNumber) {
        setIsNew(true);
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  useEffect(() => {
    isNewUser();
  }, []);

  const classes = useStyles();

  const getCalendarData = async () => {
    try {
      setIsLoadingRequest(true);
      const {
        data: { data },
      } = await eventApi.getAllCalendarEvents();
      if (data) {
        setCalendarData(data);
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      setIsLoadingRequest(false);
    }
  };

  useEffect(() => {
    getCalendarData();
  }, []);

  const spaceEvents = calendarData?.events || [];
  const users = calendarData?.users || [];

  const spaceUser = {
    //TODO: add image when S3 if fixed
    name: 'Eventos Space',
  };

  const searchUser = (users, userToSearch) => {
    if (userToSearch === '') {
      return setSearchedUsers([]);
    }

    const filteredUsers = users.filter((user) => {
      if (user.name.toLowerCase().includes(userToSearch.toLowerCase())) {
        return true;
      }
      return false;
    });

    setIsSearching(true);
    setSearchedUsers(filteredUsers);
  };

  const usersToRender =
    searchedUsers.length || isSearching ? searchedUsers : users;

  return (
    <div className={classes.container}>
      <CalendarHeader
        viewingDate={viewingDate}
        setViewingDate={setViewingDate}
        searchUser={searchUser}
        setIsSearching={setIsSearching}
        users={users}
      />

      <div className={classes.gantt}>
        <Week viewingDate={viewingDate} />
        <span
          className={`${classes.d1} ${classes.span}`}
          style={{ gridRow: `1 / ${usersToRender.length + 4}` }}
        />
        {searchedUsers.length === 0 && (
          <span
            className={`${classes.spanHorizontal}`}
            style={{ gridColumn: `1 / 9`, gridRow: 1 }}
          />
        )}
        {usersToRender.map((user, i) => {
          return (
            <span
              key={i}
              className={`${classes.spanHorizontal}`}
              style={{ gridColumn: `1 / 9`, gridRow: `${i + 2}` }}
            />
          );
        })}
        <span
          className={`${classes.d2} ${classes.span}`}
          style={{ gridRow: `1 / ${usersToRender.length + 4}` }}
        />
        <span
          className={`${classes.d3} ${classes.span}`}
          style={{ gridRow: `1 / ${usersToRender.length + 4}` }}
        />
        <span
          className={`${classes.d4} ${classes.span}`}
          style={{ gridRow: `1 / ${usersToRender.length + 4}` }}
        />
        <span
          className={`${classes.d5} ${classes.span}`}
          style={{ gridRow: `1 / ${usersToRender.length + 4}` }}
        />
        <span
          className={`${classes.d6} ${classes.span}`}
          style={{ gridRow: `1 / ${usersToRender.length + 4}` }}
        />
        <span
          className={`${classes.d7} ${classes.span}`}
          style={{ gridRow: `1 / ${usersToRender.length + 4}` }}
        />
        {!isLoadingRequest &&
        spaceEvents &&
        !searchedUsers.length &&
        !isSearching ? (
          <Row
            viewingDate={viewingDate}
            startDateField="startDateAndTime"
            endDateField="endDateAndTime"
            position={2}
            key={Math.random()}
            events={spaceEvents}
            user={spaceUser}
          />
        ) : null}
        {!isLoadingRequest
          ? usersToRender.map((user, i) => (
              <Row
                viewingDate={viewingDate}
                startDateField="startDate"
                endDateField="finishDate"
                position={i + 3}
                birthday={user.birthday}
                key={user.userId}
                events={user.requests}
                user={user}
              />
            ))
          : null}
      </div>

      {isNew ? (
        <Modal
          open={openModal}
          onClose={(_, reason) => {
            if (reason !== DISABLE_CLOSE_MODAL.BACKDROPCLICK) {
              handleCloseModal();
            }
          }}>
          <CompleteProfileModal handleClose={handleCloseModal} />
        </Modal>
      ) : null}
    </div>
  );
};

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultPropTypes;

export default Calendar;
