import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: '18px',
    width: '40%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '2rem',
  },
  input: {
    margin: 'auto',
    paddingBottom: '1rem',
  },
  inputDate: {
    margin: 'auto',
    paddingBottom: '1rem',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerDates: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rejectBtn: {
    borderRadius: 2,
    width: '95%',
    border: '1px solid',
  },
  approveButton: {
    borderRadius: 2,
    width: '100%',
  },
  attachmentButton: {
    borderRadius: 12,
    height: '1.2rem',
    marginTop: '-2rem',
  },
  text: {
    fontWeight: 600,
  },
  divider: {
    marginBottom: '2.5rem',
    marginLeft: -32,
    width: '111%',
  },
  closeButtonStyle: {
    position: 'relative',
    left: 25,
    top: -25,
  },
  rejectButton: {
    borderRadius: '8',
    color: theme.palette.error.main,
  },
  rejectButtonAction: {
    color: 'lightgray',
    cursor: 'pointer',
  },
}));

export default useStyles;
