import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { checkFileType } from '../api/apiHelpers';

import uploadFile from '../api/uploadFile';

const useFileUpload = (onValueChange) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');

  const handleUpload = useCallback(
    async (e, tableId, tableType) => {
      const file = e.target.files[0];

      if (checkFileType(e)) {
        try {
          if (!file) {
            return;
          }
          const { fileUrl } = await uploadFile(
            file,
            tableId,
            tableType,
            setProgress,
          );
          if (fileUrl) {
            onValueChange(fileUrl);
            setProgress(0);
          }
        } catch (err) {
          setProgress(0);
          setError(err);
        }
      } else {
        toast.error('El formato del archivo debe ser JPG, PNG, DOCX o PDF');
      }
    },
    [onValueChange],
  );

  return [progress, handleUpload, error];
};

export default useFileUpload;
