import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import DateAdapter from '@mui/lab/AdapterMoment';
import { TimePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import Input from '../../../shared/Input';
import { errorHandling } from '../../../../api/apiHelpers';
import eventApi from '../../../../api/eventApi';
import { HTTP_STATUS } from '../../../../../src/api/apiConstants';
import useStyles from './style';
import temp from './BaseEventInfo';
import validationSchemaEditEvent from './validationSchemaEditEvent';

const propTypes = {
  eventActive: PropTypes.array,
  handleClose: PropTypes.func,
  getAllEventsHandler: PropTypes.func,
};

const defaultPropTypes = {
  eventActive: [],
  handleClose: null,
  getAllEventsHandler: null,
};

const EditEventsModal = ({ eventActive, handleClose, getAllEventsHandler }) => {
  const [eventInfo, setEventInfo] = React.useState(temp);
  const classes = useStyles();

  const setEventData = async () => {
    try {
      const result = await eventApi.getEvent(eventActive.id);

      const data = result.data.data;

      const date = new Date(data.startDateAndTime);

      setEventInfo({ ...data, date: date });
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  const saveData = async (values) => {
    try {
      if (values.hourFrom >= values.hourTo) {
        return toast.error(
          'La hora de comienzo debe ser menor a la de finalización',
        );
      }

      const startDate = new Date(values.date);
      startDate.setHours(values.startDateAndTime.getHours());
      startDate.setMinutes(values.startDateAndTime.getMinutes());
      startDate.setSeconds(0);
      const endDate = new Date(values.date);
      endDate.setHours(values.endDateAndTime.getHours());
      endDate.setMinutes(values.endDateAndTime.getMinutes());
      endDate.setSeconds(0);

      const data = {
        name: values.eventName,
        place: values.place,
        startDateAndTime: startDate,
        endDateAndTime: endDate,
        createdBy: values.createdBy,
      };

      const resp = await eventApi.patchEvent(values.id, data);

      if (resp?.status === HTTP_STATUS.OK) {
        getAllEventsHandler();
        handleClose();
        toast.success('Evento actualizado');
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  React.useEffect(() => {
    setEventData();
  }, []);

  return (
    <div className={classes.modalContainer}>
      <Typography className={classes.title}>Edición de evento</Typography>
      <Formik
        validationSchema={validationSchemaEditEvent}
        initialValues={eventInfo}
        enableReinitialize={true}
        onSubmit={(values) => {
          saveData(values);
        }}>
        {({ values, dirty, handleChange, setFieldValue, errors }) => {
          return (
            <Form>
              <Grid container>
                <Grid item xs={12} className={classes.inputItem}>
                  <Input
                    helperText={errors.name}
                    variant="outlined"
                    value={values.name}
                    label="Nombre"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    required
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} className={classes.dateItem}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Fecha"
                      disablePast
                      helperText={errors.date}
                      value={values.date}
                      onChange={(newValue) => {
                        setFieldValue('date', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid container spacing={2} className={classes.hourContainer}>
                  <Grid item xs={6} className={classes.hourItem}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Hora de comienzo"
                        value={values.startDateAndTime}
                        helperText={errors.startDateAndTime}
                        onChange={(newValue) => {
                          setFieldValue('startDateAndTime', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} className={classes.hourItem}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Hora de finalización"
                        value={values.endDateAndTime}
                        helperText={errors.endDateAndTime}
                        onChange={(newValue) => {
                          setFieldValue('endDateAndTime', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.inputItem}>
                  <Input
                    variant="outlined"
                    value={values.place}
                    label="Lugar"
                    helperText={errors.place}
                    name="place"
                    id="place"
                    onChange={handleChange}
                    required
                    type="text"
                  />
                </Grid>
              </Grid>
              <Button
                sx={{
                  textTransform: 'none',
                }}
                variant="contained"
                disabled={!dirty}
                type="submit"
                color="primary"
                fullWidth>
                Actualizar evento
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
EditEventsModal.propTypes = propTypes;
EditEventsModal.defaultProps = defaultPropTypes;
export default EditEventsModal;
