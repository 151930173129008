import React, { useState, useEffect } from 'react';
import 'react-day-picker/lib/style.css';
import DayPicker, { DateUtils } from 'react-day-picker';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  Divider,
  Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import { Form, Formik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import getFile from '../../../../api/getFile';
import logRequestApi from '../../../../api/logRequestApi';

import {
  HTTP_STATUS,
  REQUEST_TYPE_EN,
  REQUEST_TYPE_SP,
  TABLE_TYPE,
} from '../../../../api/apiConstants';
import useFileUpload from '../../../../hooks/useFileUpload';
import {
  acceptFileTypes,
  checkFileType,
  errorHandling,
  getFileName,
  getUserId,
  maxDaysCalculation,
} from '../../../../api/apiHelpers';
import userDayOffApi from '../../../../api/userDayOffApi';
import DeleteRequestModal from '../DeleteRequestModal/DeleteRequestModal';
import useStyles from './style';

const propTypes = {
  handleClose: PropTypes.func,
  updateRequest: PropTypes.func,
  requestInfo: PropTypes.func,
  getAllRequestsHandler: PropTypes.func,
};

const defaultPropTypes = {
  handleClose: undefined,
  requestInfo: null,
  updateRequest: undefined,
  getAllRequestsHandler: undefined,
};

const EditRequestModal = ({
  handleClose,
  updateRequest,
  requestInfo,
  getAllRequestsHandler,
}) => {
  const classes = useStyles();
  const [fileUrl, setFileUrl] = useState('');
  const [tempFile, setTempFile] = useState(null);
  const [oldFileName, setOldFileName] = useState('');
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [progress, uploadFile, error] = useFileUpload(setFileUrl);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [openSmallModal, setOpenSmallModal] = useState(false);
  const [range, setRange] = React.useState({
    from: new Date(requestInfo.startDate),
    to: new Date(requestInfo.finishDate),
  });

  const handleCloseSmallModal = () => {
    setOpenSmallModal(false);
  };

  const initialValues = {
    id: requestInfo.id,
    comment: requestInfo.comment,
    fileId: requestInfo.fileId,
    type: requestInfo.type,
    answer: '',
  };

  const getAttachedFile = async (id) => {
    try {
      const {
        data: { data },
      } = await getFile(id);
      const link = data?.link;
      const fileName = data?.fileName;
      setFileName(getFileName(fileName));
      setOldFileName(getFileName(fileName));
      setUrl(link);
      setTempFile(data);
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  useEffect(() => {
    if (requestInfo.fileId !== '' && requestInfo.fileId !== null) {
      getAttachedFile(requestInfo?.fileId);
    }
  }, []);

  useEffect(() => {
    if (progress === 100 && fileUrl) {
      setIsFileLoaded(true);
    } else if (error) {
      errorHandling(error.response.status);
    }
  }, [error, fileUrl, progress]);

  const handleDayClick = (day) => {
    if (day > new Date() && day.getDay() !== 0) {
      setRange(DateUtils.addDayToRange(day, range));
    }
  };

  const modifiers = { start: range.from, end: range.to };

  const getBusinessDatesCount = (start, finish) => {
    let count = 0;
    const curDate = new Date(start);
    while (curDate <= finish) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  };

  const userData = async () => {
    try {
      const {
        data: { data },
      } = await userDayOffApi.getByUser(getUserId());
      return data;
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      values = { ...values, startDate: range.from, finishDate: range.to };
      const _values = {
        ...values,
        type: values.type === '' ? REQUEST_TYPE_EN.NORMAL : values.type,
      };
      const data = await userData();

      if (tempFile) {
        const newFileName = tempFile.target.files[0].name;

        if (newFileName !== oldFileName) {
          await uploadFile(tempFile, requestInfo.id, TABLE_TYPE.LOG_REQUEST);

          delete values.fileId;
        }
      } else {
        values.fileId = '';
      }

      const { error, maxDays } = await maxDaysCalculation(_values, data, range);

      if (error) {
        toast.error(error);
        setFinalDay(maxDays);
      } else {
        await updateRequest(values);
        handleClose();
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      await getAllRequestsHandler();
      setIsLoading(false);
    }
  };

  const deleteRequest = async (id) => {
    try {
      const response = await logRequestApi.delete(id);

      if (
        response.status === HTTP_STATUS.OK ||
        response.status === HTTP_STATUS.CREATED
      ) {
        toast.success('Solicitud eliminada');
        handleClose();
      }
    } catch (error) {
      errorHandling(error.response.status);
    } finally {
      getAllRequestsHandler();
    }
  };

  const setFinalDay = (days) => {
    let final = moment(new Date(range.from)).add(days, 'days');
    while (getBusinessDatesCount(range.from, final.toDate()) < days) {
      final = final.add(1, 'days');
    }
    handleDayClick(final.toDate());
  };

  return (
    <Paper className={classes.container}>
      <DialogTitle id="id">
        <Box className={classes.titleContainer}>
          <Typography variant="h5" className={classes.text}>
            Actualizar licencia
          </Typography>
          <Box>
            <IconButton
              aria-label="close modal"
              className={classes.closeButtonStyle}
              onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider variant="middle" />
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                <DayPicker
                  className="Selectable"
                  disabledDays={[{ before: new Date() }, { daysOfWeek: [0] }]}
                  numberOfMonths={2}
                  selectedDays={[range.from, range]}
                  modifiers={modifiers}
                  onDayClick={handleDayClick}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="type"
                  select
                  fullWidth
                  value={values.type}
                  label="Tipo de licencia"
                  margin="normal"
                  variant="outlined"
                  required
                  className={classes.inputStyle}
                  onChange={handleChange}>
                  <MenuItem value={REQUEST_TYPE_EN.NORMAL}>
                    {REQUEST_TYPE_SP.NORMAL}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.SICK}>
                    {REQUEST_TYPE_SP.SICK}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.STUDY}>
                    {REQUEST_TYPE_SP.STUDY}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.SPACE}>
                    {REQUEST_TYPE_SP.SPACE}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.MATERNITY}>
                    {REQUEST_TYPE_SP.MATERNITY}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.PATERNITY}>
                    {REQUEST_TYPE_SP.PATERNITY}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.MOURNING}>
                    {REQUEST_TYPE_SP.MOURNING}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.MARRIAGE}>
                    {REQUEST_TYPE_SP.MARRIAGE}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.PAP}>
                    {REQUEST_TYPE_SP.PAP}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.BLOOD_DONATION}>
                    {REQUEST_TYPE_SP.BLOOD_DONATION}
                  </MenuItem>
                  <MenuItem value={REQUEST_TYPE_EN.NOT_PAID}>
                    {REQUEST_TYPE_SP.NOT_PAID}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  className={classes.inputStyle}
                  multiline
                  maxRows={2}
                  name="comment"
                  onChange={handleChange}
                  value={values.comment}
                  inputProps={{ maxLength: 250 }}
                  placeholder="Descripción (max 250)"
                  required
                />
              </Grid>
              {requestInfo.fileId || tempFile ? (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.attachmentButton}>
                    {requestInfo.fileId
                      ? fileName
                      : tempFile.target.files[0].name}
                  </Button>
                  <IconButton
                    aria-label="Eliminar archivo"
                    onClick={() => {
                      requestInfo.fileId = '';
                      setTempFile(null);
                    }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Button
                    component="label"
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={<AttachmentOutlinedIcon />}>
                    <input
                      onChange={(e) => setTempFile(checkFileType(e))}
                      type="file"
                      accept={acceptFileTypes()}
                      hidden
                    />
                    Adjuntar
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  color="primary"
                  onClick={handleSubmit}
                  type="submit"
                  loading={isLoading}
                  sx={{ borderRadius: 2 }}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained">
                  Guardar
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.deleteButton}
                  onClick={() => {
                    setOpenSmallModal(true);
                  }}>
                  Eliminar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Modal open={openSmallModal} onClose={handleCloseSmallModal}>
        <Box>
          <DeleteRequestModal
            requestInfo={requestInfo}
            handleClose={handleCloseSmallModal}
            deleteRequest={deleteRequest}
          />
        </Box>
      </Modal>
    </Paper>
  );
};

EditRequestModal.propTypes = propTypes;
EditRequestModal.defaultProps = defaultPropTypes;

export default EditRequestModal;
