import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Box,
  Typography,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
} from '@mui/material';
import { Form, Formik, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';

import { noop } from 'lodash';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { errorHandling } from '../../../../api/apiHelpers';
import userInfoApi from '../../../../api/userInfoApi';
import {
  SHIRT_SIZE,
  CIVIL_STATUS,
  HTTP_STATUS,
  GENDER_EN,
  countries,
  languages,
} from '../../../../api/apiConstants';
import Input from '../../../shared/Input';
import temp from './BaseUserInfo';
import useStyles from './style';
import validationSchemaProfileEdit from './validationSchemaProfileEdit';

const propTypes = {
  userActive: PropTypes.array,
  onDisable: PropTypes.func,
  handleClose: PropTypes.func,
  getAllUsersHandler: PropTypes.func,
};

const defaultPropTypes = {
  userActive: [],
  onDisable: null,
  handleClose: noop,
  getAllUsersHandler: noop,
};

const EmployeeDetailsFormEdit = ({
  userActive,
  onDisable,
  handleClose,
  getAllUsersHandler,
}) => {
  const [otherGender, setOtherGender] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(temp);
  const [isNew, setIsNew] = useState(false);
  const classes = useStyles();

  const setUserData = async () => {
    try {
      const {
        data: { data },
      } = await userInfoApi.getByUser(userActive.id);
      if (Object.keys(data).length !== 0) {
        data.dateOfBirth = moment(data.dateOfBirth).format('YYYY-MM-DD');
        if (
          data.gender !== GENDER_EN.MALE &&
          data.gender !== GENDER_EN.FEMALE
        ) {
          setOtherGender(data.gender);
          data.gender = GENDER_EN.OTHER;
        }
        if (data.genderPronouns !== null) {
          data.genderPronounsOption = true;
        } else {
          data.genderPronounsOption = false;
          data.genderPronouns = '';
        }
        data.kids = data.kidsNames.length > 0;
        data.personalEmail = userActive.email;
        data.fullName = userActive.name;
        data.languages = data.languages.split(',');
        setUserInfo(data);
      } else {
        setIsNew(true);
      }
    } catch (error) {
      errorHandling(error.response.status);
    }
  };

  const formatData = (values) => {
    const finalData = { ...values };
    finalData.dateOfBirth = moment(finalData.dateOfBirth).toDate();

    if (finalData.genderPronounsOption === false) {
      finalData.genderPronouns = null;
    }
    if (finalData.gender === GENDER_EN.OTHER) {
      finalData.gender = otherGender;
    }
    if (finalData.kids === false) {
      finalData.kidsNames = [];
    }

    if (finalData.countryOfResidence !== null) {
      finalData.countryOfResidence = finalData.countryOfResidence.label;
    }

    if (Array.isArray(finalData.languages)) {
      const index = finalData.languages.indexOf('');

      if (index > -1) {
        finalData.languages.splice(index, 1);
      }

      finalData.languages = finalData.languages.toString();
    }

    return finalData;
  };

  const saveData = async (values) => {
    setIsLoading(true);
    if (isNew) {
      try {
        const resp = await userInfoApi.create(values);
        if (resp?.status === HTTP_STATUS.OK) {
          handleClose();
          toast.success('Datos actualizados correctamente');
        }
      } catch (error) {
        errorHandling(error.response.status);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const name = values.fullName;
        delete values.fullName;
        const userInfo = { ...values };

        const resp = await userInfoApi.updateInfoWithName(userActive.id, {
          name,
          userInfo,
        });

        if (resp?.status === HTTP_STATUS.OK) {
          handleClose();
          getAllUsersHandler();
          toast.success('Datos guardados correctamente');
        }
      } catch (error) {
        errorHandling(error.response.status);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setUserData();
  }, []);

  return (
    <>
      <div className={classes.boxContainer}>
        <Typography className={classes.title}>Edición del empleado</Typography>
        <Formik
          initialValues={userInfo}
          validationSchema={validationSchemaProfileEdit}
          enableReinitialize={true}
          onSubmit={(values) => {
            saveData(formatData(values));
            // TODO: next screen
          }}>
          {({ values, dirty, handleChange, setFieldValue, errors }) => {
            return (
              <Form className={classes.tableContainer}>
                <Box className={classes.formContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.text}>
                        Información basica
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        id="fullName"
                        value={values.fullName}
                        label="Nombre"
                        variant="outlined"
                        name="fullName"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginTop: '15px' }}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          label="Fecha de nacimiento"
                          value={values.dateOfBirth}
                          onChange={(newValue) => {
                            setFieldValue('dateOfBirth', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth required />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        id="email"
                        value={values.personalEmail}
                        label="Email"
                        variant="outlined"
                        name="personalEmail"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        value={values.nationality}
                        label="Nacionalidad"
                        variant="outlined"
                        id="nationality"
                        name="nationality"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        value={values.document}
                        label="Documento"
                        id="document"
                        variant="outlined"
                        name="document"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        variant="outlined"
                        id="phoneNumber"
                        value={values.phoneNumber}
                        label="Número de teléfono"
                        name="phoneNumber"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.text}>
                        Direcciones
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        id="country-select-demo"
                        fullWidth
                        variant="outlined"
                        options={countries}
                        onChange={(event, value) => {
                          setFieldValue('countryOfResidence', value);
                        }}
                        helperText={errors.countryOfResidence}
                        name="countryOfResidence"
                        value={values.countryOfResidence}
                        isOptionEqualToValue={(option, value) => {
                          if (value === '') {
                            value = 'Uruguay';
                          }
                          if (typeof value !== 'string') {
                            value = value.label;
                          }
                          return option.label === value;
                        }}
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          return option.label;
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}>
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            helperText={errors.countryOfResidence}
                            required
                            margin="normal"
                            label="País de residencia"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        variant="outlined"
                        helperText={errors.city}
                        id="city"
                        value={values.city}
                        name="city"
                        onChange={handleChange}
                        required
                        type={'text'}
                        label="Ciudad de residencia"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        variant="outlined"
                        id="addres"
                        value={values.addres}
                        name="addres"
                        onChange={handleChange}
                        required
                        type={'text'}
                        label="Dirección"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.text}>
                        Datos bancarios
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        value={values.bankName}
                        label="Nombre del banco"
                        name="bankName"
                        onChange={handleChange}
                        required
                        type={'text'}
                        variant="outlined"
                        id="bankName"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        id="bankAccountNumber"
                        variant="outlined"
                        value={values.bankAccountNumber}
                        label="Número de cuenta"
                        name="bankAccountNumber"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        variant="outlined"
                        value={values.bankAccountType}
                        label="Tipo de cuenta bancaria"
                        id="bankAccountType"
                        name="bankAccountType"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.text}>
                        Otros datos
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        variant="outlined"
                        value={values.emergencyContactPhone}
                        label="Contacto de emergencia"
                        name="emergencyContactPhone"
                        id="emergencyContactPhone"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        variant="outlined"
                        value={values.heathcare}
                        label="Sociedad médica"
                        name="heathcare"
                        id="heathcare"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="civilStatus"
                        select
                        fullWidth
                        value={values.civilStatus}
                        label="Estado civil"
                        margin="normal"
                        variant="outlined"
                        onChange={handleChange}>
                        <MenuItem value={CIVIL_STATUS.SINGLE}>Soltero</MenuItem>
                        <MenuItem value={CIVIL_STATUS.MARRIED}>Casado</MenuItem>
                        <MenuItem value={CIVIL_STATUS.DIVORCED}>
                          Divorciado
                        </MenuItem>
                        <MenuItem value={CIVIL_STATUS.WIDOWED}>Viudo</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        variant="outlined"
                        value={values.academicLevel}
                        label="Nivel académico"
                        name="academicLevel"
                        id="academicLevel"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        variant="outlined"
                        value={values.career}
                        label="Carrera"
                        name="career"
                        id="career"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="demo-multiple-checkbox-label">
                          Lenguajes
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          name="languages"
                          id="languages"
                          multiple
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          value={values.languages}
                          onChange={handleChange}
                          input={<OutlinedInput label="Lenguajes" />}
                          renderValue={(selected) => selected.join(', ')}>
                          {languages.map((language) => (
                            <MenuItem key={language} value={language}>
                              <Checkbox
                                checked={
                                  values.languages.indexOf(language) > -1
                                }
                              />
                              <ListItemText primary={language} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        variant="outlined"
                        value={values.specialConditions}
                        label="Condiciones especiales"
                        name="specialConditions"
                        id="specialConditions"
                        onChange={handleChange}
                        required
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="shirtSize"
                        id="shirtSize"
                        select
                        fullWidth
                        value={values.shirtSize}
                        label="Tamaño de remera"
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}>
                        <MenuItem value={SHIRT_SIZE.SMALL}>
                          {SHIRT_SIZE.SMALL}
                        </MenuItem>
                        <MenuItem value={SHIRT_SIZE.MEDIUM}>
                          {SHIRT_SIZE.MEDIUM}
                        </MenuItem>
                        <MenuItem value={SHIRT_SIZE.LARGE}>
                          {SHIRT_SIZE.LARGE}
                        </MenuItem>
                        <MenuItem value={SHIRT_SIZE.EXTRA_LARGE}>
                          {SHIRT_SIZE.EXTRA_LARGE}
                        </MenuItem>
                        <MenuItem value={SHIRT_SIZE.XX_LARGE}>
                          {SHIRT_SIZE.XX_LARGE}
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Hijos?"
                        labelPlacement="end"
                        name="kids"
                        value={values.kids}
                        checked={!!values.kids}
                        onClick={(event) => {
                          setFieldValue('kids', event.target.checked);
                        }}
                      />
                      {values.kids && (
                        <FieldArray
                          name="kidsNames"
                          render={(arrayHelpers) => (
                            <div>
                              {values.kidsNames.map((kidName, index) => (
                                <div
                                  key={index}
                                  className={classes.kidsContainer}>
                                  <TextField
                                    variant="outlined"
                                    value={kidName}
                                    label="Nombre"
                                    name={`kidsNames[${index}]`}
                                    id={`kidsNames[${index}]`}
                                    onChange={handleChange}
                                    required
                                    className={classes.kidsNameContainer}
                                  />
                                  <Button
                                    variant="text"
                                    type="button"
                                    className={classes.buttonStyle}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    X
                                  </Button>
                                </div>
                              ))}
                              <Button
                                className={classes.buttonKidsStyle}
                                variant="contained"
                                type="button"
                                onClick={() => arrayHelpers.push('')}>
                                Agregar nombre
                              </Button>
                            </div>
                          )}
                        />
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      fullWidth
                      color="primary"
                      type="submit"
                      loading={isLoading}
                      sx={{ width: '100%', marginBottom: '1rem' }}
                      disabled={!dirty}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained">
                      Guardar
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      sx={{ width: '100%' }}
                      variant="outlined"
                      type="button"
                      color="error"
                      onClick={onDisable}>
                      Deshabilitar
                    </Button>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
EmployeeDetailsFormEdit.propTypes = propTypes;
EmployeeDetailsFormEdit.defaultProps = defaultPropTypes;
export default EmployeeDetailsFormEdit;
